import _ from "lodash";
import { ReactNode, useEffect } from "react";
import { Box, Stack } from "@samacare/design";
import {
  useFormContext,
  useWatch,
  AddressBlock,
  AutocompleteField,
  TextField,
} from "@samacare/form";
import { PrescriberInfoFragment } from "@@generated/graphql";
import { usePrescribers } from "../_common/hooks/usePrescribers";
import { RequestingProviderRoleCode } from "@samacare/graphql";
import renderOptionsAutoSuggest from "@@components/RenderOptionsAutoSuggest";

const ProviderTypeOrder: RequestingProviderRoleCode[] = [
  RequestingProviderRoleCode.Provider,
  RequestingProviderRoleCode.Facility,
];

const defaults = {
  npi: "",
  firstName: "",
  lastName: "",
  PrescriberId: "",
  taxId: "",
  specialty: "",
  streetAddress: "",
  city: "",
  state: "",
  zip: "",
  roleCode: null,
};

const toLabel = (value: string) => {
  return _.startCase(_.lowerCase(value));
};

const HiddenBox = () => <Box sx={{ visibility: "hidden", width: "100%" }} />;

export type ProviderBlockProps = {
  required?: boolean;
  hideProviderType?: boolean;
  providerObjName?: string;
  children?: ReactNode;
  hideProviderAddress?: boolean;
  taxIdRequired?: boolean;
};
const ProviderBlock: React.FC<ProviderBlockProps> = (props) => {
  const {
    required = false,
    providerObjName = "requestingProvider",
    hideProviderType = false,
    children,
    hideProviderAddress = false,
    taxIdRequired = false,
  } = props;
  const { watch, control, register, setValue } = useFormContext();

  const prescribers = usePrescribers();

  const prescriberTag = `${providerObjName}.PrescriberId`;

  useEffect(() => {
    register(prescriberTag);
  }, [register, prescriberTag]);

  const PrescriberId = useWatch({ control, name: prescriberTag });

  // when a provider is not selected, all the other fields are disabled
  const disabled = !PrescriberId;

  return (
    <Stack gap={2} maxWidth={600}>
      <Stack gap={1} direction="row" alignItems="flex-start" maxWidth={600}>
        <Box width="100%">
          <AutocompleteField<PrescriberInfoFragment, false, false, false>
            name={`ProviderBlock_${providerObjName}`}
            label="Provider"
            options={prescribers}
            renderOption={(renderOptionProps, option, { inputValue }) =>
              renderOptionsAutoSuggest({
                props: renderOptionProps,
                option: {
                  id: option.id,
                  title:
                    option.firstName && option.lastName
                      ? `${option.firstName} ${option.lastName}`
                      : "Unknown",
                },
                state: { inputValue },
              })
            }
            filterSelectedOptions
            fullWidth
            getOptionLabel={(option) =>
              option?.firstName != null && option?.lastName != null
                ? `${option?.firstName} ${option?.lastName}`
                : "Unknown"
            }
            onChange={(_event, change, reason) => {
              setValue(
                providerObjName,
                reason === "selectOption"
                  ? {
                      ...defaults,
                      npi: change?.NPI,
                      firstName: change?.firstName,
                      lastName: change?.lastName,
                      specialty: change?.specialtyDescription,
                      taxId: change?.TIN,
                      PrescriberId: change?.id,
                    }
                  : defaults,
                { shouldDirty: true }
              );
            }}
          />
        </Box>
        <Box width="100%">
          <TextField
            required={required}
            disabled={disabled}
            fullWidth
            rules={{
              validate: {
                length: (value: string) =>
                  value.length === 10 || "NPI must be 10 digits",
              },
            }}
            name={`${providerObjName}.npi`}
            label="NPI"
          />
        </Box>
      </Stack>
      <TextField
        sx={{ display: "none" }}
        type="hidden"
        name={prescriberTag}
        value={PrescriberId}
      />
      {!hideProviderType && (
        <AutocompleteField<string, false, true, false>
          disableClearable
          getOptionLabel={(val) => toLabel(val)}
          options={ProviderTypeOrder}
          label="Provider type"
          name={`${providerObjName}.roleCode`}
          required
          disabled={disabled}
          value={watch(`${providerObjName}.roleCode`) ?? ""}
        />
      )}
      <Stack gap={1} direction="row" maxWidth={600}>
        <TextField
          required={required}
          disabled={disabled}
          fullWidth
          label="First name"
          name={`${providerObjName}.firstName`}
        />
        <TextField
          required={required}
          disabled={disabled}
          fullWidth
          label="Last name"
          name={`${providerObjName}.lastName`}
        />
        <HiddenBox />
      </Stack>
      <Stack gap={1} direction="row" maxWidth={600}>
        <TextField
          required={false}
          disabled={disabled}
          fullWidth
          label="Specialty"
          name={`${providerObjName}.specialty`}
        />
        <TextField
          required={taxIdRequired}
          disabled={disabled}
          fullWidth
          label="Tax ID"
          rules={{
            validate: {
              length: (value: string) =>
                value.length === 9 || "Tax ID must be 9 digits",
            },
          }}
          name={`${providerObjName}.taxId`}
        />
        <HiddenBox />
      </Stack>
      {!hideProviderAddress && (
        <AddressBlock
          required={false}
          disabled={disabled}
          AddressInputProps={{ name: `${providerObjName}.streetAddress` }}
          CityInputProps={{ name: `${providerObjName}.city` }}
          StateInputProps={{ name: `${providerObjName}.state` }}
          ZipInputProps={{ name: `${providerObjName}.zip` }}
        />
      )}
      {children}
    </Stack>
  );
};

export default ProviderBlock;
