import React, { createContext, useState, useMemo } from "react";

interface PortalData {
  portal?: {
    key?: string;
    insuranceCompanyName: string;
    isLegacy: boolean;
    loginUrl?: string;
  };
  onClickConfigOverride?: Record<string, unknown>;
}

interface PortalContextProps {
  portalData: PortalData | null;
  setPortalData: React.Dispatch<React.SetStateAction<PortalData | null>>;
}

export const PortalContext = createContext<PortalContextProps>({
  portalData: null,
  setPortalData: () => {},
});

export const PortalStateProvider: React.FC = ({ children }) => {
  const [portalData, setPortalData] = useState<PortalData | null>(null);

  const value = useMemo(() => ({ portalData, setPortalData }), [portalData]);

  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
};
