import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { IconButton, InputAdornment, TextField } from "@samacare/design/core";
import Clear from "@samacare/design/core/icons/Clear";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const ClearIcon = styled(Clear)`
  font-size: 12px;
  color: #fff;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 16px;
`;

const StyledIconButton = styled(IconButton)`
  background-color: rgba(0, 0, 0, 0.56);
  padding: 2px;
  margin-right: 0;
  &:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }
`;

const SignatureCanvas = styled.canvas`
  border: 1px solid ${(props) => props.theme.lightGray};
  border-radius: 3px;
`;

export const CANVAS_WIDTH = 600;
export const CANVAS_HEIGHT = 85;
const PADDING = 25;

interface SignatureInputProps {
  provider?: { firstName: string; lastName: string } | undefined;
  disabled?: boolean;
  required?: boolean;
  saveAndSetSignature: (dataUrl: string, addToInstitution?: boolean) => void;
  removeSignature: () => void;
  hasSelectedSignature: boolean;
}

const FONT_FAMILY = "Dawning of a New Day";

const SignatureInput = (props: SignatureInputProps) => {
  const { disabled, provider, saveAndSetSignature, required, removeSignature } =
    props;
  const [name, setName] = useState<string>("");
  const canvas = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    if (provider && (provider.firstName || provider.lastName)) {
      setName(`${provider.firstName} ${provider.lastName}`);
    }
  }, []);

  // canvas has to be re-rendered on changes because font doesn't always load on
  // first render
  if (canvas.current) {
    const ctx = canvas.current.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

      ctx.font = `60px ${FONT_FAMILY}`;

      ctx.fillText(
        name,
        PADDING,
        CANVAS_HEIGHT - PADDING,
        CANVAS_WIDTH - 2 * PADDING
      );
    }
  }

  useEffect(() => {
    saveAndSetSignature(name ? canvas?.current?.toDataURL() || "" : "");
  }, [canvas?.current?.toDataURL(), name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    removeSignature();
  };

  const handleClear = () => {
    setName("");
    removeSignature();
  };

  return (
    <Container>
      <StyledTextField
        label="Signature"
        id="signatureInput"
        data-cy="fieldPhysicianStamp"
        disabled={Boolean(disabled)}
        onChange={handleChange}
        variant="outlined"
        required={Boolean(required)}
        value={name}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: name && !disabled && (
            <InputAdornment position="end">
              <StyledIconButton onClick={handleClear} edge="end">
                <ClearIcon />
              </StyledIconButton>
            </InputAdornment>
          ),
        }}
        placeholder="Begin typing to generate signature"
      />
      {/* Width and height need to be explicitly set on canvas */}
      {/* Key is needed for re-render the canvas and keep fonts synced */}
      <SignatureCanvas
        ref={canvas}
        style={{ height: CANVAS_HEIGHT, width: CANVAS_WIDTH, marginTop: 1 }}
        height={`${CANVAS_HEIGHT}px`}
        width={`${CANVAS_WIDTH}px`}
      />
    </Container>
  );
};

export default SignatureInput;
