import _ from "lodash";

import { AutocompleteOnChangeType } from "@samacare/design/core/Autocomplete/Autocomplete";
import { Prescriber } from "@samacare/graphql";
import {
  AutocompleteField,
  type AutocompleteFieldProps,
} from "@samacare/form2";

type PrescriberAutocompleteProps = Omit<
  AutocompleteFieldProps<Prescriber, false, false, false>,
  "name" | "options"
>;

export type ProviderAutocompleteInternalFunctionalProps = {
  disabled?: boolean;
  PrescriberAutocompleteInputProps?: PrescriberAutocompleteProps;
  chooseDisabled?: boolean;
  placeholder?: string;
  filterSelectedOptions?: boolean;
  name: string;
  onChange: AutocompleteOnChangeType<Prescriber, false, false, false>;
  noOptionsText?: React.ReactNode;
  value?: Prescriber | null;
} & (
  | {
      onInputChange: (
        event: React.SyntheticEvent,
        value: string,
        reason: string,
      ) => void;
      inputValue: string;
      value: Prescriber | null;
      open: boolean;
      onOpen: () => void;
      onClose: () => void;
    }
  | {
      onInputChange?: never;
      inputValue?: never;
      value?: never;
      open?: never;
      onOpen?: never;
      onClose?: never;
    }
);

export type ProviderAutocompleteFunctionalProps =
  ProviderAutocompleteInternalFunctionalProps & {
    apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
  };

export type ProviderAutocompleteDataProps = {
  providers: Prescriber[];
  loading: boolean;
};

export type ProviderAutocompleteAllProps =
  ProviderAutocompleteInternalFunctionalProps & ProviderAutocompleteDataProps;

const defaultProps: ProviderAutocompleteInternalFunctionalProps = {
  disabled: false,
  PrescriberAutocompleteInputProps: {},
  chooseDisabled: false,
  name: "PrescriberBlock",
  onChange: () => {},
  noOptionsText: "No options",
  inputValue: undefined,
  onInputChange: undefined,
  value: undefined,
};

export const ProviderAutocomplete: React.FC<ProviderAutocompleteAllProps> = (
  rawProps,
) => {
  const {
    disabled,
    PrescriberAutocompleteInputProps,
    chooseDisabled,
    name,
    providers,
    onChange,
    noOptionsText,
    inputValue,
    onInputChange,
    value,
    open,
    onClose,
    onOpen,
  } = _.merge(
    _.cloneDeep(defaultProps),
    rawProps,
  ) as Required<ProviderAutocompleteAllProps>;

  const cannotChoose = disabled || chooseDisabled;

  return (
    <AutocompleteField<Prescriber, false, false, false>
      disablePortal
      name={name}
      options={providers}
      placeholder={cannotChoose ? "" : "Choose..."}
      filterSelectedOptions
      getOptionLabel={(option: Prescriber) =>
        option?.firstName != null && option?.lastName != null
          ? `${option?.firstName} ${option?.lastName}`
          : "Unknown"
      }
      value={value}
      noOptionsText={noOptionsText}
      onChange={onChange}
      disabled={cannotChoose}
      inputValue={inputValue}
      onInputChange={onInputChange}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      {...PrescriberAutocompleteInputProps}
    />
  );
};
