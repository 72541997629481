import React, { useState } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  TextField,
} from "@samacare/design/core";
import Avatar from "@samacare/design/core/Avatar";

import PhoneOutlined from "@samacare/design/core/icons/PhoneOutlined";
import VerifiedOutlined from "@samacare/design/core/icons/VerifiedOutlined";
import ExpandMoreIcon from "@samacare/design/core/icons/ExpandMore";

import { InternationalPhone } from "Segment/InternationalPhone";
import { PhoneNumberItem } from "./PhoneNumberItem";
import { withPossibleFormNumbers } from "../../graphql/FormNumber";

const StyledPaper = styled(Paper)`
  border-radius: 4px;
  min-width: 458px;
  height: 100%;
`;
const StyledHeader = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;
  align-items: center;
`;
const StyledAvatar = styled(Avatar)`
  border: 2px solid #cfd8dc;
  background: #eceff1;
  color: #607d8b;
`;
const StyledIcon = styled(VerifiedOutlined)`
  width: 18px;
  height: 100%;
`;
const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  border-left: 4px solid #b0bec5;
  background: #eceff1;
  height: 64px;

  .MuiAccordionSummary-content {
    margin: 8px 0;
  }
`;
const StyledDetails = styled(AccordionDetails)`
  padding: 8px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;
const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: rgba(0, 0, 0, 0.38);
`;
const StyledAccordions = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  .Mui-expanded {
    margin: 0;
  }
`;

const StyledInputDescription = styled.div`
  padding: 16px;
`;

const StyledEmptyState = styled.div`
  padding: 8px;
`;

const formatPhoneNumberDetails = ({
  number,
  onClick,
  selected,
  isFavorite,
  isInLastActiveTab,
}) => {
  if (!number?.id) return null;
  return (
    <PhoneNumberItem
      dataCy="actionSelectFaxNumber"
      key={number.id}
      number={number.number}
      description={number.description}
      isFavorite={isFavorite}
      id={number.id}
      onClick={onClick}
      isSelected={isInLastActiveTab && number.id === selected}
    />
  );
};

const TABS = {
  RECOMMENDED: "RECOMMENDED",
  FAVORITES: "FAVORITES",
  ADD_FAX_NUMBER: "ADD_FAX_NUMBER",
};

const PhoneNumberSelector = ({
  numbers,
  number,
  setNumber,
  onSelect,
  selected,
  validateNumber,
  validateDescription,
}) => {
  const [lastActiveTab, setLastActiveTab] = useState(TABS.RECOMMENDED);
  const { suggestedNumbers, favoriteNumbers, remainingNumbers } = numbers || {};

  return (
    <StyledPaper>
      <StyledHeader>
        <StyledAvatar>
          <PhoneOutlined />
        </StyledAvatar>
        Choose Fax Number
      </StyledHeader>
      <StyledAccordions>
        <Accordion
          defaultExpanded={true}
          onClick={() => setLastActiveTab(TABS.RECOMMENDED)}
        >
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <StyledTitle>
              <Typography variant="body1">Recommended Numbers</Typography>
              <StyledDescription>
                <StyledIcon />
                <div>Verified by SamaCare</div>
              </StyledDescription>
            </StyledTitle>
          </StyledAccordionSummary>
          <StyledDetails>
            {suggestedNumbers?.length ||
            favoriteNumbers?.length ||
            remainingNumbers?.length ? (
              <>
                {favoriteNumbers?.map((num) =>
                  formatPhoneNumberDetails({
                    number: num,
                    isFavorite: true,
                    onClick: onSelect,
                    selected,
                    isInLastActiveTab: lastActiveTab === TABS.RECOMMENDED,
                  })
                )}
                {suggestedNumbers?.map((num) =>
                  formatPhoneNumberDetails({
                    number: num,
                    isFavorite: false,
                    onClick: onSelect,
                    selected,
                    isInLastActiveTab: lastActiveTab === TABS.RECOMMENDED,
                  })
                )}
                {remainingNumbers?.map((num) =>
                  formatPhoneNumberDetails({
                    number: num,
                    isFavorite: false,
                    onClick: onSelect,
                    selected,
                    isInLastActiveTab: lastActiveTab === TABS.RECOMMENDED,
                  })
                )}
              </>
            ) : (
              <StyledEmptyState>
                SamaCare does not have a verified fax number for this form.
                Please add the fax number listed on the form below.
              </StyledEmptyState>
            )}
          </StyledDetails>
        </Accordion>
        <Accordion onClick={() => setLastActiveTab(TABS.FAVORITES)}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <StyledTitle>
              <Typography variant="body1">Favorites</Typography>
            </StyledTitle>
          </StyledAccordionSummary>
          <StyledDetails>
            {favoriteNumbers?.length ? (
              favoriteNumbers.map((num) =>
                formatPhoneNumberDetails({
                  number: num,
                  isFavorite: true,
                  onClick: onSelect,
                  selected,
                  isInLastActiveTab: lastActiveTab === TABS.FAVORITES,
                })
              )
            ) : (
              <StyledEmptyState>
                Save your top fax numbers here for quick reference. Favorite a
                number to get started.
              </StyledEmptyState>
            )}
          </StyledDetails>
        </Accordion>
        <Accordion style={{ paddingBottom: "16px" }}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <StyledTitle>
              <Typography variant="body1">Add Fax Number</Typography>
            </StyledTitle>
          </StyledAccordionSummary>
          <StyledDetails>
            <StyledInputDescription>
              Enter a fax number, give it a nickname so you can easily find it
              next time.
            </StyledInputDescription>
            <InternationalPhone
              name="Fax Number"
              error={number?.number && number?.numberError}
              number={number?.number}
              onChange={(e) => {
                onSelect(null);
                setNumber({ ...number, number: e.target.value });
              }}
              onBlur={validateNumber}
            />
            <TextField
              name="description"
              label="Nickname"
              placeholder="Nickname or title"
              value={number?.description}
              onChange={(e) => {
                onSelect(null);
                setNumber({ ...number, description: e.target.value });
              }}
              rows={3}
              error={Boolean(number?.descriptionError)}
              helperText={number?.descriptionError || ""}
              onBlur={validateDescription}
            />
          </StyledDetails>
        </Accordion>
      </StyledAccordions>
    </StyledPaper>
  );
};

export default compose(withPossibleFormNumbers)(PhoneNumberSelector);
