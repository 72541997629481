import React from "react";
import { useQuery } from "@apollo/client";

import {
  Box,
  Grid,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Typography,
  CircularProgress,
} from "@samacare/design/core";
import {
  SearchCareMetxEnrollmentResponseByBvIdQuery,
  SearchCareMetxEnrollmentResponseByBvIdQueryVariables,
} from "@@generated/graphql";
import { getInsuranceType } from "../../../util/getInsuranceType";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { CareMetxMedeBvCoverageStatus } from "./CareMetxMedeBvCoverageStatus";
import { UnifiedBenefitsCheckStatus } from "./UnifiedBenefitsCheckStatus";
import GetCareMetxEnrollmentResponseByBenefitsVerificationId from "../graphql/GetCareMetxEnrollmentResponseByBenefitsVerificationIdQuery.gql";

export const CareMetxMedeBvSummary: React.FC = () => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();

  const benefitsVerificationId = unifiedBenefitsCheck?.fullData?.id;

  const { data, loading } = useQuery<
    SearchCareMetxEnrollmentResponseByBvIdQuery,
    SearchCareMetxEnrollmentResponseByBvIdQueryVariables
  >(GetCareMetxEnrollmentResponseByBenefitsVerificationId, {
    variables: {
      BenefitsVerificationId: benefitsVerificationId ?? null,
    },
    skip: !benefitsVerificationId,
  });

  const { enrollmentStatus, copayStatus } =
    data?.getCareMetxEnrollmentResponseByBenefitsVerificationId || {};

  if (!unifiedBenefitsCheck) {
    return <Box />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TabContext value="summary">
      <TabList>
        <Tab label="Summary" value="summary" />
      </TabList>
      <TabPanel value="summary" sx={{ paddingX: 0 }}>
        <Grid container padding={0}>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">
                Benefits Verification Status
              </Typography>
              <UnifiedBenefitsCheckStatus />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">Coverage Status</Typography>
              <CareMetxMedeBvCoverageStatus />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Payer</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insuranceCompanyData?.name || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Plan Type</Typography>
              <Typography variant="body1">
                {getInsuranceType(
                  unifiedBenefitsCheck.insurancePolicyData?.planType
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Member ID</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insurancePolicyData?.memberId || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Insurance State</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insurancePolicyData?.insuranceState ||
                  "-"}
              </Typography>
            </Box>
          </Grid>
          {enrollmentStatus && (
            <Grid item xs={6}>
              <Box marginTop={2}>
                <Typography variant="subtitle2">
                  Amgen HUB Patient ID
                </Typography>
                <Typography variant="body1">
                  {enrollmentStatus.value}
                </Typography>
              </Box>
            </Grid>
          )}
          {copayStatus && (
            <Grid item xs={6}>
              <Box marginTop={2}>
                <Typography variant="subtitle2">Copay Status</Typography>
                <Typography variant="body1">{copayStatus.value}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </TabContext>
  );
};
