import { BenefitsVerification } from "@samacare/graphql";

import {
  GetBenefitsVerificationByIdQuery,
  GetBenefitsVerificationByIdQueryVariables,
} from "@@generated/graphql";

import { gql, useQuery } from "@apollo/client";

export const getBenefitsVerificationByIdQuery = gql`
  query getBenefitsVerificationById($id: String!) {
    benefitsVerificationById(id: $id) {
      id
      createdAt

      patient {
        id
        institutionPatientId
        firstName
        lastName
        dateOfBirth
      }

      outcome {
        __typename
        id
        createdAt
        state
        data {
          __typename
          outcomeDataType
          requestedBy
          id
          status
          externalReferenceNumber
          notes
          isAuthRequired
          isAuthOnFile
          authNumber
          startDate
          endDate
          payorPaFax
          payorPaPhone
          authProcess
          stepTherapyProcess
          isStepTherapyRequired
          insuranceCoverageStatus
          isDrugCoveredByPlan
          drugBuyBillStatus
          secondaryinsuranceCoverageStatus
          isDrugCoveredBySecondaryInsurance
          drugBuyBillStatusForSecondaryInsurance
          updatedAt
          copayOffice
          copayDrug
          copayServices
          copayPcp
          copaySpecialist
          isDeductibleMet
          isOopMet
          deductibleTotal
          isDeductibleAppliedToOop
          deductibleRemaining
          oopMax
          oopRemaining
          oopAnnualCap
          HCPCSCodeDetails {
            code
            response
          }
        }
      }

      request {
        requestedById
        prescribedDate
        patientStatus

        drugDetails {
          drugOptionId
          drugName
          drugCode
          icds
          hcpcsCodes
        }

        patientDetails {
          firstName
          lastName
          dob
          gender
          phone
          address
          zip
          city
          state
        }

        primaryInsuranceDetails {
          InsuranceCompanyId
          insuranceCompany {
            id
            name
          }
          id
          insuranceState
          groupNumber
          memberId
          planType
          insuranceCompany {
            id
            name
          }
          latestCoverageCheck {
            rawData
          }
        }
        primaryInsuranceCompanyPhone

        secondaryInsuranceDetails {
          InsuranceCompanyId
          insuranceCompany {
            id
            name
          }
          id
          insuranceState
          groupNumber
          memberId
          planType
        }
        secondaryInsuranceCompanyPhone

        locationDetails {
          id
          name
          address
          zip
          city
          state
          taxId
          NPI
          PTAN
        }

        prescriberDetails {
          id
          firstName
          lastName
          specialtyCode
          specialtyDescription
          NPI
          TIN
        }
        providerInNetwork
        prescriberPTAN
      }
    }
  }
`;

export const useBenefitsVerification = (id?: string | null) => {
  const { data, error, loading } = useQuery<
    GetBenefitsVerificationByIdQuery,
    GetBenefitsVerificationByIdQueryVariables
  >(getBenefitsVerificationByIdQuery, {
    variables: { id: id ?? "" },
    // Full bv id format is uuid, we can skip if id is null or is a number
    skip: id == null || !Number.isNaN(Number(id)),
  });

  return {
    benefitsVerification: (data?.benefitsVerificationById ??
      null) as BenefitsVerification | null,
    loading,
    error,
  };
};
