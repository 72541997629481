import {
  Authorization,
  MutationCheckOnFailedStatusCheckArgs,
  TrackingStatus,
  UserCredential,
  StatusNotFoundReason,
} from "@samacare/graphql";
import { useConfig } from "@@hooks/config";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  ButtonProps,
  ButtonGroup,
  Popover,
  SelectChangeEvent,
} from "@samacare/design/core";
import Grid from "@samacare/design/core/Grid";
import { userCredentialByFollowUpQuery } from "../../../graphql/Account";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { checkOnFailedStatusCheckMutation } from "../../../graphql/SupportedAuthorizationFollowUp";
import { useAlert } from "react-alert";
import CorrespondenceViewer from "@@components/CorrespondenceViewer";
import { useFileUpload } from "@@hooks/useFIleUpload";
import Chip from "@samacare/design/core/Chip";
import Dialog from "@samacare/design/core/Dialog";
import UploadFileOutlined from "@samacare/design/core/icons/UploadFileOutlined";
import { WebExtensionPubSub } from "../../../services/WebExtensionPubSub";
import { WebExtensionContext } from "../../../contexts/webExtension";

const ButtonLabel = Button as React.FC<
  ButtonProps<"label", { component?: "label" }>
>;

const ButtonLink = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }> & { blank?: string }
) => {
  return <Button {...props}>{props.children}</Button>;
};

const trackingStatusMapping = {
  [TrackingStatus.AcceptedError]: "an acceptable error occurred.",
  [TrackingStatus.AccessBlocked]: "access to something has been blocked.",
  [TrackingStatus.AlreadyTerminal]:
    "the situation is already in a terminal state.",
  [TrackingStatus.Archived]: "something has been moved to an archived state.",
  [TrackingStatus.BlockedOnMfa]:
    "blocked due to multi-factor authentication (MFA) failure.",
  [TrackingStatus.BrowserlessTimeout]:
    "a timeout occurred in a browserless environment.",
  [TrackingStatus.CaptchaRetryLimitReached]:
    "the limit for captcha retries has been reached.",
  [TrackingStatus.CheckedBySupport]: "checked or verified by support.",
  [TrackingStatus.CheckedByTracker]: "checked or verified by a tracker.",
  [TrackingStatus.LoginFailures]: "failures or errors during login attempts.",
  [TrackingStatus.MissingCredentials]:
    "credentials are missing or not provided.",
  [TrackingStatus.MissingIdentifier]: "an identifier is missing.",
  [TrackingStatus.MissingUi]: "user interface (UI) elements are missing.",
  [TrackingStatus.MultipleAuthsFound]: "multiple authentications were found.",
  [TrackingStatus.NavigationError]: "an error occurred during navigation.",
  [TrackingStatus.NoAccountsWithCreds]:
    "no accounts with credentials were found.",
  [TrackingStatus.NoAuthFound]: "no authentication or authorization found.",
  [TrackingStatus.PasswordResetNeeded]: "a password reset is required.",
  [TrackingStatus.ScrapingError]:
    "an error occurred while scraping or extracting data.",
  [TrackingStatus.StatusNotFound]: "the status was not found.",
  [TrackingStatus.TooOld]: "something is too old (possibly data or version).",
  [TrackingStatus.UnexpectedErrorDuringLogin]:
    "an unexpected error occurred during login.",
  [TrackingStatus.UnknownError]: "an unknown or unspecified error occurred.",
  [TrackingStatus.UnrecognizedStatus]: "the status is unrecognized.",
  [TrackingStatus.WrongCreds]: "incorrect credentials were provided.",
  [TrackingStatus.IncorrectIdentifierInput]:
    "incorrect identifiers were inputed.",
};

interface FileType {
  s3Key: string;
  fileName: string;
}

interface ValuesType {
  foundStatus: string | undefined;
  statusNotFoundReason: string | undefined;
  portalAuthorizationId: string | undefined;
  newNoteText: string | undefined;
  isAddScreenshotAsCorrespondence: boolean | undefined;
  awsKeysForNewCorrespondences: string[] | undefined;
}

export const FailedStatusCheckerModal: React.VoidFunctionComponent<{
  onClose: () => void;
  authorization: Authorization;
  followUpId: number;
  screenshotUrl: string | null | undefined;
}> = ({ onClose, authorization, followUpId, screenshotUrl }) => {
  const { DEFAULT_FIELDS, CONSTANTS } = useConfig();
  const { portal, trackingStatus, id } = authorization;
  const config = authorization.config as Record<string, unknown>;
  const alert = useAlert();
  const [statusFound, setStatusFound] = useState<boolean | null>(null);
  const [showScreenshot, setShowScreenshot] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState<FileType[] | null>(null);
  const [isScreenshotAdded, setIsScreenshotAdded] = useState(false);
  const { isWebExtensionConnected } = useContext(WebExtensionContext);
  const [supportFollowUp, setSupportFollowUp] = useState<ValuesType>({
    foundStatus: authorization.status,
    statusNotFoundReason: undefined,
    portalAuthorizationId: undefined,
    newNoteText: undefined,
    isAddScreenshotAsCorrespondence: undefined,
    awsKeysForNewCorrespondences: undefined,
  });
  const STATUSES_FOR_UI = [
    CONSTANTS.AUTHORIZATION_STATUSES.ACTION_REQUIRED,
    CONSTANTS.AUTHORIZATION_STATUSES.APPROVED,
    CONSTANTS.AUTHORIZATION_STATUSES.DENIED,
    CONSTANTS.AUTHORIZATION_STATUSES.DUPLICATE,
    CONSTANTS.AUTHORIZATION_STATUSES.MODIFIED_APPROVAL,
    CONSTANTS.AUTHORIZATION_STATUSES.NO_AUTH_REQUIRED,
    CONSTANTS.AUTHORIZATION_STATUSES.PENDING,
    CONSTANTS.AUTHORIZATION_STATUSES.VOIDED,
  ];

  const { isSuccess: isFileUploadSuccess, upload: uploadFile } =
    useFileUpload();

  const {
    data: userCredential,
    error: userCredentialError,
    loading: userCredentialLoading,
  } = useQuery<{ userCredentialByFollowUp: UserCredential }, unknown>(
    userCredentialByFollowUpQuery,
    {
      variables: { followUpId, isSupportQueue: true },
    }
  );

  const [checkOnFailedStatusCheck] = useMutation<
    void,
    MutationCheckOnFailedStatusCheckArgs
  >(checkOnFailedStatusCheckMutation, {
    refetchQueries: ["getUnresolvedFollowUps"],
  });

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files == null || files.length === 0) {
      return;
    }
    if (files.length > 1) {
      alert.error("Please select only one file");
      return;
    }
    try {
      const file = files[0];
      const uploadFileResult = await uploadFile(file);

      const { s3Key, fileName } = uploadFileResult;
      setSupportFollowUp({
        ...supportFollowUp,
        awsKeysForNewCorrespondences: [
          ...(supportFollowUp.awsKeysForNewCorrespondences ?? []),
          s3Key,
        ],
      });
      setAttachedFiles([...(attachedFiles ?? []), { s3Key, fileName }]);
    } catch (err) {
      alert.error("Failed to upload attachment. Please try again.");
    }
  };

  const handleChange =
    (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSupportFollowUp({
        ...supportFollowUp,
        [property]: event.target.value,
      });
    };

  const handleSelectChange =
    (property: string) => (event: SelectChangeEvent<unknown>) => {
      setSupportFollowUp({
        ...supportFollowUp,
        [property]: event.target.value as string,
      });
    };

  const handleDeleteFile = async (s3Key: string) => {
    const { awsKeysForNewCorrespondences } = supportFollowUp;
    if (awsKeysForNewCorrespondences)
      setSupportFollowUp({
        ...supportFollowUp,
        awsKeysForNewCorrespondences: _.remove(
          awsKeysForNewCorrespondences,
          s3Key
        ),
      });
    if (attachedFiles)
      setAttachedFiles(_.remove(attachedFiles, (file) => file.s3Key === s3Key));
  };

  const handleStatusFoundState = async (state: boolean): Promise<void> => {
    setStatusFound(state);
    setSupportFollowUp({
      ...supportFollowUp,
      isAddScreenshotAsCorrespondence: state ?? undefined,
      statusNotFoundReason: state
        ? undefined
        : supportFollowUp.statusNotFoundReason,
    });
  };

  const onSave = async () => {
    try {
      await checkOnFailedStatusCheck({
        variables: {
          authId: authorization.id as unknown as number,
          ...supportFollowUp,
          foundStatus: supportFollowUp.statusNotFoundReason
            ? undefined
            : supportFollowUp.foundStatus,
        },
      });
      alert.info("Success");
      onClose();
    } catch {
      alert.error("There was an error");
    }
  };

  useEffect(() => {
    if (
      trackingStatus === TrackingStatus.UnrecognizedStatus ||
      trackingStatus === TrackingStatus.StatusNotFound
    ) {
      setIsScreenshotAdded(!isScreenshotAdded);
      setSupportFollowUp({
        ...supportFollowUp,
        isAddScreenshotAsCorrespondence: true,
      });
    }
  }, []);

  const shouldShowMFA =
    !userCredentialError &&
    !userCredentialLoading &&
    userCredential?.userCredentialByFollowUp.portalMfaPhoneNumber &&
    CONSTANTS.MFA_REQUIRING_HUB_KEYS.includes(
      userCredential.userCredentialByFollowUp
        .hub as (typeof CONSTANTS.MFA_REQUIRING_HUB_KEYS)[number]
    );

  return (
    <Dialog onClose={onClose} maxWidth="md" fullWidth={true} open>
      <Box
        sx={{
          marginLeft: "16px 0px 16px 0px",
          overflowY: "auto",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: "24px 24px 16px 24px",
        }}
      >
        <Box sx={{ width: "80%" }}>
          <>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
              Failed Status Check
            </Typography>
            <Typography variant="body1" color="text.primary">
              Authorization was not found in the {portal?.title} portal because:
              <br />
              {trackingStatus
                ? trackingStatusMapping[trackingStatus]
                : "unknown"}
            </Typography>
          </>
          <>
            <Typography variant="h6" color="text.primary" pt={2}>
              Auth #{id}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <div>
                  {`${config[DEFAULT_FIELDS.PATIENT_LAST_NAME.key]}, ${
                    config[DEFAULT_FIELDS.PATIENT_FIRST_NAME.key]
                  }`}
                </div>
                <div>{config[DEFAULT_FIELDS.PATIENT_DOB.key]}</div>
                <div>
                  {config[DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key]} –
                  {config[DEFAULT_FIELDS.HCPCS_0.key]}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  {`Provider: ${
                    config[DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key]
                  }
                  ${config[DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key]}`}
                </div>
                <div>
                  {`Insurance Company: ${
                    config[DEFAULT_FIELDS.INSURANCE_COMPANY.key] ?? "N/A"
                  }`}
                </div>
                <div>
                  {`Member ID: ${config[DEFAULT_FIELDS.PATIENT_MEMBER_ID.key]}`}
                </div>
              </Grid>
            </Grid>
            <Box pt={1} ml={-1}>
              <ButtonLink
                href={`${window.location.origin}/#/new-authorization?step=${
                  (authorization.formDetails as { currentStep: string } | null)
                    ?.currentStep
                }&id=${id.toString()}`}
                target="_blank"
                rel="noopener noreferrer"
              >{`View Auth >`}</ButtonLink>
            </Box>
          </>
        </Box>

        <Box sx={{ width: "80%" }}>
          <Typography variant="subtitle2" color="text.primary" pt={2}>
            Portal Details
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <div>{portal?.title}</div>
              <div>{`MFA Phone Number: ${
                shouldShowMFA
                  ? userCredential.userCredentialByFollowUp.portalMfaPhoneNumber
                  : "N/A"
              }`}</div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                {authorization.portalKey ===
                  CONSTANTS.PORTAL_KEY.COVER_MY_MEDS ||
                portal?.hideCredentialsFromSupport ||
                !userCredential ? (
                  <Box>
                    <Box>Username: N/A</Box>
                    <Box>Password: N/A</Box>
                  </Box>
                ) : (
                  <Box>
                    <Box>
                      Username:{" "}
                      {userCredential?.userCredentialByFollowUp?.username}
                    </Box>
                    <Box>Password: *******</Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} ml={-1}>
              {portal?.loginUrl && (
                <>
                  {/* Note, we need this error state here currently because
                  SupportLaunchPortal fails if there are no credentials */}
                  {!isWebExtensionConnected || userCredentialError ? (
                    <ButtonLink
                      href={portal.loginUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`Visit Portal >`}</ButtonLink>
                  ) : (
                    <Button
                      onClick={() => {
                        const pubSub = new WebExtensionPubSub();
                        pubSub.send("SupportLaunchPortal", {
                          followUpId,
                        });
                      }}
                    >{`Launch Portal >`}</Button>
                  )}
                </>
              )}
              <ButtonLink
                href="https://console.plivo.com/accounts/login/?next=/dashboard/"
                disabled={!shouldShowMFA}
                target="_blank"
                rel="noopener noreferrer"
              >{`Link to Plivo >`}</ButtonLink>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "80%" }}>
          <Typography variant="subtitle2" color="text.primary" pt={2}>
            Status Details
          </Typography>
          <Box pb={1}>
            <Button
              onClick={() => setShowScreenshot(true)}
              disabled={!screenshotUrl}
            >{`View Screenshot >`}</Button>
            <Popover
              open={showScreenshot}
              onClose={() => setShowScreenshot(false)}
            >
              {screenshotUrl && (
                <CorrespondenceViewer
                  correspondence={{ fileURL: screenshotUrl }}
                  width="700px"
                  height="450px"
                />
              )}
            </Popover>
          </Box>
          <Box>
            <Grid pb={2} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <ButtonGroup>
                  <Button
                    onClick={async () => handleStatusFoundState(true)}
                    variant={statusFound === true ? "contained" : "outlined"}
                  >
                    Status Found
                  </Button>
                  <Button
                    onClick={async () => handleStatusFoundState(false)}
                    variant={statusFound === false ? "contained" : "outlined"}
                  >
                    Status Not Found
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                {statusFound === true && screenshotUrl && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isScreenshotAdded}
                        value={supportFollowUp.isAddScreenshotAsCorrespondence}
                        onChange={() => {
                          handleChange("isAddScreenshotAsCorrespondence");
                          setIsScreenshotAdded(!isScreenshotAdded);
                        }}
                        size="small"
                      />
                    }
                    label="Add screenshot as correspondence"
                  />
                )}
                {statusFound === false && (
                  <FormControl fullWidth>
                    <InputLabel size="small">Reason</InputLabel>
                    <Select
                      required={statusFound === false}
                      label="Reason"
                      size="small"
                      value={supportFollowUp.statusNotFoundReason}
                      onChange={handleSelectChange("statusNotFoundReason")}
                    >
                      {Object.values(StatusNotFoundReason).map((status) => (
                        <MenuItem key={status} value={status}>
                          {_.startCase(_.camelCase(status))}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  disabled={!statusFound}
                  label="Status"
                  size="small"
                  value={supportFollowUp.foundStatus}
                  onChange={handleSelectChange("foundStatus")}
                >
                  {STATUSES_FOR_UI.map((status) => (
                    <MenuItem key={status} value={status}>
                      {_.startCase(_.camelCase(status))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Portal Authorization ID #"
                size="small"
                fullWidth
                onSelect={handleChange("portalAuthorizationId")}
                defaultValue={authorization.portalAuthorizationId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Additional Information"
                size="small"
                fullWidth
                onSelect={handleChange("newNoteText")}
              />
            </Grid>
          </Grid>
        </Box>

        <Stack spacing={2} mt={2}>
          <Fade in>
            <Box>
              <ButtonLabel
                startIcon={<UploadFileOutlined />}
                component="label"
                variant="outlined"
                size="small"
              >
                Add Attachment
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  onChange={onFileChange}
                />
              </ButtonLabel>
            </Box>
          </Fade>
          <Box flexDirection="row">
            {isFileUploadSuccess &&
              attachedFiles &&
              attachedFiles.map(({ fileName, s3Key }) => (
                <Chip
                  key={s3Key}
                  clickable
                  label={fileName}
                  onDelete={async () => handleDeleteFile(s3Key)}
                  size="small"
                />
              ))}
          </Box>
        </Stack>

        <Box
          pt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={
              statusFound === null ||
              (statusFound === false &&
                supportFollowUp.statusNotFoundReason == null)
            }
            onClick={onSave}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
