import React from "react";
import { Typography } from "@samacare/design";
import SubmitButtons from "@@components/SubmitButtons";
import { Account } from "../../../../../monorepo/packages/graphql";
import CoverLetter from "./CoverLetter";
import UploadAttachments from "./UploadAttachments";

interface AttachmentsProps {
  onBack: () => void;
  onSubmit: () => void;
  onCheckboxChange: (b: boolean) => void;
  checkboxValue: boolean;
  coverLetterNote: string;
  setCoverLetterNote: (s: string) => void;
  attachments: [];
  account: Account;
  authorizationId: string;
  showEMRDocumentOption: boolean;
  disabled: boolean;
}

const Attachments: (props: AttachmentsProps) => JSX.Element = ({
  onBack,
  onSubmit,
  onCheckboxChange,
  checkboxValue,
  coverLetterNote,
  setCoverLetterNote,
  attachments,
  account,
  authorizationId,
  showEMRDocumentOption,
  disabled,
}: AttachmentsProps) => {
  return (
    <div style={{ marginTop: "16px" }}>
      <div style={{ marginBottom: "24px" }}>
        <Typography variant="h6">Cover Letter and Attachments</Typography>
        <Typography variant="body2">
          Provide additional information needed to submit prior authorization.
        </Typography>
      </div>
      <CoverLetter
        onCheckboxChange={onCheckboxChange}
        checkboxValue={checkboxValue}
        coverLetterNote={coverLetterNote}
        setCoverLetterNote={setCoverLetterNote}
        disabled={disabled}
      />
      <UploadAttachments
        attachments={attachments}
        account={account}
        authorizationId={authorizationId}
        showEMRDocumentOption={showEMRDocumentOption}
        multiple={true}
        header="Attachments"
        details="Attach additional documents (optional)"
        hideUploader={disabled}
        disabled={disabled}
      />
      <SubmitButtons
        back={onBack}
        submit={onSubmit}
        submitText="Review and Submit"
      />
    </div>
  );
};

export default Attachments;
