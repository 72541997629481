import { graphql } from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import {
  Mutation,
  MutationSetPatientOnAuthArgs,
  MutationRemovePatientOnAuthArgs,
} from "@samacare/graphql";

import authorizationInfo from "./fragments/authorizationInfo";
import patientInfo from "./fragments/patientInfo";
import { setPatientMutation, removePatientMutation } from "./Authorization";
import {
  CreatePatientMutationVariables,
  WriteAuthorizationResultsToPatientMutationVariables,
} from "@@generated/graphql";

const PATIENTS_QUERY = "Patients";

export const allPatientsQuery = gql`
  query Patients(
    $limit: Int
    $offset: Int
    $searchWord: String
    $sortModel: [GridSortItemInput]
  ) {
    patientsFindAll(
      limit: $limit
      offset: $offset
      searchWord: $searchWord
      sortModel: $sortModel
    ) {
      count
      rows {
        ...patientInfo
        primaryInsurance {
          memberId
        }
      }
    }
  }
  ${patientInfo}
`;

export const createPatientOnAuthMutation = gql`
  mutation createPatientOnAuthorization(
    $authorizationId: Int
    $patientProps: JSON
  ) {
    createPatientOnAuthorization(
      authorizationId: $authorizationId
      patientProps: $patientProps
    ) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;

export const withCreatePatientOnAuthorization = graphql(
  createPatientOnAuthMutation,
  {
    name: "createPatientOnAuthorization",
  }
);

const updatePatientMutation = gql`
  mutation updatePatient($id: Int!, $patch: UpdatePatientArgs!) {
    updatePatientById(id: $id, patch: $patch) {
      ...patientInfo
    }
  }
  ${patientInfo}
`;

export const withUpdatePatient = graphql(updatePatientMutation, {
  name: "updatePatient",
});

export const findDuplicateAuthorizationsForPatientQuery = gql`
  query findDuplicateAuthorizationsForPatient($id: Int!) {
    findDuplicateAuthorizationsForPatient(id: $id) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;

export const withCreatePatientMutation = gql`
  mutation createPatient($object: CreatePatientArgs!) {
    createPatient(object: $object) {
      id
      firstName
      lastName
      dob
    }
  }
`;

export const useCreatePatient = () => {
  return useMutation<Mutation, CreatePatientMutationVariables>(
    withCreatePatientMutation
  );
};

export const withWriteAuthorizationResultsToPatient = gql`
  mutation writeAuthorizationResultsToPatient($id: Int!, $patientId: Int) {
    writeAuthorizationResultsToPatient(
      patientId: $patientId
      authorizationId: $id
    )
  }
`;

export const useWriteAuthorizationResultsToPatient = () => {
  return useMutation<
    Mutation,
    WriteAuthorizationResultsToPatientMutationVariables
  >(withWriteAuthorizationResultsToPatient);
};

export const useSetPatientOnAuth = () => {
  return useMutation<Mutation, MutationSetPatientOnAuthArgs>(
    setPatientMutation
  );
};

export const useRemovePatientOnAuth = () => {
  return useMutation<void, MutationRemovePatientOnAuthArgs>(
    removePatientMutation
  );
};

const deletePatientMutation = gql`
  mutation deletePatient($id: Int!) {
    deletePatient(id: $id)
  }
`;

export const useDeletePatient = () => {
  const [deletePatient] = useMutation(deletePatientMutation, {
    refetchQueries: () => [PATIENTS_QUERY],
  });

  return async (patientId: number) => {
    await deletePatient({
      variables: {
        id: patientId,
      },
    });
  };
};
