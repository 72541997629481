import gql from "graphql-tag";

export default gql`
  fragment institutionInfo on Institution {
    id
    name
    faxResponseNumber
    practicingSpecialty
    integrations {
      isEnabled
      integrationId
    }
    capturesPatientConsent
    featureFlags {
      Enrollments
      OptionalSignature
      MultiPartyAuthorization
      pharmacyAndMedicalBenefitFormsEnabled
      skipSendingToPhaxio
      Novartis
      NovartisProduction
      CareMetxEnrollment
      PortalMFA
    }
    multiPartyFaxNumber
    isTop
  }
`;
