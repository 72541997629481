import _ from "lodash";
import { Stack } from "@samacare/design";
import { AutocompleteField, TextField } from "@samacare/form2";
import { ServiceQuantityTypeCode } from "@samacare/graphql";
import { useFormContext } from "react-hook-form";

const toLabel = (value: string) => _.startCase(_.lowerCase(value));

const QuantityTypeOrder: ServiceQuantityTypeCode[] = [
  ServiceQuantityTypeCode.Units,
  ServiceQuantityTypeCode.Visits,
  ServiceQuantityTypeCode.Hours,
  ServiceQuantityTypeCode.Days,
  ServiceQuantityTypeCode.Months,
];

type QuantityProps = {
  required: boolean;
  disabled: boolean;
  name: string;
};

export const QuantityAndType: React.FC<QuantityProps> = ({
  required,
  disabled,
  name,
}) => {
  const { watch } = useFormContext();
  const quantityTypeTag = `${name}.quantityType`;
  const quantityType = watch(quantityTypeTag);

  return (
    <Stack direction="row" spacing={1}>
      <AutocompleteField<string, false, true, false>
        disableClearable
        getOptionLabel={(val: string) => toLabel(val)}
        options={QuantityTypeOrder}
        label="Qnty type"
        name={quantityTypeTag}
        required
        disabled={disabled}
        value={quantityType ?? null}
      />
      <TextField
        name={`${name}.quantity`}
        label="Qnty"
        placeholder={quantityType ? `Number of ${quantityType}` : ""}
        required={required}
        disabled={disabled}
        fullWidth
      />
    </Stack>
  );
};
