import _ from "lodash";
import { useState } from "react";
import { useAlert } from "react-alert";
import {
  DeleteInstitutionMutation,
  GetInstitutionsByIdsQuery,
  UpdateInstitutionForSamaUsersMutation,
} from "@@generated/graphql";
import {
  Institution,
  CapturesPatientConsent,
  IntegrationInstallation,
} from "@samacare/graphql";
import { enumValues } from "../../../util/enumUtils";
import {
  DETAILED_INSTITUTIONS_QUERY,
  deleteInstitutionMutation,
  updateInstitutionForSamaUsersMutation,
  SUPPORT_INSTITUTION_QUERY,
  withGetInstitutionsByIds,
} from "../../../graphql/Institution";
import { useConfig } from "@@hooks/config";
import { useMutation, useQuery } from "@apollo/client";
import {
  CheckboxField,
  FormProvider,
  useForm,
  AutocompleteField,
  SwitchField,
  TextField,
} from "@samacare/form";
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Typography,
} from "@samacare/design/core";
import { EmrConfigModal, IntegrationStatus } from "./EmrConfigModal";
import getIntegrationInstallationByInstitutionIdQueryFile from "./getIntegrationInstallationByInstitutionId.gql";
import { NextGenTestUploadModal } from "./NextGenTestUploadModal";
import { DocumentUploadStatus } from "./DocumentUploadStatus";
import { InternationalPhone } from "Segment/InternationalPhone";

type FeatureFlags = typeof window.CONFIG.CONSTANTS.FEATURE_FLAGS;
type FeatureFlagKeys = keyof FeatureFlags;
type FeatureFlagValues = FeatureFlags[FeatureFlagKeys];

const MULTI_PARTY_FAX_NUMBER = "multiPartyFaxNumber";
const PRACTICING_SPECIALTY = "practicingSpecialty";
const CAPTURES_PATIENT_CONSENT = "capturesPatientConsent";
const FAX_NUMBER = "faxResponseNumber";
const INSTITUTION_NAME = "name";
const TOP_PRACTICE = "isTop";

const InstitutionViewComponent: React.VoidFunctionComponent<{
  institution: Institution;
}> = ({ institution }) => {
  const CONFIG = useConfig();
  const alert = useAlert();
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isDocUploadModalOpen, setIsDocUploadModalOpen] = useState(false);

  const apiIntegrations = [
    CONFIG.CONSTANTS.INTEGRATION_TITLE.redoxAPI,
    CONFIG.CONSTANTS.INTEGRATION_TITLE.nextGen,
    CONFIG.CONSTANTS.INTEGRATION_TITLE.modernizingMedicine,
  ];

  const [deleteInstitution] = useMutation<DeleteInstitutionMutation>(
    deleteInstitutionMutation,
    {
      refetchQueries: [DETAILED_INSTITUTIONS_QUERY],
    }
  );

  const [updateInstitutionForSamaUsers] =
    useMutation<UpdateInstitutionForSamaUsersMutation>(
      updateInstitutionForSamaUsersMutation,
      {
        refetchQueries: [
          DETAILED_INSTITUTIONS_QUERY,
          SUPPORT_INSTITUTION_QUERY,
        ],
      }
    );

  const {
    data: integrationInstallationData,
    loading: integrationInstallationLoading,
  } = useQuery<{
    getIntegrationInstallationByInstitutionId: IntegrationInstallation;
  }>(getIntegrationInstallationByInstitutionIdQueryFile, {
    variables: { institutionId: institution.id },
  });

  const enabledFeatureFlags = _.reduce(
    institution.featureFlagsRaw,
    (acc, flag) => ({
      ...acc,
      [flag as string]: true,
    }),
    {}
  ) as { [key in FeatureFlagValues]: boolean };

  const defaultValues = {
    ...enabledFeatureFlags,
    [MULTI_PARTY_FAX_NUMBER]: institution.multiPartyFaxNumber || "",
    [FAX_NUMBER]: institution.faxResponseNumber || "",
    [PRACTICING_SPECIALTY]: institution.practicingSpecialty || "",
    [CAPTURES_PATIENT_CONSENT]: institution.capturesPatientConsent || "",
    [INSTITUTION_NAME]: institution.name || "",
    [TOP_PRACTICE]: institution.isTop || false,
  };

  const methods = useForm({
    defaultValues,
  });

  const formData = methods.watch();

  const handleDelete = async () => {
    try {
      await deleteInstitution({ variables: { id: institution.id } });
      alert.success("Success");
    } catch {
      alert.error(
        "Error deleting institution. Make sure you have deleted all authorizations associated with this institution"
      );
    }
  };
  const { FEATURE_FLAGS } = CONFIG.CONSTANTS;

  const updateSelectedInstitution = async (data: typeof defaultValues) => {
    try {
      if (data.MultiPartyAuthorization && !data.multiPartyFaxNumber) {
        alert.error(
          "Please set multi party fax number or unset multiparty feature flag"
        );
      } else {
        if (!data.MultiPartyAuthorization) {
          methods.setValue(MULTI_PARTY_FAX_NUMBER, "");
        }

        if (!data[FAX_NUMBER]) {
          alert.error("Please set fax number");
          return;
        }

        const featureFlagsRaw = _.values(FEATURE_FLAGS).filter(
          (flag) => data[flag]
        );

        const input = _.reduce(
          data,
          (acc, val, key) => ({ ...acc, [key]: val === "" ? null : val }),
          {}
        ) as typeof defaultValues;

        await updateInstitutionForSamaUsers({
          variables: {
            id: institution.id,
            patch: {
              ...input,
              featureFlags: featureFlagsRaw,
            },
          },
        });
        alert.success("Success");
      }
    } catch (e) {
      alert.error(
        `There was an error updating this institution: ${(e as Error).message}`
      );
    }
  };

  const apiIntegrationEnabled =
    integrationInstallationData?.getIntegrationInstallationByInstitutionId
      ?.isEnabled &&
    apiIntegrations.includes(
      integrationInstallationData?.getIntegrationInstallationByInstitutionId
        ?.integrationId
    );
  const isNextGenConfigured =
    integrationInstallationData?.getIntegrationInstallationByInstitutionId
      ?.integrationId === CONFIG.CONSTANTS.INTEGRATION_TITLE.nextGen;

  return (
    <Stack direction="column" spacing={2}>
      {institution.id && !integrationInstallationLoading && (
        <EmrConfigModal
          open={isConfigModalOpen}
          institution={institution}
          onClose={() => setIsConfigModalOpen(false)}
          integrationInstallation={
            integrationInstallationData?.getIntegrationInstallationByInstitutionId
          }
        />
      )}
      {isNextGenConfigured &&
        integrationInstallationData?.getIntegrationInstallationByInstitutionId
          .isEnabled && (
          <NextGenTestUploadModal
            open={isDocUploadModalOpen}
            onClose={() => setIsDocUploadModalOpen(false)}
            institutionId={parseInt(institution.id)}
          />
        )}
      <FormProvider {...methods}>
        <form>
          <Stack direction="column" spacing={2}>
            <Typography variant="h5">{institution.name}</Typography>
            <Stack
              direction="column"
              spacing={2}
              width={({ spacing }) => spacing(45)}
            >
              <TextField
                name={INSTITUTION_NAME}
                label={_.startCase(INSTITUTION_NAME)}
                fullWidth
                required
              />
              <InternationalPhone
                name={FAX_NUMBER}
                label={_.startCase(FAX_NUMBER)}
                number={formData[FAX_NUMBER]}
                onChange={(e: any) => {
                  methods.setValue(FAX_NUMBER, e.target.value);
                }}
                required={true}
              />

              {_.includes(
                institution.featureFlagsRaw,
                FEATURE_FLAGS.MultiPartyAuthorization
              ) && (
                <InternationalPhone
                  name={MULTI_PARTY_FAX_NUMBER}
                  label={_.startCase(MULTI_PARTY_FAX_NUMBER)}
                  number={formData[MULTI_PARTY_FAX_NUMBER]}
                  onChange={(e: any) => {
                    methods.setValue(MULTI_PARTY_FAX_NUMBER, e.target.value);
                  }}
                  required={true}
                />
              )}
              <AutocompleteField
                name={PRACTICING_SPECIALTY}
                label={_.startCase(PRACTICING_SPECIALTY)}
                options={_.keys(CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES)}
                value={methods.watch(PRACTICING_SPECIALTY)}
              />
              <AutocompleteField
                name={CAPTURES_PATIENT_CONSENT}
                label={_.startCase(CAPTURES_PATIENT_CONSENT)}
                options={enumValues(CapturesPatientConsent)}
                value={methods.watch(CAPTURES_PATIENT_CONSENT)}
              />
              <FormControlLabel
                control={
                  <SwitchField
                    defaultChecked={institution.isTop}
                    name={TOP_PRACTICE}
                  />
                }
                label="Top Practice"
              />
            </Stack>

            <Box width={({ spacing }) => spacing(80)}>
              <Typography pb={2} variant="h6">
                EMR Setup
              </Typography>
              <Stack direction="column" spacing={2} pl={4}>
                <Stack direction="row" spacing={3} alignItems="flexStart">
                  <Typography fontWeight="bold"> 1. </Typography>
                  <Stack direction="column" spacing={1}>
                    <Button
                      variant="outlined"
                      onClick={() => setIsConfigModalOpen(true)}
                    >
                      Setup EMR Integration Now
                    </Button>
                    <IntegrationStatus
                      integrationInstallation={
                        integrationInstallationData?.getIntegrationInstallationByInstitutionId
                      }
                    />
                  </Stack>
                </Stack>

                {apiIntegrationEnabled && (
                  <Stack direction="row" spacing={3} alignItems="flexStart">
                    <Typography fontWeight="bold"> 2. </Typography>
                    <Stack direction="column">
                      <Typography>Test setup with patient:</Typography>
                      <Typography fontWeight="bold">
                        {`${integrationInstallationData?.getIntegrationInstallationByInstitutionId.testFirstName} ${integrationInstallationData?.getIntegrationInstallationByInstitutionId.testLastName}`}
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {/* Only show document upload status/button for nextGen */}
                {apiIntegrationEnabled && isNextGenConfigured && (
                  <Stack direction="row" spacing={3} alignItems="flexStart">
                    <Typography fontWeight="bold"> 3. </Typography>
                    <Stack direction="column" spacing={1}>
                      <Button
                        disabled={
                          !integrationInstallationData
                            ?.getIntegrationInstallationByInstitutionId
                            ?.isEnabled
                        }
                        variant="outlined"
                        onClick={() => setIsDocUploadModalOpen(true)}
                      >
                        Test document upload
                      </Button>
                      <DocumentUploadStatus
                        integrationInstallation={
                          integrationInstallationData?.getIntegrationInstallationByInstitutionId
                        }
                      />
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Box>

            <Stack direction="row" spacing={2}>
              <Stack direction="column" minWidth="500px">
                <Typography variant="h6">Feature Flags</Typography>
                {_.map(_.values(FEATURE_FLAGS), (flag) => (
                  <CheckboxField
                    name={flag}
                    valueOverride={
                      institution.featureFlags?.[
                        flag as keyof Institution["featureFlags"]
                      ]
                    }
                    label={flag}
                  />
                ))}
              </Stack>

              <Stack direction="column">
                <Typography variant="h6">
                  Launch Darkly Feature Flags (Read Only)
                </Typography>
                {_.map(
                  _.omit(institution.launchDarklyFeatureFlags, "__typename"),
                  (value, key) => {
                    if (typeof value === "boolean") {
                      return (
                        <CheckboxField
                          name={`launchDarklyFeatureFlags.${key}`}
                          valueOverride={value}
                          disabled
                          label={key}
                        />
                      );
                    } else if (key === "AvailableEnrollmentPrograms") {
                      return (
                        <Stack direction="column">
                          <Typography mb={1} color="grey.A200">
                            AvailableEnrollmentPrograms
                          </Typography>
                          <Stack direction="column" ml={2} color="grey.A200">
                            {institution.launchDarklyFeatureFlags
                              ?.AvailableEnrollmentPrograms?.length === 0 && (
                              <Typography mb={1}>None</Typography>
                            )}
                            {_.map(
                              institution.launchDarklyFeatureFlags
                                ?.AvailableEnrollmentPrograms,
                              (item) => (
                                <CheckboxField
                                  name={item ?? ""}
                                  valueOverride={true}
                                  label={item ?? ""}
                                  disabled
                                />
                              )
                            )}
                          </Stack>
                        </Stack>
                      );
                    }
                  }
                )}
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" color="error" onClick={handleDelete}>
          Delete Institution
        </Button>
        <Button
          variant="contained"
          onClick={async () =>
            updateSelectedInstitution(
              _.omit(formData, "launchDarklyFeatureFlag")
            )
          }
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const InstitutionView: React.VoidFunctionComponent<{
  institutionId: number;
}> = ({ institutionId }) => {
  const { data: institutionsData } = useQuery<GetInstitutionsByIdsQuery>(
    withGetInstitutionsByIds,
    {
      variables: { institutionIds: [institutionId] },
    }
  );

  const institution = _.first(institutionsData?.getInstitutionsByIds ?? []);

  return (
    <>
      {institution && (
        <InstitutionViewComponent institution={institution as Institution} />
      )}
      {!institution && <Box>No Institution Found</Box>}
    </>
  );
};
