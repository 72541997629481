export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Date: { input: any; output: any; }
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: { input: any; output: any; }
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: any; output: any; }
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: any; output: any; }
  /** Arbitrary object */
  Object: { input: any; output: any; }
  /** A scalar type representing a RegExp instance. */
  RegExp: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  InstitutionId?: Maybe<Scalars['Int']['output']>;
  /** Allowed institution Ids for multi practice accounts */
  allowedInstitutionIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** Phone extension */
  extension?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** Whether this account should see portal tutorial */
  hidePortalTutorial?: Maybe<Scalars['Boolean']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The institution associated with this account */
  institution?: Maybe<Institution>;
  /** Whether this account belongs to a practice administrator */
  isAdmin: Scalars['Boolean']['output'];
  /** Whether this account has been deactivated */
  isDeactivated?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this account is locked out - expired or too many login attempts */
  isLockedOut?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this account has read only access */
  isReadOnly: Scalars['Boolean']['output'];
  /** Whether this account belongs to a SamaCare employee / system */
  isSamaUser: Scalars['Boolean']['output'];
  /** Whether this account is a system user */
  isSystemUser: Scalars['Boolean']['output'];
  lastLoggedIn?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** Ids of portals for which MFA has been configured */
  mfaConfiguredPortalIds: Array<Scalars['Int']['output']>;
  passwordDate?: Maybe<Scalars['DateTime']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Portal credentials belonging to the user, primarily used for updating password in portals */
  portalCredentials?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Phone number used to receive MFA codes */
  portalMfaPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** The websites where user created authorizations */
  sites?: Maybe<Array<Maybe<Site>>>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type AddMfaPhoneNumberToAccountMutationResult = {
  __typename?: 'AddMfaPhoneNumberToAccountMutationResult';
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully added a MFA phone number to the account */
  success: Scalars['Boolean']['output'];
};

export type Authorization = {
  __typename?: 'Authorization';
  /** The drug option id used for this authorization */
  DrugOptionId?: Maybe<Scalars['Int']['output']>;
  /** List of HCPCS Codes */
  HCPCSCodes?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** List of ICD 10 codes */
  ICDs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The institution that created this authorization */
  InstitutionId?: Maybe<Scalars['ID']['output']>;
  /** The insurance company used for this authorization */
  InsuranceCompanyId?: Maybe<Scalars['ID']['output']>;
  /** The patient for whom this authorization is created */
  PatientId?: Maybe<Scalars['ID']['output']>;
  /** The portal used to submit this authorization */
  PortalId?: Maybe<Scalars['ID']['output']>;
  /** The site used for this authorization */
  SiteId?: Maybe<Scalars['ID']['output']>;
  /** The patient for whom this authorization is created */
  attachments?: Maybe<Array<Maybe<File>>>;
  /** How the front end should render the caremetx enrollment module */
  caremetxEnrollmentDisplayStatus?: Maybe<CaremetxEnrollmentDisplayStatus>;
  /** The configuration that will be used to populate the form */
  config: Scalars['JSON']['output'];
  /** Get all correspondences for an authorization */
  correspondences: Array<AuthorizationCorrespondence>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Account>;
  /** The user that created this authorization */
  createdById?: Maybe<Scalars['ID']['output']>;
  createdByUser?: Maybe<Scalars['Boolean']['output']>;
  /** Custom portal steps */
  customPortalSteps?: Maybe<Array<Maybe<CustomPortalStep>>>;
  /** When the most recent status was set */
  dateOfCurrentStatus: Scalars['DateTime']['output'];
  dateOfService?: Maybe<Scalars['DateTime']['output']>;
  /** Whether a terminal authorization status should show a warning about whether been reviewed by its practice */
  displayReviewWarning: Scalars['Boolean']['output'];
  /** The durg code type of authorization service */
  drugCodeType?: Maybe<DrugCodeType>;
  /** The drug-specific configuration for this authorization */
  drugConfiguration?: Maybe<DrugConfigurationType>;
  /** Email reminder scheduled date for authorization */
  emailReminderAt?: Maybe<Scalars['String']['output']>;
  /** Email reminder scheduled description */
  emailReminderDescription?: Maybe<Scalars['String']['output']>;
  enrollment?: Maybe<Enrollment>;
  /** The company supporting the enrollment */
  enrollmentProvider?: Maybe<EnrollmentProviderType>;
  followUp?: Maybe<FollowUp>;
  /** The details related to the form - these are UI related details */
  formDetails?: Maybe<Scalars['JSON']['output']>;
  formFieldConfig?: Maybe<FormFieldConfig>;
  /** The name of the assignee */
  formId?: Maybe<Scalars['Int']['output']>;
  /** The title + description of the associated form */
  formInfo?: Maybe<Scalars['String']['output']>;
  /** The formType of the associated form */
  formType?: Maybe<Scalars['String']['output']>;
  /** Whether the authorization is currently hidden from expiring */
  hideFromExpiring: Scalars['Boolean']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The type of status capture at submission */
  identifierCaptureType?: Maybe<IdentifierCaptureType>;
  institution?: Maybe<Institution>;
  /** Institution specific authorization id */
  institutionAuthorizationId?: Maybe<Scalars['String']['output']>;
  insuranceCompany?: Maybe<InsuranceCompany>;
  /** Whether or not we have finished downloading the documents from their EMR */
  integratedEMRDocumentsDownloaded?: Maybe<Scalars['Boolean']['output']>;
  /** Files downloaded from our practice's EMR - most likely from OncoEMR - JSON configurations */
  integratedEMRFilesDownloaded?: Maybe<Array<Maybe<IntegratedEmrFilesDownloaded>>>;
  /** EMR procedures downloaded */
  integratedEMRProcedures?: Maybe<Array<Maybe<IntegratedEmrProcedures>>>;
  /** Whether the authorization is currently archived */
  isArchived: Scalars['Boolean']['output'];
  /**
   * CareMetx module feature to determine whether the associated form is the
   * generic form - used in cases where the payer or payer form is missing
   */
  isGenericForm?: Maybe<Scalars['Boolean']['output']>;
  /** Is this a referral to another doctor rather than an auth to a payer */
  isReferral: Scalars['Boolean']['output'];
  /** Can the authorization be resubmitted? */
  isResubmittable: Scalars['Boolean']['output'];
  /**
   * Determines whether the authorization is a SamaAssist authorization based on
   * whetherthere was an enrollment on file with a status that indicates successful
   * submission or if the institution ID or jcode is a PA-supported one
   */
  isSamaAssist?: Maybe<Scalars['Boolean']['output']>;
  /** denotes that this is a web extension portal auth */
  isWebExtension: Scalars['Boolean']['output'];
  /** When the authorization was last checked by status checker, default to submitted at */
  lastCheckedAt?: Maybe<Scalars['String']['output']>;
  /** Get latest correspondence for an authorization */
  latestCorrespondence?: Maybe<AuthorizationCorrespondence>;
  /** The latest note added to this authorization */
  latestNote?: Maybe<Note>;
  newEnrollment?: Maybe<NewEnrollmentObjectType>;
  patient?: Maybe<Patient>;
  portal?: Maybe<Portal>;
  /** The ID reference of this authorization in the portal */
  portalAuthorizationId?: Maybe<Scalars['String']['output']>;
  /** The hub associated with the portal, if portal authorization */
  portalHub?: Maybe<Scalars['String']['output']>;
  /** A key implies this is a portal authorization */
  portalKey?: Maybe<Scalars['String']['output']>;
  /** A user facing title for the portal */
  portalTitle?: Maybe<Scalars['String']['output']>;
  /** Uploaded attachments for a portal authorization - only used for the download forms modal in portal authorizations */
  portalUploadAttachments?: Maybe<Array<Maybe<Scalars['Object']['output']>>>;
  /** Has this authorization been automatically associated */
  requiresAssociationReview: Scalars['Boolean']['output'];
  /** Whether or not should re-send authorization to HCP fax number */
  resendToHCP?: Maybe<Scalars['Boolean']['output']>;
  site?: Maybe<Site>;
  /** The timestamp that we sort by - different than updated at timestamp */
  sortByTimestamp: Scalars['DateTime']['output'];
  /** The status of the authorization */
  status: AuthorizationStatusEnum;
  /** The type of status capture at submission */
  statusAtSubmissionCaptureType?: Maybe<StatusAtSubmissionCaptureType>;
  /** If the auth tracker failed due to a log in error */
  statusCheckLoginRequiresAttn?: Maybe<Scalars['Boolean']['output']>;
  /** The relevant auth flow steps for this authorization */
  steps: Array<AuthorizationStep>;
  submissionPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** When the authorization was submitted */
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The path of the most recent filled out PDF for this authorization */
  submittedFormURL?: Maybe<Scalars['String']['output']>;
  /** Whether the authorization supports enrollment */
  supportsEnrollment?: Maybe<Scalars['Boolean']['output']>;
  /** Correspondence tags associated with this authorization */
  tags: Array<TagType>;
  /** When the authorization entered a terminal state */
  terminalAt?: Maybe<Scalars['DateTime']['output']>;
  /** When a status is set to a terminal one, what method was it set via */
  terminalStatusUpdateType?: Maybe<TerminalStatusUpdateType>;
  /** The tracking status of the authorization */
  trackingStatus?: Maybe<TrackingStatus>;
  type?: Maybe<AuthorizationType>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  /** The version of the authorization. */
  version?: Maybe<Scalars['Int']['output']>;
};

/** A single paginated field on an Authorization. */
export type AuthorizationConnection = {
  __typename?: 'AuthorizationConnection';
  authorizations: Array<Authorization>;
  pageInfo: PageInfo;
  /** @deprecated Replaced by pageInfo.totalCount. */
  totalCount: Scalars['Int']['output'];
};

export enum AuthorizationConnectionSortByAttribute {
  LastUpdated = 'LAST_UPDATED',
  ServiceDate = 'SERVICE_DATE'
}

export type AuthorizationCorrespondence = {
  __typename?: 'AuthorizationCorrespondence';
  AuthorizationId?: Maybe<Scalars['ID']['output']>;
  InstitutionId?: Maybe<Scalars['ID']['output']>;
  authorizedProcedures: Array<Maybe<Scalars['JSON']['output']>>;
  code?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  fileAWSKey?: Maybe<Scalars['String']['output']>;
  fileURL?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  /** The fax number where the correspondence is sending from */
  originatingFaxNumber?: Maybe<Scalars['String']['output']>;
  patientLastName?: Maybe<Scalars['String']['output']>;
  patientMemberId?: Maybe<Scalars['String']['output']>;
  /**
   * The Authorization ID of the correspondence. We choose to set a pre-association
   * authorization ID when we know the authorization ID but are not yet ready to
   * associate the correspondence, since there is still meta-data to be gathered
   * from the correspondence. Use cases include: status checker screenshots when
   * status cannot be determined or decoded barcode IDs from fax responses.
   */
  preAssociationAuthorizationId?: Maybe<Scalars['ID']['output']>;
  rejectedAuthorizationIds: Array<Scalars['Int']['output']>;
  reviewNotRequired?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  /** The status of the authorization */
  suggestedStatus?: Maybe<AuthorizationStatusEnum>;
  type?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type AuthorizationCorrespondenceWhere = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
};

/** Metadata for allowing cursor-based pagination. */
export type AuthorizationEdge = {
  __typename?: 'AuthorizationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Authorization>;
};

export type AuthorizationError = {
  __typename?: 'AuthorizationError';
  AuthorizationForm?: Maybe<AuthorizationForm>;
  AuthorizationId?: Maybe<Scalars['String']['output']>;
  awsKey?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Email address of the user who created the authorization associated with the portal error */
  createdByUserEmail?: Maybe<Scalars['String']['output']>;
  /** Whether the authorization associated with the portal error has been deleted */
  hasAuthBeenDeleted?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the authorization associated with the portal error has been submitted */
  hasAuthBeenSubmitted?: Maybe<Scalars['Boolean']['output']>;
  hasBeenResolved?: Maybe<Scalars['Boolean']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  /** Name of the institution where the portal error originated from */
  institutionName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  resolutionDetails?: Maybe<Scalars['String']['output']>;
  resolutionStatus?: Maybe<Scalars['String']['output']>;
  resolvedBy?: Maybe<Account>;
  type?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum AuthorizationExtensionFilterValue {
  All = 'ALL',
  ExpensiveOnly = 'EXPENSIVE_ONLY',
  ExtensionsOnly = 'EXTENSIONS_ONLY',
  NonExtensionsOnly = 'NON_EXTENSIONS_ONLY'
}

export type AuthorizationForm = {
  __typename?: 'AuthorizationForm';
  /** Number of times form has been used for the given parameters */
  approvalCount?: Maybe<Scalars['Int']['output']>;
  /** Approval info for a form with given parameters */
  approvalRate?: Maybe<Scalars['Float']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the form */
  description?: Maybe<Scalars['String']['output']>;
  /** The filters associated with the form */
  formFilter?: Maybe<FormFilter>;
  /** The fax numbers associated with this form */
  formNumbers?: Maybe<Array<Maybe<FormNumber>>>;
  hasTaggedSignature?: Maybe<Scalars['Boolean']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** A list of AWS keys representing images of the pdf pages */
  imgKeys: Array<Scalars['String']['output']>;
  /** A list of signed urls corresponding to image representations for each page of the form. */
  imgURLs: Array<Scalars['String']['output']>;
  /** The insurance companies associated with this form. */
  insuranceCompanies?: Maybe<Array<Maybe<InsuranceCompany>>>;
  /** List of insurance companies names connected to this form. */
  insuranceCompaniesNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The form's archived status. */
  isArchived: Scalars['Boolean']['output'];
  /** List of Institution Ids where the authorization form should be hidden */
  isHiddenInstitutionIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** The authorization date last submitted by Account using this form */
  lastUsed?: Maybe<Scalars['DateTime']['output']>;
  /** Manualy configured boxes for user uploaded form */
  manuallyConfiguredBoxes?: Maybe<Array<Maybe<ManuallyConfiguredBoxes>>>;
  /** The signed url for the PDF file associated with the form. */
  pdfURL: Scalars['String']['output'];
  /** The form specification. */
  specification: Scalars['JSON']['output'];
  /** The width in pixels used when tagging the form. */
  taggedHeight: Scalars['Float']['output'];
  /** The width in pixels used when tagging the form. */
  taggedWidth: Scalars['Float']['output'];
  /** List of tags applied to this form. */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The title of the form */
  title?: Maybe<Scalars['String']['output']>;
  /** The type of the form */
  type?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/** A page of AuthorizationForms. */
export type AuthorizationFormConnection = {
  __typename?: 'AuthorizationFormConnection';
  edges: Array<AuthorizationFormEdge>;
  pageInfo: PageInfo;
};

export type AuthorizationFormEdge = {
  __typename?: 'AuthorizationFormEdge';
  node: AuthorizationForm;
};

export type AuthorizationFormUpdatePatch = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuthorizationFormsFilters = {
  InsuranceCompanyId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<AuthorizationFormsFiltersIdFilters>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<AuthorizationFormsFiltersTitleFilters>;
  type?: InputMaybe<AuthorizationFormsFiltersTypeFilters>;
};

export type AuthorizationFormsFiltersIdFilters = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AuthorizationFormsFiltersTitleFilters = {
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AuthorizationFormsFiltersTypeFilters = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorizationLog = {
  __typename?: 'AuthorizationLog';
  /** Association to Account table */
  AccountId?: Maybe<Scalars['Int']['output']>;
  /** Association to AuthorizationCorrespondence table */
  AuthorizationCorrespondenceId?: Maybe<Scalars['Int']['output']>;
  /** Association to File table */
  FileId?: Maybe<Scalars['Int']['output']>;
  account?: Maybe<Account>;
  assignee?: Maybe<Account>;
  authorizationCorrespondence?: Maybe<AuthorizationCorrespondence>;
  createdAt: Scalars['DateTime']['output'];
  /** Additional context for flagging or user info */
  details: Scalars['JSON']['output'];
  file?: Maybe<File>;
  type: AuthorizationLogType;
};

/** The type of the authorization log event */
export enum AuthorizationLogType {
  AttemptStatusCheck = 'ATTEMPT_STATUS_CHECK',
  AuthorizationAssigned = 'AUTHORIZATION_ASSIGNED',
  AuthorizationForwardedToPayer = 'AUTHORIZATION_FORWARDED_TO_PAYER',
  Duplication = 'DUPLICATION',
  FailedStatusCheck = 'FAILED_STATUS_CHECK',
  FollowUp = 'FOLLOW_UP',
  ManualAssociation = 'MANUAL_ASSOCIATION',
  RemoveUploadedFile = 'REMOVE_UPLOADED_FILE',
  Resubmission = 'RESUBMISSION',
  StatusChange = 'STATUS_CHANGE',
  Submission = 'SUBMISSION',
  SuccessfulFaxAssociation = 'SUCCESSFUL_FAX_ASSOCIATION',
  SuccessfulFaxTransmission = 'SUCCESSFUL_FAX_TRANSMISSION',
  SuccessfulStatusCheck = 'SUCCESSFUL_STATUS_CHECK',
  SuccessfulStatusCheckNoChange = 'SUCCESSFUL_STATUS_CHECK_NO_CHANGE',
  UndoAssociation = 'UNDO_ASSOCIATION',
  UndoAutoAssociation = 'UNDO_AUTO_ASSOCIATION',
  UndoManualAssociation = 'UNDO_MANUAL_ASSOCIATION',
  UserUploadedFile = 'USER_UPLOADED_FILE'
}

/** Information about the current Authorization page. */
export type AuthorizationPageInfo = {
  __typename?: 'AuthorizationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The possible status fields for Authorizations */
export enum AuthorizationStatusEnum {
  /** action_required */
  ActionRequired = 'action_required',
  /** appeal_pending */
  AppealPending = 'appeal_pending',
  /** approved */
  Approved = 'approved',
  /** caremetx_form_not_found */
  CaremetxFormNotFound = 'caremetx_form_not_found',
  /** caremetx_form_ready */
  CaremetxFormReady = 'caremetx_form_ready',
  /** denied */
  Denied = 'denied',
  /** draft */
  Draft = 'draft',
  /** duplicate */
  Duplicate = 'duplicate',
  /** edit_and_resubmit */
  EditAndResubmit = 'edit_and_resubmit',
  /** forms_research */
  FormsResearch = 'forms_research',
  /** modified_approval */
  ModifiedApproval = 'modified_approval',
  /** no_authorization_required */
  NoAuthorizationRequired = 'no_authorization_required',
  /** pending */
  Pending = 'pending',
  /** premium_rx_support */
  PremiumRxSupport = 'premium_rx_support',
  /** sending */
  Sending = 'sending',
  /** sending_failed */
  SendingFailed = 'sending_failed',
  /** This authorization has been sent to the payer, we have yet to hear back. Same as Pending Determination. */
  Sent = 'sent',
  /** sent_to_hcp */
  SentToHcp = 'sent_to_hcp',
  /** unrecognized */
  Unrecognized = 'unrecognized',
  /** voided */
  Voided = 'voided'
}

export type AuthorizationStep = {
  __typename?: 'AuthorizationStep';
  active: Scalars['Boolean']['output'];
  belongsTo?: Maybe<Scalars['Float']['output']>;
  key?: Maybe<AuthorizationStepKey>;
  number: Scalars['Float']['output'];
  section: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum AuthorizationStepKey {
  Attachments = 'ATTACHMENTS',
  CaremetxEnrollmentAndSubmit = 'CAREMETX_ENROLLMENT_AND_SUBMIT',
  EnrollmentAndSubmit = 'ENROLLMENT_AND_SUBMIT',
  FaxForm = 'FAX_FORM',
  FormDetails = 'FORM_DETAILS',
  Initiate = 'INITIATE',
  LomnEdit = 'LOMN_EDIT',
  LomnFill = 'LOMN_FILL',
  PatientAndInsurance = 'PATIENT_AND_INSURANCE',
  PdfTagger = 'PDF_TAGGER',
  PortalPrescriberAndLocationDetails = 'PORTAL_PRESCRIBER_AND_LOCATION_DETAILS',
  PortalRequestDetails = 'PORTAL_REQUEST_DETAILS',
  PrescriberAndLocationDetails = 'PRESCRIBER_AND_LOCATION_DETAILS',
  ReferralDetails = 'REFERRAL_DETAILS',
  RequestDetails = 'REQUEST_DETAILS',
  ReviewSignSubmit = 'REVIEW_SIGN_SUBMIT',
  ServiceAndDiagnosis = 'SERVICE_AND_DIAGNOSIS',
  Summary = 'SUMMARY'
}

export type AuthorizationTag = {
  __typename?: 'AuthorizationTag';
  AuthorizationId: Scalars['Int']['output'];
  TagId: Scalars['Int']['output'];
};

/** The type of the authorization (e.g. form, portal, etc.) */
export enum AuthorizationType {
  Edi = 'edi',
  Enrollment = 'enrollment',
  External = 'external',
  Form = 'form',
  MultiParty = 'multi_party',
  Portal = 'portal',
  PortalCopilot = 'portal_copilot',
  PortalExternal = 'portal_external',
  Referral = 'referral'
}

export type AuthorizationsFilterInputObjectType = {
  items?: InputMaybe<Array<InputMaybe<AuthorizationsFilterItemsInputObjectType>>>;
  logicOperator?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorizationsFilterItemsInputObjectType = {
  field: Scalars['String']['input'];
  operator: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorizationsFilters = {
  /** Filter authorizations by an id. */
  AuthorizationId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter authorizations by an account id. */
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter authorizations by drug name */
  drugName?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations which will have expired by this date. */
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter authorizations by the past end date. Defaults to false. */
  filterByExpired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter authorizations by upcoming date of service by days */
  filterByUpcomingDateOfServiceDays?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations by first name matching patient name. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations by institutionPatientId */
  institutionPatientId?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations by insurance company id. */
  insuranceCompanyId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter authorizations by the isArchived flag. */
  isArchived?: InputMaybe<AuthorizationsFiltersIsArchivedFilters>;
  /** Filter authorizations by drug code */
  jCode?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations by last name matching patient name. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations by a location id. */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter authorizations by a patient id. */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter authorizations by a portal key. */
  portal?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations by an portalAuthorizationId. */
  portalAuthorizationId?: InputMaybe<Scalars['String']['input']>;
  /** Filter authorizations by a prescriber id. */
  prescriberId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter authorizations by statuses. */
  statuses?: InputMaybe<Array<AuthorizationStatusEnum>>;
};

export type AuthorizationsFiltersIsArchivedFilters = {
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type AuthorizationsObjectType = {
  __typename?: 'AuthorizationsObjectType';
  count: Scalars['Int']['output'];
  rows: Array<Authorization>;
};

export type AuthorizationsSortInputObjectType = {
  field: Scalars['String']['input'];
  sort: Scalars['String']['input'];
};

export enum AvailityServiceReviewSearchMappingsKey {
  Aetna = 'AETNA',
  AetnaBetterHealth = 'AETNA_BETTER_HEALTH',
  AnthemCa = 'ANTHEM_CA',
  AnthemCo = 'ANTHEM_CO',
  AnthemCt = 'ANTHEM_CT',
  AnthemGa = 'ANTHEM_GA',
  AnthemIn = 'ANTHEM_IN',
  AnthemKy = 'ANTHEM_KY',
  AnthemMe = 'ANTHEM_ME',
  AnthemMo = 'ANTHEM_MO',
  AnthemNh = 'ANTHEM_NH',
  AnthemOh = 'ANTHEM_OH',
  AnthemVa = 'ANTHEM_VA',
  AnthemWi = 'ANTHEM_WI',
  BcbsFl = 'BCBS_FL',
  BcbsIl = 'BCBS_IL',
  BcbsNm = 'BCBS_NM',
  BcbsOk = 'BCBS_OK',
  BcbsTx = 'BCBS_TX',
  Humana = 'HUMANA'
}

export enum BatchKind {
  InsuranceCompany = 'InsuranceCompany',
  Portal = 'Portal',
  PortalKey = 'PortalKey'
}

export type BenefitsVerification = {
  __typename?: 'BenefitsVerification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  institution?: Maybe<Institution>;
  outcome?: Maybe<BenefitsVerificationOutcome>;
  patient: BenefitsVerificationPatient;
  request: BenefitsVerificationRequest;
  type: BenefitsVerificationTypeEnum;
};

export type BenefitsVerificationBasic = {
  __typename?: 'BenefitsVerificationBasic';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  insuranceCompanyName?: Maybe<Scalars['String']['output']>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  patient?: Maybe<BenefitsVerificationBasicTypePatient>;
  status?: Maybe<BenefitsVerificationOutcomeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BenefitsVerificationBasicTypePatient = {
  __typename?: 'BenefitsVerificationBasicTypePatient';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type BenefitsVerificationOutcome = {
  __typename?: 'BenefitsVerificationOutcome';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<BenefitsVerificationOutcomeData>;
  id?: Maybe<Scalars['ID']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type BenefitsVerificationOutcomeData = {
  __typename?: 'BenefitsVerificationOutcomeData';
  EBvResponse?: Maybe<CareMetxMedeBvEBvResponse>;
  HCPCSCodeDetails?: Maybe<Array<Maybe<HcpcsCodeDetailsDataType>>>;
  Html?: Maybe<Scalars['String']['output']>;
  TransactionID?: Maybe<Scalars['String']['output']>;
  authNumber?: Maybe<Scalars['String']['output']>;
  authProcess?: Maybe<Scalars['String']['output']>;
  copayDrug?: Maybe<Scalars['String']['output']>;
  copayOffice?: Maybe<Scalars['String']['output']>;
  copayPcp?: Maybe<Scalars['String']['output']>;
  copayServices?: Maybe<Scalars['String']['output']>;
  copaySpecialist?: Maybe<Scalars['String']['output']>;
  deductibleRemaining?: Maybe<Scalars['NonNegativeFloat']['output']>;
  deductibleTotal?: Maybe<Scalars['NonNegativeFloat']['output']>;
  drugBuyBillStatus?: Maybe<BenefitsVerificationOutcomeDrugBuyBillStatus>;
  drugBuyBillStatusForSecondaryInsurance?: Maybe<BenefitsVerificationOutcomeDrugBuyBillStatus>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalReferenceNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  insuranceCoverageStatus?: Maybe<BenefitsVerificationOutcomeInsuranceCoverageStatus>;
  isAuthOnFile?: Maybe<Scalars['Boolean']['output']>;
  isAuthRequired?: Maybe<Scalars['Boolean']['output']>;
  isDeductibleAppliedToOop?: Maybe<Scalars['Boolean']['output']>;
  isDeductibleMet?: Maybe<Scalars['Boolean']['output']>;
  isDrugCoveredByPlan?: Maybe<Scalars['Boolean']['output']>;
  isDrugCoveredBySecondaryInsurance?: Maybe<Scalars['Boolean']['output']>;
  isOopMet?: Maybe<Scalars['Boolean']['output']>;
  isStepTherapyRequired?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  oopAnnualCap?: Maybe<Scalars['NonNegativeFloat']['output']>;
  oopMax?: Maybe<Scalars['NonNegativeFloat']['output']>;
  oopRemaining?: Maybe<Scalars['NonNegativeFloat']['output']>;
  outcomeDataType?: Maybe<BenefitsVerificationOutcomeDataType>;
  payorPaFax?: Maybe<Scalars['String']['output']>;
  payorPaPhone?: Maybe<Scalars['String']['output']>;
  requestedBy?: Maybe<Scalars['String']['output']>;
  secondaryinsuranceCoverageStatus?: Maybe<BenefitsVerificationOutcomeInsuranceCoverageStatus>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<BenefitsVerificationOutcomeStatus>;
  stepTherapyProcess?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum BenefitsVerificationOutcomeDataType {
  Manual = 'MANUAL'
}

export enum BenefitsVerificationOutcomeDrugBuyBillStatus {
  Medical = 'MEDICAL',
  Pharmacy = 'PHARMACY',
  Unknown = 'UNKNOWN'
}

export type BenefitsVerificationOutcomeFields = {
  HCPCSCodeDetails?: InputMaybe<Array<InputMaybe<HcpcsCodeDetailsInputDataType>>>;
  authNumber?: InputMaybe<Scalars['String']['input']>;
  authProcess?: InputMaybe<Scalars['String']['input']>;
  copayDrug?: InputMaybe<Scalars['String']['input']>;
  copayOffice?: InputMaybe<Scalars['String']['input']>;
  copayPcp?: InputMaybe<Scalars['String']['input']>;
  copayServices?: InputMaybe<Scalars['String']['input']>;
  copaySpecialist?: InputMaybe<Scalars['String']['input']>;
  deductibleRemaining?: InputMaybe<Scalars['Float']['input']>;
  deductibleTotal?: InputMaybe<Scalars['Float']['input']>;
  drugBuyBillStatus?: InputMaybe<BenefitsVerificationOutcomeDrugBuyBillStatus>;
  drugBuyBillStatusForSecondaryInsurance?: InputMaybe<BenefitsVerificationOutcomeDrugBuyBillStatus>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  insuranceCoverageStatus?: InputMaybe<BenefitsVerificationOutcomeInsuranceCoverageStatus>;
  isAuthOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  isAuthRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isDeductibleAppliedToOop?: InputMaybe<Scalars['Boolean']['input']>;
  isDeductibleMet?: InputMaybe<Scalars['Boolean']['input']>;
  isDrugCoveredByPlan?: InputMaybe<Scalars['Boolean']['input']>;
  isDrugCoveredBySecondaryInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isOopMet?: InputMaybe<Scalars['Boolean']['input']>;
  isStepTherapyRequired?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  oopAnnualCap?: InputMaybe<Scalars['Float']['input']>;
  oopMax?: InputMaybe<Scalars['Float']['input']>;
  oopRemaining?: InputMaybe<Scalars['Float']['input']>;
  outcomeDataType?: InputMaybe<BenefitsVerificationOutcomeDataType>;
  payorPaFax?: InputMaybe<Scalars['String']['input']>;
  payorPaPhone?: InputMaybe<Scalars['String']['input']>;
  requestedBy?: InputMaybe<Scalars['String']['input']>;
  secondaryinsuranceCoverageStatus?: InputMaybe<BenefitsVerificationOutcomeInsuranceCoverageStatus>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<BenefitsVerificationOutcomeStatus>;
  stepTherapyProcess?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum BenefitsVerificationOutcomeInsuranceCoverageStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Unknown = 'UNKNOWN'
}

export enum BenefitsVerificationOutcomeStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type BenefitsVerificationPatient = {
  __typename?: 'BenefitsVerificationPatient';
  dateOfBirth: Scalars['LocalDate']['output'];
  firstName: Scalars['NonEmptyString']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  institutionPatientId?: Maybe<Scalars['NonEmptyString']['output']>;
  lastName: Scalars['NonEmptyString']['output'];
};

export type BenefitsVerificationRequest = {
  __typename?: 'BenefitsVerificationRequest';
  drugDetails: BenefitsVerificationRequestDrugDetails;
  locationDetails?: Maybe<Location>;
  patientDetails: BenefitsVerificationRequestPatientDetails;
  patientStatus?: Maybe<PatientStatusType>;
  prescribedDate?: Maybe<Scalars['DateTime']['output']>;
  prescriberDetails?: Maybe<Prescriber>;
  prescriberPTAN?: Maybe<Scalars['String']['output']>;
  primaryInsuranceCompanyPhone?: Maybe<Scalars['String']['output']>;
  primaryInsuranceDetails?: Maybe<InsurancePolicyObjectType>;
  providerInNetwork?: Maybe<Scalars['Boolean']['output']>;
  requestedBy?: Maybe<Account>;
  requestedById?: Maybe<Scalars['String']['output']>;
  secondaryInsuranceCompanyPhone?: Maybe<Scalars['String']['output']>;
  secondaryInsuranceDetails?: Maybe<InsurancePolicyObjectType>;
};

export type BenefitsVerificationRequestDrugDetails = {
  __typename?: 'BenefitsVerificationRequestDrugDetails';
  drugCode?: Maybe<Scalars['String']['output']>;
  drugName?: Maybe<Scalars['String']['output']>;
  drugOptionId?: Maybe<Scalars['String']['output']>;
  hcpcsCodes?: Maybe<Array<Maybe<Scalars['NonEmptyString']['output']>>>;
  icds?: Maybe<Array<Maybe<Scalars['NonEmptyString']['output']>>>;
  ndc?: Maybe<Scalars['String']['output']>;
};

export type BenefitsVerificationRequestPatientDetails = {
  __typename?: 'BenefitsVerificationRequestPatientDetails';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['LocalDate']['output']>;
  firstName?: Maybe<Scalars['NonEmptyString']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['NonEmptyString']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum BenefitsVerificationTypeEnum {
  Caremetx = 'CAREMETX',
  Novartis = 'NOVARTIS',
  Samacare = 'SAMACARE'
}

export enum CapturesPatientConsent {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

/** Enrollment response from CareMetx */
export type CareMetxEnrollmentResponseType = {
  __typename?: 'CareMetxEnrollmentResponseType';
  EnrollmentId?: Maybe<Scalars['ID']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  response?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CareMetxEnrollmentType>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Enrollment status and copay status response from CareMetx */
export type CareMetxEnrollmentResponsesType = {
  __typename?: 'CareMetxEnrollmentResponsesType';
  /** Copay status response from CareMetx */
  copayStatus?: Maybe<CareMetxEnrollmentResponseType>;
  /** Enrollment status response from CareMetx */
  enrollmentStatus?: Maybe<CareMetxEnrollmentResponseType>;
};

export enum CareMetxEnrollmentType {
  CopayStatus = 'COPAY_STATUS',
  EnrollmentStatus = 'ENROLLMENT_STATUS'
}

export type CareMetxMedeBvEBvResponse = {
  __typename?: 'CareMetxMedeBvEBvResponse';
  Status?: Maybe<Scalars['String']['output']>;
};

export type CareMetxMedeBvType = {
  __typename?: 'CareMetxMedeBvType';
  enrollmentId?: Maybe<Scalars['Int']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: SubmitCareMetxBenefitsVerificationStatus;
};

export type ColumnMap = {
  from: Scalars['String']['input'];
  to?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAuthorizationFromBvPatch = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAuthorizationSpecificFormResult = {
  __typename?: 'CreateAuthorizationSpecificFormResult';
  /** The error message that we will display on the front-end */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully create an Authorization Form and associate it to the current */
  success: Scalars['Boolean']['output'];
};

export type CreateBenefitsVerificationPatientInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  dob: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  institutionPatientId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  phoneType?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type CreateEdiAuthorizationResult = {
  __typename?: 'CreateEdiAuthorizationResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateIngestJobResult = {
  __typename?: 'CreateIngestJobResult';
  ingestJob: IngestJob;
  presignedPost: PresignedPost;
};

export type CreateLocationByNpiFromRegistryResult = {
  __typename?: 'CreateLocationByNpiFromRegistryResult';
  /** The error message we will use to communicate to end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully checked NPI registry for NPI and saved to Location table */
  success: Scalars['Boolean']['output'];
};

export type CreatePatientArgs = {
  InstitutionId: Scalars['Int']['input'];
  PatientSourceId?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dob: Scalars['Date']['input'];
  ehrId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  heightInches?: InputMaybe<Scalars['Int']['input']>;
  institutionPatientId?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  mrn?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType?: InputMaybe<PatientPhoneType>;
  primaryInsurance?: InputMaybe<InsurancePolicyInput>;
  redoxPatientIdentifiers?: InputMaybe<Array<RedoxPatientIdentifierInput>>;
  secondaryInsurance?: InputMaybe<InsurancePolicyInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  weightPounds?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePortalArgs = {
  enableFollowUps: Scalars['Boolean']['input'];
  formFieldConfigKey: Scalars['String']['input'];
  hideCredentialsFromSupport: Scalars['Boolean']['input'];
  hubKey?: InputMaybe<Scalars['String']['input']>;
  isLegacy: Scalars['Boolean']['input'];
  isTop: Scalars['Boolean']['input'];
  loginUrl: Scalars['String']['input'];
  requiresPhoneMfa: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type CreatePrescriberByNpiFromRegistryResult = {
  __typename?: 'CreatePrescriberByNpiFromRegistryResult';
  /** First name of the Prescriber */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Id of the Prescriber if successfully created */
  id?: Maybe<Scalars['String']['output']>;
  /** Last name of the Prescriber */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The error message we will use to communicate to end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully checked NPI registry for NPI and saved to Prescriber table */
  success: Scalars['Boolean']['output'];
};

export type CreateSubscriberArgs = {
  PatientSourceId: Scalars['Int']['input'];
  dob: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  redoxSubscriberIdentifiers?: InputMaybe<Array<RedoxSubscriberIdentifierInput>>;
};

export type CreateTrackerConfigArgs = {
  authTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  batchKind?: InputMaybe<BatchKind>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  exclusions?: InputMaybe<Array<Scalars['JSON']['input']>>;
  insuranceCompanyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  portalIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  portalKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  runAtHoursInLaTz?: InputMaybe<Array<Scalars['Int']['input']>>;
  trackerKey: TrackerKey;
};

export type CustomPortalStep = {
  __typename?: 'CustomPortalStep';
  display?: Maybe<Scalars['Object']['output']>;
  errorGroup?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageURL?: Maybe<Scalars['String']['output']>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  isTerminal?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  requiresHardReset?: Maybe<Scalars['Boolean']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['JSON']['output']>;
};

export type DeleteEnrollmentResult = {
  __typename?: 'DeleteEnrollmentResult';
  enrollmentId?: Maybe<Scalars['Int']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DrugConfigurationType = {
  __typename?: 'DrugConfigurationType';
  appealFields?: Maybe<Array<LomnField>>;
  appealFillStepHtml?: Maybe<Scalars['String']['output']>;
  appealLetterHtml?: Maybe<Scalars['String']['output']>;
  appealMarkdownTemplate?: Maybe<Scalars['String']['output']>;
  fieldInsuranceOrganization_note?: Maybe<Scalars['String']['output']>;
  fieldPrimaryDrugName_note?: Maybe<Scalars['String']['output']>;
  isiHtml?: Maybe<Scalars['String']['output']>;
  lomnFields?: Maybe<Array<LomnField>>;
  lomnMarkdownTemplate?: Maybe<Scalars['String']['output']>;
  patientAccessInfoHtml?: Maybe<Scalars['String']['output']>;
  reviewAndSubmitNote?: Maybe<Scalars['String']['output']>;
  useLetterOfMedicalNecessity?: Maybe<Scalars['Boolean']['output']>;
};

/** An option to choose from when setting primary drug */
export type DrugOption = {
  __typename?: 'DrugOption';
  code?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  drugName?: Maybe<Scalars['String']['output']>;
  expensive?: Maybe<Scalars['Boolean']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  sponsored?: Maybe<Scalars['Boolean']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type DrugOptionFeatures = {
  __typename?: 'DrugOptionFeatures';
  fullBenefitsVerification: Scalars['Boolean']['output'];
};

export type EditableBenefitsVerificationOutcome = {
  __typename?: 'EditableBenefitsVerificationOutcome';
  benefits_verification_id: Scalars['ID']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  created_at: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  state?: Maybe<BenefitsVerificationOutcomeStatus>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type Enrollment = {
  __typename?: 'Enrollment';
  /** The account ID */
  AccountId?: Maybe<Scalars['ID']['output']>;
  /** The institution ID */
  InstitutionId?: Maybe<Scalars['ID']['output']>;
  /** The insurance company ID */
  InsuranceCompanyId?: Maybe<Scalars['ID']['output']>;
  MedicationRequest?: Maybe<MedicationRequest>;
  /** The medication request ID */
  MedicationRequestId?: Maybe<Scalars['ID']['output']>;
  /** The patient ID */
  PatientId?: Maybe<Scalars['ID']['output']>;
  /** The prescriber ID */
  PrescriberId?: Maybe<Scalars['ID']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** The reference id from the enroller's system */
  externalId?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  questionnaire?: Maybe<Scalars['JSON']['output']>;
  /** The status of an enrollment */
  status: EnrollmentStatus;
  /** The type of enrollment */
  type: EnrollmentType;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type EnrollmentProgram = {
  __typename?: 'EnrollmentProgram';
  /** The channel name of enrollment program */
  channel: EnrollmentProgramChannel;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** The drugCode associated with a enrollment program */
  drugCode?: Maybe<Scalars['String']['output']>;
  /** The drugName associated with a enrollment program */
  drugName?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The label of an enrollment program */
  label: Scalars['String']['output'];
  /** The AWS key of the related pdf form */
  pdfKey?: Maybe<Scalars['String']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  questionnaire?: Maybe<QuestionnaireObjectType>;
  /** The submission type of an enrollment program */
  type: EnrollmentProgramTypeColumnGraphQlType;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum EnrollmentProgramChannel {
  Apellis = 'APELLIS',
  Assistrx = 'ASSISTRX',
  Eylea4U = 'EYLEA4U',
  Genentech = 'GENENTECH',
  Gooddays = 'GOODDAYS',
  Horizon = 'HORIZON'
}

export enum EnrollmentProgramTypeColumnGraphQlType {
  Api = 'API',
  Iframe = 'IFRAME',
  Pdf = 'PDF'
}

export enum EnrollmentProviderType {
  AssistRx = 'AssistRx',
  CareMetx = 'CareMetx',
  Genentech = 'Genentech',
  Horizon = 'Horizon',
  Unknown = 'Unknown'
}

export type EnrollmentServiceResponseObjectType = {
  __typename?: 'EnrollmentServiceResponseObjectType';
  id?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Scalars['JSON']['output']>;
  serviceLinkId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export enum EnrollmentStatus {
  Draft = 'DRAFT',
  FetchModuleError = 'FETCH_MODULE_ERROR',
  Skipped = 'SKIPPED',
  Submitted = 'SUBMITTED',
  SubmitError = 'SUBMIT_ERROR'
}

export enum EnrollmentType {
  Caremetx = 'CAREMETX',
  Genentech = 'GENENTECH'
}

export type EnrollmentUpdatePatchArgs = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<EnrollmentStatus>;
};

export type Favorite = {
  __typename?: 'Favorite';
  authorizationFormId?: Maybe<Scalars['Int']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  formNumberId?: Maybe<Scalars['Int']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  institutionId?: Maybe<Scalars['Int']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type FaxFilter = {
  __typename?: 'FaxFilter';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** List of states associated with this filter */
  states?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Describes this filter as either a Suggest or HideUnless type */
  type?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  BenefitsVerification?: Maybe<Scalars['Boolean']['output']>;
  CareMetxAPI?: Maybe<Scalars['Boolean']['output']>;
  CareMetxEnrollment?: Maybe<Scalars['Boolean']['output']>;
  Enrollments?: Maybe<Scalars['Boolean']['output']>;
  MultiPartyAuthorization?: Maybe<Scalars['Boolean']['output']>;
  Novartis?: Maybe<Scalars['Boolean']['output']>;
  NovartisProduction?: Maybe<Scalars['Boolean']['output']>;
  OptionalSignature?: Maybe<Scalars['Boolean']['output']>;
  PortalMFA?: Maybe<Scalars['Boolean']['output']>;
  ReferringProvider?: Maybe<Scalars['Boolean']['output']>;
  SpotlightEnabled?: Maybe<Scalars['Boolean']['output']>;
  pharmacyAndMedicalBenefitFormsEnabled?: Maybe<Scalars['Boolean']['output']>;
  skipSendingToPhaxio?: Maybe<Scalars['Boolean']['output']>;
};

export type FieldOptions = {
  __typename?: 'FieldOptions';
  fieldKey: Scalars['String']['output'];
  maxLength?: Maybe<Scalars['Int']['output']>;
};

export type File = {
  __typename?: 'File';
  awsKey?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** A publically provisioned download path to this URL */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  /** A publically provisioned path to this URL */
  url?: Maybe<Scalars['String']['output']>;
};

/** A benefit type applied to the form */
export enum FilterFormBenefitType {
  Any = 'ANY',
  MedicalOnly = 'MEDICAL_ONLY',
  PharmacyOnly = 'PHARMACY_ONLY'
}

export type FollowUp = {
  __typename?: 'FollowUp';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  isReviewed: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type FormFieldConfig = {
  __typename?: 'FormFieldConfig';
  additionalFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fieldOptionsList?: Maybe<Array<Maybe<FieldOptions>>>;
  hcpcsUnits?: Maybe<Array<HcpcsUnit>>;
  hiddenFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxHcpcsCount?: Maybe<Scalars['Int']['output']>;
  maxIcdCount?: Maybe<Scalars['Int']['output']>;
  requiredFields?: Maybe<Array<Scalars['String']['output']>>;
};

export enum FormFieldConfigKey {
  Aim = 'AIM',
  Availity = 'AVAILITY',
  BlueCrossComplete = 'BLUE_CROSS_COMPLETE',
  Cigna = 'CIGNA',
  CoverMyMeds = 'COVER_MY_MEDS',
  Default = 'DEFAULT',
  EvicoreCarecore = 'EVICORE_CARECORE',
  EvicoreMedSolutions = 'EVICORE_MED_SOLUTIONS',
  Hap = 'HAP',
  Hh = 'HH',
  HorizonNj = 'HORIZON_NJ',
  HumanaPromptPa = 'HUMANA_PROMPT_PA',
  Magellan = 'MAGELLAN',
  MartinsPoint = 'MARTINS_POINT',
  NewCenturyHealth = 'NEW_CENTURY_HEALTH',
  PearIndependenceBlueCross = 'PEAR_INDEPENDENCE_BLUE_CROSS',
  Premier = 'PREMIER',
  Radmd = 'RADMD',
  SelectHealth = 'SELECT_HEALTH',
  Tricare = 'TRICARE',
  Uhc = 'UHC',
  Umr = 'UMR',
  Wellcare = 'WELLCARE',
  Wellmed = 'WELLMED'
}

export type FormFilter = {
  __typename?: 'FormFilter';
  /** List of NDCs associated with this filter */
  NDCs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Describes the benefit type applicable for this form */
  benefitType?: Maybe<FilterFormBenefitType>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** A list of insurance types this form filter can be associated with */
  insuranceTypes?: Maybe<Array<Maybe<FormFilterInsuranceType>>>;
  /** List of JCodes associated with this filter */
  jCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  /** List of states associated with this filter */
  states?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Describes this filter as either a Suggest or HideUnless type */
  type?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/** An insurance types this form filter can be associated with */
export enum FormFilterInsuranceType {
  IsCommercial = 'IS_COMMERCIAL',
  IsMedicaid = 'IS_MEDICAID',
  IsMedicareAdvantage = 'IS_MEDICARE_ADVANTAGE',
  Other = 'OTHER'
}

export type FormNumber = {
  __typename?: 'FormNumber';
  InstitutionId?: Maybe<Scalars['ID']['output']>;
  associatedCompanies?: Maybe<Array<Maybe<InsuranceCompany>>>;
  associatedForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  /** If this is a number that is specific to an institution */
  canBeDeletedByEndUser?: Maybe<Scalars['Boolean']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the number */
  description?: Maybe<Scalars['String']['output']>;
  faxFilter?: Maybe<FaxFilter>;
  /** A description of the reason a number should not used */
  forbiddenReason?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** If this is a number that is specified at the top of the form */
  isFormSpecified?: Maybe<Scalars['Boolean']['output']>;
  /** If this number should be hidden from user workflow */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** List of Institution Ids where the form number should be hidden */
  isHiddenInstitutionIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** The phone number */
  number?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type ForwardCorrespondenceToPayerMutationResult = {
  __typename?: 'ForwardCorrespondenceToPayerMutationResult';
  /** Whether or not we successfully sent a fax to the payer */
  success: Scalars['Boolean']['output'];
};

export type FullBenefitsCheckData = {
  __typename?: 'FullBenefitsCheckData';
  id: Scalars['String']['output'];
  outcome?: Maybe<BenefitsVerificationOutcome>;
  requestedById?: Maybe<Scalars['String']['output']>;
  type?: Maybe<FullBenefitsCheckDataType>;
};

export enum FullBenefitsCheckDataType {
  Caremetx = 'CAREMETX',
  Novartis = 'NOVARTIS',
  Samacare = 'SAMACARE'
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other'
}

export type GetAllServiceCodesResult = {
  __typename?: 'GetAllServiceCodesResult';
  allServiceCodes: Array<ServiceCode>;
  drugOptions: Array<ServiceCode>;
  hcpcsCodes: Array<ServiceCode>;
};

export type GetEnrollmentDetailsResponse = {
  __typename?: 'GetEnrollmentDetailsResponse';
  enrollment?: Maybe<Enrollment>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  moduleHtml?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetInstitutionNameFromNextGen = {
  __typename?: 'GetInstitutionNameFromNextGen';
  /** The error message we will display to our end user */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** InstitutionName for the given SiteId */
  institutionName?: Maybe<Scalars['String']['output']>;
};

export type GetInstitutionNamesFromModernizingMedicine = {
  __typename?: 'GetInstitutionNamesFromModernizingMedicine';
  /** The error message we will display to our end user */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Potential InstitutionNames for the given SiteId */
  institutionNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GetOrCreateSiteId = {
  __typename?: 'GetOrCreateSiteId';
  /** The Site id */
  siteId?: Maybe<Scalars['String']['output']>;
  /** Where the operation was successful */
  success: Scalars['Boolean']['output'];
};

export type GetTestPatientsFromNextGenResult = {
  __typename?: 'GetTestPatientsFromNextGenResult';
  /** The error message we will display to our end user */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** List of test patients for the given InstitutionId */
  testPatients: Array<NextGenPatient>;
};

export type GetTreatmentsByPatientOutput = {
  __typename?: 'GetTreatmentsByPatientOutput';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<PatientWithTreatments>>>;
};

export type GetUnresolvedFollowUpPortalsAndInstitutionsResult = {
  __typename?: 'GetUnresolvedFollowUpPortalsAndInstitutionsResult';
  institutions: Array<Institution>;
  portals: Array<Portal>;
};

export type GetUnresolvedFollowUpsResult = {
  __typename?: 'GetUnresolvedFollowUpsResult';
  followUps: Array<SupportedAuthorizationFollowUp>;
  totalCount: Scalars['Int']['output'];
};

export type GridSortItemInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  sort: Scalars['String']['input'];
};

export type HcpcsCode = {
  __typename?: 'HCPCSCode';
  code?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  drugName?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  longDescription?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type HcpcsCodeDetailsDataType = {
  __typename?: 'HCPCSCodeDetailsDataType';
  /** HCPCS code */
  code: Scalars['String']['output'];
  /** Additional details for the given HCPCS code */
  response?: Maybe<Scalars['String']['output']>;
};

export type HcpcsCodeDetailsInputDataType = {
  /** HCPCS code */
  code: Scalars['String']['input'];
  /** Additional details for the given HCPCS code */
  response?: InputMaybe<Scalars['String']['input']>;
};

export type HcpcsUnit = {
  __typename?: 'HcpcsUnit';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type HideAuthorizationCorrespondenceResult = {
  __typename?: 'HideAuthorizationCorrespondenceResult';
  /** Whether or not we successfully hid an Authorization Correspondence */
  success: Scalars['Boolean']['output'];
};

/** The possible identifier (portal authorization id) capture types */
export enum IdentifierCaptureType {
  FullAuto = 'FULL_AUTO',
  PartAuto = 'PART_AUTO',
  UserDirect = 'USER_DIRECT'
}

export enum IdentifierKind {
  Ehr = 'ehr',
  Mrn = 'mrn',
  Ssn = 'ssn'
}

export enum IdentifierUse {
  Official = 'official',
  Old = 'old',
  Secondary = 'secondary',
  Temp = 'temp',
  Unknown = 'unknown',
  Usual = 'usual'
}

export type IdentityProvider = {
  __typename?: 'IdentityProvider';
  id: Scalars['String']['output'];
  institutionId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum IngestFailureCode {
  FileEmpty = 'FILE_EMPTY',
  FileTooLarge = 'FILE_TOO_LARGE',
  GenericUnexpectedError = 'GENERIC_UNEXPECTED_ERROR',
  HeaderButNoData = 'HEADER_BUT_NO_DATA',
  IncorrectHeaders = 'INCORRECT_HEADERS',
  InvalidFilePath = 'INVALID_FILE_PATH',
  TooFewColumns = 'TOO_FEW_COLUMNS',
  UnexpectedMessageType = 'UNEXPECTED_MESSAGE_TYPE',
  WrongFileType = 'WRONG_FILE_TYPE'
}

export type IngestJob = {
  __typename?: 'IngestJob';
  IngestTransformId: Scalars['Int']['output'];
  InstitutionId: Scalars['Int']['output'];
  addedCount?: Maybe<Scalars['Int']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  currentStage: PipelineStage;
  duplicateCount?: Maybe<Scalars['Int']['output']>;
  failedLoadCount?: Maybe<Scalars['Int']['output']>;
  failedValidationCount?: Maybe<Scalars['Int']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  ingestStages: Array<IngestStage>;
  ingestTransform: IngestTransform;
  originalRowCount?: Maybe<Scalars['Int']['output']>;
  pipelineEndAt?: Maybe<Scalars['DateTime']['output']>;
  pipelineStartAt?: Maybe<Scalars['DateTime']['output']>;
  s3FileName: Scalars['String']['output'];
  s3TimestampedFolder?: Maybe<Scalars['String']['output']>;
  startedWaitingForPipelineAt?: Maybe<Scalars['DateTime']['output']>;
  successCount?: Maybe<Scalars['Int']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  updatedCount?: Maybe<Scalars['Int']['output']>;
};

export type IngestStage = {
  __typename?: 'IngestStage';
  IngestJobId: Scalars['Int']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  failureCode?: Maybe<IngestFailureCode>;
  failureCount: Scalars['Int']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  retryCount: Scalars['Int']['output'];
  stage: PipelineStage;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  state: IngestStageState;
  successCount: Scalars['Int']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum IngestStageState {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Started = 'STARTED',
  WaitingToRetry = 'WAITING_TO_RETRY'
}

export type IngestTransform = {
  __typename?: 'IngestTransform';
  InstitutionId: Scalars['Int']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  ingestTransformItems: Array<IngestTransformItem>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type IngestTransformItem = {
  __typename?: 'IngestTransformItem';
  IngestTransformId: Scalars['Int']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  details?: Maybe<Scalars['JSON']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  type: IngestTransformType;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum IngestTransformType {
  InjectHeaderRow = 'INJECT_HEADER_ROW',
  MapColumns = 'MAP_COLUMNS',
  ReformatDate = 'REFORMAT_DATE'
}

export type Institution = {
  __typename?: 'Institution';
  /** List containing referral forms */
  ReferralForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  /** Whether this institution has captured patient consent for enrollment */
  capturesPatientConsent: CapturesPatientConsent;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  emrUploadCategory?: Maybe<Scalars['String']['output']>;
  extraPortalKeys: Array<Scalars['String']['output']>;
  /** Fax response number */
  faxResponseNumber?: Maybe<Scalars['String']['output']>;
  /** The features enabled for this institution */
  featureFlags?: Maybe<FeatureFlags>;
  featureFlagsRaw?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The institution associated with this account */
  integrations?: Maybe<Array<Maybe<IntegrationInstallation>>>;
  isTop: Scalars['Boolean']['output'];
  /** The launch darkly features enabled for this institution */
  launchDarklyFeatureFlags?: Maybe<LaunchDarklyFeatureFlags>;
  /** The locations associated with this institution */
  locations: Array<Location>;
  /** Fax number used to communicate with HCPs when performing multi party authorizations */
  multiPartyFaxNumber?: Maybe<Scalars['String']['output']>;
  /** Identifying name of institution */
  name: Scalars['String']['output'];
  practicingSpecialty?: Maybe<PracticingSpecialty>;
  /** The associated SFTP key for this institution */
  sftpKey?: Maybe<SftpKey>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type InstitutionUpdatePatch = {
  capturesPatientConsent?: InputMaybe<CapturesPatientConsent>;
};

export type Insurance = {
  InsuranceCompanyId: Scalars['Int']['input'];
  memberId: Scalars['String']['input'];
  relationshipToSubscriber: SubscriberRelationshipCode;
};

export type InsuranceCompany = {
  __typename?: 'InsuranceCompany';
  /** The key used to map this insurance company to an Availity service review search request */
  availityServiceReviewSearchMappingsKey?: Maybe<AvailityServiceReviewSearchMappingsKey>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The insurance company's archived status. */
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Plan's parent insurance company ID */
  planParentCompanyId?: Maybe<Scalars['ID']['output']>;
  /** Upper bound on number of days it takes for this payer to resolve */
  responseRangeMax?: Maybe<Scalars['Int']['output']>;
  /** Lower bound on number of days it takes for this payer to resolve */
  responseRangeMin?: Maybe<Scalars['Int']['output']>;
  searchTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The type of coding supported by this payer (J-code, NDC, or J-code and NDC) */
  supportedCodingTypes: InsuranceCompanySupportedCodingTypes;
  /** List of plan types associated with this insurance company */
  supportedPlanTypes: Array<Scalars['String']['output']>;
  /** List of states associated with this insurance company */
  supportedStates: Array<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type InsuranceCompanyFeatures = {
  __typename?: 'InsuranceCompanyFeatures';
  availityCoverageCheck: Scalars['Boolean']['output'];
};

export enum InsuranceCompanySupportedCodingTypes {
  Jcode = 'JCODE',
  Ndc = 'NDC',
  NdcAndJcode = 'NDC_AND_JCODE'
}

export type InsuranceCompanyUpdatePatch = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InsuranceCoverageCheckBenefit = {
  __typename?: 'InsuranceCoverageCheckBenefit';
  amounts?: Maybe<InsuranceCoverageCheckBenefitAmounts>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckBenefitAmountCoInsurance = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountCoInsurance';
  inNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountCoInsuranceValue>>>;
  notApplicableNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountCoInsuranceValue>>>;
  outOfNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountCoInsuranceValue>>>;
};

export type InsuranceCoverageCheckBenefitAmountCoInsuranceValue = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountCoInsuranceValue';
  amount?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  levelCode?: Maybe<Scalars['String']['output']>;
  payerNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  placeOfService?: Maybe<Scalars['String']['output']>;
  placeOfServiceCode?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckBenefitAmountCoPayment = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountCoPayment';
  inNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountCoPaymentValue>>>;
  notApplicableNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountCoPaymentValue>>>;
  outOfNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountCoPaymentValue>>>;
};

export type InsuranceCoverageCheckBenefitAmountCoPaymentValue = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountCoPaymentValue';
  amount?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  levelCode?: Maybe<Scalars['String']['output']>;
  payerNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  placeOfService?: Maybe<Scalars['String']['output']>;
  placeOfServiceCode?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckBenefitAmountDeductibleValue = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountDeductibleValue';
  amount?: Maybe<Scalars['String']['output']>;
  amountTimePeriod?: Maybe<Scalars['String']['output']>;
  amountTimePeriodCode?: Maybe<Scalars['String']['output']>;
  benefitBeginDate?: Maybe<Scalars['String']['output']>;
  benefitEndDate?: Maybe<Scalars['String']['output']>;
  coverageEndDate?: Maybe<Scalars['String']['output']>;
  coverageStartDate?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  levelCode?: Maybe<Scalars['String']['output']>;
  payerNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  planStartDate?: Maybe<Scalars['String']['output']>;
  remaining?: Maybe<Scalars['String']['output']>;
  remainingTimePeriod?: Maybe<Scalars['String']['output']>;
  remainingTimePeriodCode?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['String']['output']>;
  totalTimePeriod?: Maybe<Scalars['String']['output']>;
  totalTimePeriodCode?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckBenefitAmountDeductibles = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountDeductibles';
  inNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountDeductibleValue>>>;
  notApplicableNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountDeductibleValue>>>;
  outOfNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountDeductibleValue>>>;
};

export type InsuranceCoverageCheckBenefitAmountOutOfPocket = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountOutOfPocket';
  inNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountOutOfPocketValue>>>;
  notApplicableNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountOutOfPocketValue>>>;
  outOfNetwork?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefitAmountOutOfPocketValue>>>;
};

export type InsuranceCoverageCheckBenefitAmountOutOfPocketValue = {
  __typename?: 'InsuranceCoverageCheckBenefitAmountOutOfPocketValue';
  amount?: Maybe<Scalars['String']['output']>;
  amountTimePeriod?: Maybe<Scalars['String']['output']>;
  amountTimePeriodCode?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  levelCode?: Maybe<Scalars['String']['output']>;
  payerNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  remaining?: Maybe<Scalars['String']['output']>;
  remainingTimePeriod?: Maybe<Scalars['String']['output']>;
  remainingTimePeriodCode?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['String']['output']>;
  totalTimePeriod?: Maybe<Scalars['String']['output']>;
  totalTimePeriodCode?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckBenefitAmounts = {
  __typename?: 'InsuranceCoverageCheckBenefitAmounts';
  coInsurance?: Maybe<InsuranceCoverageCheckBenefitAmountCoInsurance>;
  coPayment?: Maybe<InsuranceCoverageCheckBenefitAmountCoPayment>;
  deductibles?: Maybe<InsuranceCoverageCheckBenefitAmountDeductibles>;
  outOfPocket?: Maybe<InsuranceCoverageCheckBenefitAmountOutOfPocket>;
};

export type InsuranceCoverageCheckData = {
  __typename?: 'InsuranceCoverageCheckData';
  id?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<InsuranceCoverageCheckPayer>;
  plans?: Maybe<Array<Maybe<InsuranceCoverageCheckPlan>>>;
  status?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
  subscriber?: Maybe<InsuranceCoverageCheckSubscriberType>;
};

export type InsuranceCoverageCheckInsuranceCompanyData = {
  __typename?: 'InsuranceCoverageCheckInsuranceCompanyData';
  name?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckInsurancePolicyData = {
  __typename?: 'InsuranceCoverageCheckInsurancePolicyData';
  memberId?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckPatientData = {
  __typename?: 'InsuranceCoverageCheckPatientData';
  dob?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckPayer = {
  __typename?: 'InsuranceCoverageCheckPayer';
  name?: Maybe<Scalars['String']['output']>;
  payerId?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckPlan = {
  __typename?: 'InsuranceCoverageCheckPlan';
  benefits?: Maybe<Array<Maybe<InsuranceCoverageCheckBenefit>>>;
  groupName?: Maybe<Scalars['String']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  planEndDate?: Maybe<Scalars['String']['output']>;
  planNumber?: Maybe<Scalars['String']['output']>;
  planStartDate?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  statusCode: Scalars['String']['output'];
};

export type InsuranceCoverageCheckSubscriberType = {
  __typename?: 'InsuranceCoverageCheckSubscriberType';
  birhtDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  genderCoe?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCoverageCheckType = {
  __typename?: 'InsuranceCoverageCheckType';
  activePlan?: Maybe<InsuranceCoverageCheckPlan>;
  checkedAt?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<InsuranceCoverageCheckData>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  inactivePlan?: Maybe<InsuranceCoverageCheckPlan>;
  /** Insurance company data snapshot when this coverage check was submitted */
  insuranceCompanyData?: Maybe<InsuranceCoverageCheckInsuranceCompanyData>;
  /** Insurance policy data snapshot when this coverage check was submitted */
  insurancePolicyData?: Maybe<InsuranceCoverageCheckInsurancePolicyData>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  /** Patient data snapshot when this coverage check was submitted */
  patientData?: Maybe<InsuranceCoverageCheckPatientData>;
  rawData?: Maybe<Scalars['JSON']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type InsurancePolicyInput = {
  InsuranceCompanyId?: InputMaybe<Scalars['ID']['input']>;
  groupNumber?: InputMaybe<Scalars['String']['input']>;
  insuranceState?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  periodEnd?: InputMaybe<Scalars['Date']['input']>;
  periodStart?: InputMaybe<Scalars['Date']['input']>;
  planNumber?: InputMaybe<Scalars['String']['input']>;
  planType?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InsurancePolicyStatus>;
};

export type InsurancePolicyObjectType = {
  __typename?: 'InsurancePolicyObjectType';
  InsuranceCompanyId?: Maybe<Scalars['ID']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  groupNumber?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  insuranceCompany?: Maybe<InsuranceCompany>;
  insuranceState?: Maybe<Scalars['String']['output']>;
  latestCoverageCheck?: Maybe<InsuranceCoverageCheckType>;
  memberId?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  supportsCoverageCheck?: Maybe<Scalars['Boolean']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum InsurancePolicyStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Draft = 'draft',
  EnteredInError = 'entered_in_error'
}

export type IntegratedEmrFilesDownloaded = {
  __typename?: 'IntegratedEMRFilesDownloaded';
  category?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type IntegratedEmrProcedures = {
  __typename?: 'IntegratedEMRProcedures';
  code?: Maybe<Scalars['String']['output']>;
  icd?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
};

export type IntegrationInstallation = {
  __typename?: 'IntegrationInstallation';
  /** Test data for CS */
  configError?: Maybe<Scalars['String']['output']>;
  /** Test data for CS */
  documentUploadLastSuccessfullyCheckedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Next Gen API installation config field */
  facilityCode?: Maybe<Scalars['String']['output']>;
  /** The ID of the institution that installed this integration */
  institutionId: Scalars['Int']['output'];
  /** The integration id that the integration installation created from */
  integrationId: Scalars['String']['output'];
  /** Whether or not the integration is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Test data for CS */
  patientSearchLastSuccessfullyCheckedAt?: Maybe<Scalars['DateTime']['output']>;
  settings?: Maybe<Settings>;
  /** Test data for CS */
  testFirstName?: Maybe<Scalars['String']['output']>;
  /** Test data for CS */
  testLastName?: Maybe<Scalars['String']['output']>;
};

export type InternalOnlyToggleUserTypeForTestingReturn = {
  __typename?: 'InternalOnlyToggleUserTypeForTestingReturn';
  /** The error message that we will display on the front-end */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully toggle the current account isSamaUser setting to a new value */
  success: Scalars['Boolean']['output'];
};

export type LomnField = {
  __typename?: 'LOMNField';
  key?: Maybe<Scalars['String']['output']>;
  optional?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type LaunchDarklyFeatureFlags = {
  __typename?: 'LaunchDarklyFeatureFlags';
  AvailableBenefitsVerificationsDrugs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  AvailableBenefitsVerificationsDrugsV2?: Maybe<Array<Maybe<LaunchDarklyFeatureFlagsAvailableBenefitsVerificationsDrugsV2Name>>>;
  AvailableEnrollmentPrograms?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  CareMetxEnrollmentLD?: Maybe<Scalars['Boolean']['output']>;
  DisplayPatientTreatmentView?: Maybe<Scalars['Boolean']['output']>;
  EnableBenefitsVerification?: Maybe<Scalars['Boolean']['output']>;
  EnableBlendedPortalWorkflow?: Maybe<Scalars['Boolean']['output']>;
  EnableEligibilityCheck?: Maybe<Scalars['Boolean']['output']>;
  EnableHealixDashboard?: Maybe<Scalars['Boolean']['output']>;
  EnableLetterOfMedicalNecessity?: Maybe<Scalars['Boolean']['output']>;
  EnableNovartisIptacopan?: Maybe<Scalars['Boolean']['output']>;
  EnableNovartisRLT?: Maybe<Scalars['Boolean']['output']>;
  EnablePatientFoundation?: Maybe<Scalars['Boolean']['output']>;
  EnableRRWEB?: Maybe<Scalars['Boolean']['output']>;
  EnableRRWEBInputMasking?: Maybe<Scalars['Boolean']['output']>;
  EnableWebExtensionSidebarVersion2?: Maybe<Scalars['Boolean']['output']>;
  InstitutionFormFilter?: Maybe<LaunchDarklyFeatureFlagsInstitutionFormFilterName>;
  NextGenDataSync?: Maybe<Scalars['Boolean']['output']>;
  RRWEBAllowedOrigins?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  SamaAssist?: Maybe<Scalars['Boolean']['output']>;
  SimplifiedFaxWorkflowUx?: Maybe<Scalars['Boolean']['output']>;
};

export type LaunchDarklyFeatureFlagsAvailableBenefitsVerificationsDrugsV2Name = {
  __typename?: 'LaunchDarklyFeatureFlagsAvailableBenefitsVerificationsDrugsV2Name';
  drugName?: Maybe<Scalars['String']['output']>;
  drugOptionId?: Maybe<Scalars['Int']['output']>;
};

export type LaunchDarklyFeatureFlagsInstitutionFormFilterName = {
  __typename?: 'LaunchDarklyFeatureFlagsInstitutionFormFilterName';
  allowList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  blockList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Location = {
  __typename?: 'Location';
  NPI?: Maybe<Scalars['String']['output']>;
  PTAN?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  facilityName?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type ManuallyConfiguredBoxes = {
  __typename?: 'ManuallyConfiguredBoxes';
  height: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  key?: Maybe<Scalars['String']['output']>;
  page: Scalars['Int']['output'];
  value?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ManuallyConfiguredBoxesInput = {
  height: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type MedicationRequest = {
  __typename?: 'MedicationRequest';
  AccountId?: Maybe<Scalars['ID']['output']>;
  InstitutionId?: Maybe<Scalars['ID']['output']>;
  InsuranceCompanyId?: Maybe<Scalars['ID']['output']>;
  LocationId?: Maybe<Scalars['ID']['output']>;
  PatientId?: Maybe<Scalars['ID']['output']>;
  PrescriberId?: Maybe<Scalars['ID']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** The cached diagnosis codes for the medical request */
  diagnoses?: Maybe<Array<MedicationRequestDiagnosis>>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The insurance company by id */
  insuranceCompany?: Maybe<InsuranceCompany>;
  /** The cached location for medical request */
  location?: Maybe<MedicationRequestLocation>;
  /** The cached diagnosis codes for the medical request */
  medications?: Maybe<Array<MedicationRequestMedication>>;
  /** The cached patient for medical request */
  patient?: Maybe<MedicationRequestPatient>;
  /** The cached prescriber for medical request */
  prescriber?: Maybe<MedicationRequestPrescriber>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicationRequestDiagnosis = {
  __typename?: 'MedicationRequestDiagnosis';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type MedicationRequestInput = {
  InstitutionId?: InputMaybe<Scalars['ID']['input']>;
  InsuranceCompanyId?: InputMaybe<Scalars['ID']['input']>;
  LocationId?: InputMaybe<Scalars['ID']['input']>;
  PatientId?: InputMaybe<Scalars['ID']['input']>;
  PrescriberId?: InputMaybe<Scalars['ID']['input']>;
  diagnoses?: InputMaybe<Scalars['JSON']['input']>;
  location?: InputMaybe<Scalars['JSON']['input']>;
  medications?: InputMaybe<Scalars['JSON']['input']>;
  patient?: InputMaybe<Scalars['JSON']['input']>;
  prescriber?: InputMaybe<Scalars['JSON']['input']>;
};

export type MedicationRequestLocation = {
  __typename?: 'MedicationRequestLocation';
  NPI?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type MedicationRequestMedication = {
  __typename?: 'MedicationRequestMedication';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
};

export type MedicationRequestPatient = {
  __typename?: 'MedicationRequestPatient';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id?: Maybe<Scalars['ID']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<Scalars['String']['output']>;
  primaryInsurance?: Maybe<InsurancePolicyObjectType>;
  secondaryInsurance?: Maybe<InsurancePolicyObjectType>;
  state?: Maybe<Scalars['String']['output']>;
  weightPounds?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type MedicationRequestPrescriber = {
  __typename?: 'MedicationRequestPrescriber';
  NPI?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  specialtyDescription?: Maybe<Scalars['String']['output']>;
};

export enum ModelType {
  Account = 'Account',
  AssistRxForm = 'AssistRXForm',
  Authorization = 'Authorization',
  AuthorizationCorrespondence = 'AuthorizationCorrespondence',
  BenefitsVerification = 'BenefitsVerification',
  Enrollment = 'Enrollment',
  FollowUp = 'FollowUp',
  Institution = 'Institution',
  PatientConsent = 'PatientConsent'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** add a MFA phone number to an account */
  addMfaPhoneNumberToAccount: AddMfaPhoneNumberToAccountMutationResult;
  /** Creates or recreates an SFTP key and associated SFTP transfer user */
  addSFTPKey?: Maybe<SftpKey>;
  /** Associates a form w/ form numbers and insurance companies */
  associateAuthorizationForm?: Maybe<AuthorizationForm>;
  /** Deletes an authorization by ID */
  authorizationDelete?: Maybe<Scalars['Int']['output']>;
  /** Submitting an authorization to be filled, can also send authorization through e-fax */
  authorizationSubmit?: Maybe<Authorization>;
  /** Cancel an authorization from sending by id */
  cancelAuthorizationSend?: Maybe<Authorization>;
  /** Permanently resolves a support follow up */
  checkOnFailedStatusCheck?: Maybe<Scalars['Boolean']['output']>;
  /** Creates a user account */
  createAccount?: Maybe<Account>;
  /** Creates a file record for specified awsKey and title */
  createAuthUploadFromAwsKey?: Maybe<File>;
  /** Create authorization, set default fields */
  createAuthorization?: Maybe<Authorization>;
  /** Create authorization correspondence and return it. */
  createAuthorizationCorrespondence?: Maybe<AuthorizationCorrespondence>;
  /** Creates an AuthorizationError for the specified authorization */
  createAuthorizationError?: Maybe<AuthorizationError>;
  /** Create authorization from BV, set default fields */
  createAuthorizationFromBv?: Maybe<Authorization>;
  /** Create authorization from an existing enrollment */
  createAuthorizationFromEnrollment?: Maybe<Authorization>;
  /** Creates a new authorization note */
  createAuthorizationNote?: Maybe<Note>;
  /** Create an Authorization Form and associte it to the current Authorization */
  createAuthorizationSpecificForm: CreateAuthorizationSpecificFormResult;
  /** Create benefits verification for a patient */
  createBenefitsVerification?: Maybe<BenefitsVerification>;
  /** Create benefits verification from a PA with patient and drug info */
  createBvFromAuth: Scalars['ID']['output'];
  /** Creates an EDI authorization */
  createEdiAuthorization: CreateEdiAuthorizationResult;
  /** Creates an Enrollment from existing Authorization */
  createEnrollmentFromAuthorization?: Maybe<NewEnrollmentObjectType>;
  /** Creates an FollowUp for the specified authorization */
  createFollowUp?: Maybe<FollowUp>;
  /** Creates a new form number */
  createFormNumber?: Maybe<FormNumber>;
  /** Creates a new Ingest Job */
  createIngestJob: CreateIngestJobResult;
  /** Creates an Institution with no props */
  createInstitution?: Maybe<Institution>;
  /** Check NPI registry for NPI and save to Location table */
  createLocationByNpiFromRegistry: CreateLocationByNpiFromRegistryResult;
  /** Creates a new Enrollment */
  createNewEnrollment?: Maybe<NewEnrollmentObjectType>;
  /** Create a Patient */
  createPatient?: Maybe<Patient>;
  /** Creates a new patient and sets the patient to the specified authorization */
  createPatientOnAuthorization?: Maybe<Authorization>;
  /** Create a Portal */
  createPortal?: Maybe<Portal>;
  /** Check NPI registry for NPI and save to Prescriber table */
  createPrescriberByNpiFromRegistry: CreatePrescriberByNpiFromRegistryResult;
  /** Creates a new authorization note */
  createReviewNote?: Maybe<Note>;
  /** Create authorization, set default fields */
  createSignature?: Maybe<File>;
  /** Create a Subscriber */
  createSubscriber?: Maybe<Subscriber>;
  /** Create a TrackerConfig */
  createTrackerConfig?: Maybe<TrackerConfig>;
  /** Deletes a Form by ID */
  deleteAuthorizationForm?: Maybe<Scalars['Int']['output']>;
  /** Deletes an authorization by ID */
  deleteCorrespondence?: Maybe<Scalars['Int']['output']>;
  /** Delete a draft enrollment */
  deleteDraftEnrollment?: Maybe<DeleteEnrollmentResult>;
  /** Deletes drug by id */
  deleteDrugOption?: Maybe<Scalars['Int']['output']>;
  /** Delete favorited authorization form or form number */
  deleteFavorite?: Maybe<Scalars['JSON']['output']>;
  /** Deletes a FormNumber by ID */
  deleteFormNumber?: Maybe<Scalars['Int']['output']>;
  /** Deletes an authorization by ID */
  deleteFormOrFaxFilter?: Maybe<Scalars['Int']['output']>;
  /** Deletes a saved prescriber signature by ID */
  deleteInstitution?: Maybe<Scalars['Int']['output']>;
  /** Deletes a location by ID */
  deleteLocation?: Maybe<Scalars['Int']['output']>;
  /** Delete a patient */
  deletePatient?: Maybe<Scalars['Int']['output']>;
  /** Delete a portal */
  deletePortal?: Maybe<Scalars['Boolean']['output']>;
  /** Deletes a saved prescriber signature by ID */
  deletePrescriberSignature?: Maybe<Scalars['Int']['output']>;
  /** Delete a tracker config */
  deleteTrackerConfig?: Maybe<Scalars['Boolean']['output']>;
  /** Resets an authorization after an incorrect auto-association */
  disassociateCorrespondence?: Maybe<Authorization>;
  /** Duplicate an authorization exactly */
  duplicateAuthorization?: Maybe<Authorization>;
  /** Sets a follow up as reviewed so it doesn't show up */
  followUpReviewed?: Maybe<FollowUp>;
  /** Submitting an authorization to be filled, can also send authorization through e-fax */
  followUpSubmit?: Maybe<FollowUp>;
  /** forward a multi_party correspondence to payer */
  forwardCorrespondenceToPayer: ForwardCorrespondenceToPayerMutationResult;
  /** Returns a password token to be used in a link */
  generatePasswordToken?: Maybe<Scalars['String']['output']>;
  /** This generates a downloadable PDF based on the template and the data provided */
  generatePdfDownload?: Maybe<Scalars['String']['output']>;
  /** Get Site id for a given URL */
  getOrCreateSiteId: GetOrCreateSiteId;
  /** Hides an authorization correspondence by id */
  hideAuthorizationCorrespondence: HideAuthorizationCorrespondenceResult;
  /** toggle isSamaUser on account */
  internalOnlyToggleUserTypeForTesting: InternalOnlyToggleUserTypeForTestingReturn;
  /** Create a mock fax message for the provided authorization and queue it */
  mockFax?: Maybe<Authorization>;
  /** Get the file URL of the export */
  practiceDataExport?: Maybe<Scalars['String']['output']>;
  /** Reassign a user for an authorization */
  reassingAuthorization?: Maybe<Authorization>;
  /** Refreshes a patient's primary insurance coverage check */
  refreshPrimaryInsuranceCoverageCheck: RefreshPrimaryInsuranceCoverageCheckResult;
  /** This regenerates the LOMN and then adds it as an attachment */
  regenerateLOMNAttachment: RegenerateLomnAttachmentResult;
  /** Removes the patient assigned to an authorization. */
  removePatientOnAuth?: Maybe<Authorization>;
  /** Sends us an email with error information */
  reportError?: Maybe<Scalars['JSON']['output']>;
  /** Sends us an email with the missing information request */
  reportMissingInformation?: Maybe<Scalars['JSON']['output']>;
  /** A user request for a new portal */
  requestNewPortalIntegration: RequestNewPortalIntegrationResponse;
  /** Sends a password reset email. */
  resetPassword?: Maybe<Scalars['JSON']['output']>;
  /** Send client side log to Mezmo */
  saveClientLog: SaveClientLog;
  /** Send client metric to DataDog */
  saveClientMetric: SaveClientMetric;
  /** Saves a reference to the session URL */
  saveSessionRecordingReference: SaveSessionRecordingReferenceResult;
  /** Sends an authorization submission to Phaxio */
  sendAuthorization?: Maybe<Authorization>;
  /** send response to HCP */
  sendResponsesToHCP: SendResponsesToHcpMutationResult;
  /** Submitting an authorization to be filled, can also send authorization through e-fax */
  sendSecureFile?: Maybe<Scalars['String']['output']>;
  /** Add attachmentIds to authorization, and updates the related File objects */
  setAuthorizationAttachments?: Maybe<Authorization>;
  /** Sets authorization reminder email date and note */
  setAuthorizationReminder?: Maybe<Authorization>;
  /** Sets a prescribers fields onto an authorization config */
  setConfigDetailsOnAuthorization?: Maybe<Authorization>;
  /** Create authorization correspondence and return it. */
  setCorrespondenceOnAuthorization?: Maybe<AuthorizationCorrespondence>;
  /** Set favorite authorization form or form number */
  setFavorite?: Maybe<Scalars['JSON']['output']>;
  /** set Manually Configured Boxes On Form */
  setManuallyConfiguredBoxesOnForm: SetManuallyConfiguredBoxesOnFormMutationResult;
  /** Update authorization by ID, filtered by user's institutionId */
  setOnAuthorizationJSONField?: Maybe<Authorization>;
  /** Sets a patient from an authorization. Sets patient related configurations as well */
  setPatientOnAuth?: Maybe<Authorization>;
  /** Sets a prescribers fields onto an authorization config */
  setPrescriberOnAuth?: Maybe<Authorization>;
  /** Submit Benefits Verification by id */
  submitBenefitsVerification?: Maybe<BenefitsVerification>;
  /** Submit a CareMetx Benefits Verification by id */
  submitCareMetxBenefitsVerification?: Maybe<CareMetxMedeBvType>;
  /** Submit an enrollment */
  submitEnrollment?: Maybe<NewEnrollmentObjectType>;
  /** Hides an authorization correspondence by id */
  toggleNoteHidden?: Maybe<Note>;
  /** Update authorization form by ID */
  trackPluginPortalPageHTML: TrackPluginPortalPageHtmlResponseType;
  /** Unlock an Account */
  unlockAccount?: Maybe<Account>;
  /** Removes a correspondence from an Authorization */
  unsetCorrespondenceFromAuthorization?: Maybe<AuthorizationCorrespondence>;
  /** Update the logged-in Account. */
  updateAccount?: Maybe<Account>;
  /** Update an account by email. */
  updateAccountByEmail?: Maybe<Account>;
  /** Update the institution associated with the account. */
  updateAccountInstitution?: Maybe<Account>;
  /** Update authorization by ID, filtered by user's institutionId */
  updateAuthorizationById?: Maybe<Authorization>;
  /** Update authorization correspondence and return it. */
  updateAuthorizationCorrespondence?: Maybe<AuthorizationCorrespondence>;
  /** Update authorization form by ID */
  updateAuthorizationFormById?: Maybe<AuthorizationForm>;
  /** Update authorization tags. */
  updateAuthorizationFormTags?: Maybe<AuthorizationForm>;
  /** Update authorization status and return updated authorization. */
  updateAuthorizationStatus?: Maybe<Authorization>;
  /** Update authorization tags. */
  updateAuthorizationTags?: Maybe<Authorization>;
  /** Update Benefits Verification by id */
  updateBenefitsVerification?: Maybe<BenefitsVerification>;
  /** Update benefits verification outcome. */
  updateBenefitsVerificationOutcome?: Maybe<EditableBenefitsVerificationOutcome>;
  /** Update authorization enrollment by ID, filtered by user's institutionId */
  updateEnrollmentById?: Maybe<Enrollment>;
  /** Modifies an existing form number or description */
  updateFormNumber?: Maybe<FormNumber>;
  /** Update institution as an admin or normal user, which restricts the user in what fields they can edit. */
  updateInstitution?: Maybe<Institution>;
  /** Update the institution as a Sama User, which includes the ability to edit data we would not want a normal user to edit. */
  updateInstitutionForSamaUsers?: Maybe<Institution>;
  /** Update the institution emr document upload category */
  updateInstitutionUploadCategory?: Maybe<Institution>;
  /** Update insurance company by ID */
  updateInsuranceCompanyById?: Maybe<InsuranceCompany>;
  /** Update authorization correspondence and return it. */
  updateLatestAuthorizationCorrespondence?: Maybe<AuthorizationCorrespondence>;
  /** Upsert new enrollment by ID, filtered by user's institutionId */
  updateNewEnrollmentById?: Maybe<NewEnrollmentObjectType>;
  /** Updates the password associated with an Account. */
  updatePassword?: Maybe<Scalars['JSON']['output']>;
  /** Update patient by ID, filtered by user's institutionId */
  updatePatientById?: Maybe<Patient>;
  /** Update portal by ID */
  updatePortal?: Maybe<Portal>;
  /** Hides portal tutorial by account */
  updateShowTutorial?: Maybe<Account>;
  /** Update statusCheckLoginRequiresAttn flag on authorizations */
  updateStatusCheckLoginRequiresAttn: UpdateLoginErrorDuringStatusCheckResult;
  /** Update patient by ID, filtered by user's institutionId */
  updateSubscriber?: Maybe<Subscriber>;
  /** Update trackerConfig by ID */
  updateTrackerConfig?: Maybe<TrackerConfig>;
  /** Upload Document To Modernizing Medicine API */
  uploadDocumentsToModernizingMedicine: UploadDocumentToModernizingMedicineResult;
  /** Upload Document To Next Gen API */
  uploadDocumentsToNextGen: UploadDocumentToNextGenResult;
  /** Upload documents to OncoEMR */
  uploadDocumentsToOncoEMR?: Maybe<Scalars['JSON']['output']>;
  /** Upload documents to Redox */
  uploadDocumentsToRedox: UploadDocumentsToRedoxResult;
  /** Upload Redox document reference to S3 */
  uploadRedoxDocumentToS3: RedoxDocumentReference;
  /** Upsert an Account. */
  upsertAccountForSamaUsers?: Maybe<Account>;
  /** Update a patient if ID is provided, otherwise create a new patient */
  upsertBenefitsCheckPatient?: Maybe<Patient>;
  /** Upserts a drug option */
  upsertDrugOption?: Maybe<DrugOption>;
  /** Upserts a form filter */
  upsertFaxFilter?: Maybe<FaxFilter>;
  /** Upserts a form */
  upsertForm?: Maybe<AuthorizationForm>;
  /** Upserts a form filter */
  upsertFormFilter?: Maybe<FormFilter>;
  /** Upsert insurance company. */
  upsertInsuranceCompany?: Maybe<InsuranceCompany>;
  /** Upsert a Location. */
  upsertLocation?: Maybe<Location>;
  /** Upload Document To Modernizing Medicine API */
  upsertModernizingMedicineIntegrationInstallation: UpsertModernizingMedicineIntegrationInstallationResult;
  /** Upload Document To Next Gen API */
  upsertNextGenIntegrationInstallation: UpsertNextGenIntegrationInstallationResult;
  /** Upsert an patient syncer credential. */
  upsertPatientIntegrationCredential?: Maybe<PatientDemographicIntegrationCredential>;
  /** Insert or update a set of portal credentials */
  upsertPortalCredentials?: Maybe<Account>;
  /** Upsert a Prescriber. */
  upsertPrescriber?: Maybe<Prescriber>;
  /** Create or update integrationInstalltion for Redox API */
  upsertRedoxIntegrationInstallation: UpsertRedoxIntegrationInstallationResult;
  /** Validate OncoEMR Credentials */
  validateCredentialsOncoEMR?: Maybe<Scalars['JSON']['output']>;
  /** Update the patient to the latest information, based on form responses by user */
  writeAuthorizationResultsToPatient?: Maybe<Array<Maybe<Scalars['NonEmptyString']['output']>>>;
  /** write enrollment info to enrollment form */
  writeEnrollmentInfoToEnrollmentForm: WriteEnrollmentInfoToEnrollmentFormResult;
  /** Update the patient to the latest information, based on form responses by user */
  writeIntegratedDetailsToAuthorization?: Maybe<Authorization>;
};


export type MutationAssociateAuthorizationFormArgs = {
  formNumbers?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  insuranceCompanies?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationAuthorizationDeleteArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAuthorizationSubmitArgs = {
  caremetxReadFaxFromConfig?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  send?: InputMaybe<Scalars['Boolean']['input']>;
  signatureId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCancelAuthorizationSendArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCheckOnFailedStatusCheckArgs = {
  authId: Scalars['Int']['input'];
  awsKeysForNewCorrespondences?: InputMaybe<Array<Scalars['String']['input']>>;
  foundStatus?: InputMaybe<Scalars['String']['input']>;
  isAddScreenshotAsCorrespondence?: InputMaybe<Scalars['Boolean']['input']>;
  newNoteText?: InputMaybe<Scalars['String']['input']>;
  portalAuthorizationId?: InputMaybe<Scalars['String']['input']>;
  statusNotFoundReason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAccountArgs = {
  InstitutionId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationCreateAuthUploadFromAwsKeyArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  awsKey?: InputMaybe<Scalars['String']['input']>;
  createFollowUp?: InputMaybe<Scalars['Boolean']['input']>;
  maxFileSizeMB?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAuthorizationArgs = {
  defaultFields?: InputMaybe<Scalars['JSON']['input']>;
  segmentDetails?: InputMaybe<SegmentDetailsInputCreatePa>;
};


export type MutationCreateAuthorizationCorrespondenceArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  authorizedProcedures?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  fileId?: InputMaybe<Scalars['Int']['input']>;
  reviewNotRequired?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userUploaded?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateAuthorizationErrorArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAuthorizationFromBvArgs = {
  benefitsVerificationId: Scalars['String']['input'];
  patch?: InputMaybe<CreateAuthorizationFromBvPatch>;
};


export type MutationCreateAuthorizationFromEnrollmentArgs = {
  enrollmentId: Scalars['String']['input'];
  patch?: InputMaybe<CreatePaFromEnrollmentPatch>;
};


export type MutationCreateAuthorizationNoteArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAuthorizationSpecificFormArgs = {
  authorizationId: Scalars['Int']['input'];
  fileKey: Scalars['String']['input'];
};


export type MutationCreateBenefitsVerificationArgs = {
  drugOptionId: Scalars['Int']['input'];
  institutionId?: InputMaybe<Scalars['Int']['input']>;
  patientData?: InputMaybe<CreateBenefitsVerificationPatientInput>;
  patientId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateBvFromAuthArgs = {
  authId: Scalars['Int']['input'];
};


export type MutationCreateEdiAuthorizationArgs = {
  InstitutionId: Scalars['Int']['input'];
  PatientId: Scalars['Int']['input'];
  icdCodes: Array<Scalars['String']['input']>;
  insurance: Insurance;
  placeOfServiceCode: PlaceOfServiceCode;
  renderingProvider: RenderingProvider;
  requester: Requester;
  requestingProvider: RequestingProvider;
  services: Array<Service>;
};


export type MutationCreateEnrollmentFromAuthorizationArgs = {
  authorizationId: Scalars['Int']['input'];
};


export type MutationCreateFollowUpArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateFormNumberArgs = {
  createWithoutInstitution?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  insuranceCompanyId?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateIngestJobArgs = {
  columnMappings?: InputMaybe<Array<ColumnMap>>;
  fileName: Scalars['String']['input'];
};


export type MutationCreateLocationByNpiFromRegistryArgs = {
  npi: Scalars['String']['input'];
};


export type MutationCreateNewEnrollmentArgs = {
  EnrollmentProgramId: Scalars['Int']['input'];
  patientId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreatePatientArgs = {
  object: CreatePatientArgs;
};


export type MutationCreatePatientOnAuthorizationArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  patientProps?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationCreatePortalArgs = {
  object: CreatePortalArgs;
};


export type MutationCreatePrescriberByNpiFromRegistryArgs = {
  npi: Scalars['String']['input'];
};


export type MutationCreateReviewNoteArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateSignatureArgs = {
  addToInstitution?: InputMaybe<Scalars['Boolean']['input']>;
  dataUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateSubscriberArgs = {
  object: CreateSubscriberArgs;
};


export type MutationCreateTrackerConfigArgs = {
  object: CreateTrackerConfigArgs;
};


export type MutationDeleteAuthorizationFormArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteCorrespondenceArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteDraftEnrollmentArgs = {
  enrollmentId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteDrugOptionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteFavoriteArgs = {
  authorizationFormId?: InputMaybe<Scalars['Int']['input']>;
  formNumberId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteFormNumberArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteFormOrFaxFilterArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isFormFilter?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeleteInstitutionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteLocationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeletePatientArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePortalArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePrescriberSignatureArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteTrackerConfigArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDisassociateCorrespondenceArgs = {
  authorizationId: Scalars['Int']['input'];
  correspondenceId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDuplicateAuthorizationArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  insuranceName?: InputMaybe<Scalars['String']['input']>;
  isExternal?: InputMaybe<Scalars['Boolean']['input']>;
  isWebExtension?: InputMaybe<Scalars['Boolean']['input']>;
  portalKey?: InputMaybe<Scalars['String']['input']>;
  setNewForm?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationFollowUpReviewedArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationFollowUpSubmitArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationForwardCorrespondenceToPayerArgs = {
  authorizationId: Scalars['Int']['input'];
  correspondenceId: Scalars['Int']['input'];
  faxNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGeneratePasswordTokenArgs = {
  id: Scalars['Int']['input'];
};


export type MutationGeneratePdfDownloadArgs = {
  modelId: Scalars['String']['input'];
  modelType: ModelType;
  template: TemplateType;
};


export type MutationGetOrCreateSiteIdArgs = {
  url: Scalars['String']['input'];
};


export type MutationHideAuthorizationCorrespondenceArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationMockFaxArgs = {
  authorizationId: Scalars['Int']['input'];
  markdown: Scalars['String']['input'];
  mockBarcode: Scalars['Boolean']['input'];
};


export type MutationPracticeDataExportArgs = {
  isXLSX?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationReassingAuthorizationArgs = {
  accountId: Scalars['Int']['input'];
  authorizationId: Scalars['Int']['input'];
};


export type MutationRefreshPrimaryInsuranceCoverageCheckArgs = {
  PatientId: Scalars['Int']['input'];
};


export type MutationRegenerateLomnAttachmentArgs = {
  authorizationId: Scalars['Int']['input'];
  signatureId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRemovePatientOnAuthArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationReportErrorArgs = {
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReportMissingInformationArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  fileKey?: InputMaybe<Scalars['String']['input']>;
  insuranceCompanyId?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestNewPortalIntegrationArgs = {
  input: RequestNewPortalIntegrationInput;
};


export type MutationResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveClientLogArgs = {
  message: Scalars['JSON']['input'];
};


export type MutationSaveClientMetricArgs = {
  authorizationId: Scalars['Int']['input'];
  hubKey: Scalars['String']['input'];
  metric: Scalars['String']['input'];
  pageKey?: InputMaybe<Scalars['String']['input']>;
  selector?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveSessionRecordingReferenceArgs = {
  AuthorizationId: Scalars['String']['input'];
  sessionURL: Scalars['String']['input'];
};


export type MutationSendAuthorizationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  resendReasonAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  resendReasonKey?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSendResponsesToHcpArgs = {
  authorizationId: Scalars['Int']['input'];
  correspondenceId: Scalars['Int']['input'];
  faxNumber: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendSecureFileArgs = {
  awsKey?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  sendTo?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetAuthorizationAttachmentsArgs = {
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSetAuthorizationReminderArgs = {
  emailReminderAt?: InputMaybe<Scalars['String']['input']>;
  emailReminderDescription?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSetConfigDetailsOnAuthorizationArgs = {
  authorizationConfig?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSetCorrespondenceOnAuthorizationArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  correspondenceId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSetFavoriteArgs = {
  authorizationFormId?: InputMaybe<Scalars['Int']['input']>;
  formNumberId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSetManuallyConfiguredBoxesOnFormArgs = {
  input: SetManuallyConfiguredBoxesOnFormInput;
};


export type MutationSetOnAuthorizationJsonFieldArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationSetPatientOnAuthArgs = {
  authorizationId: Scalars['Int']['input'];
  insuranceCompanyId?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['Int']['input'];
};


export type MutationSetPrescriberOnAuthArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  prescriberId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSubmitBenefitsVerificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationSubmitCareMetxBenefitsVerificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationSubmitEnrollmentArgs = {
  enrollmentId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationToggleNoteHiddenArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationTrackPluginPortalPageHtmlArgs = {
  AuthorizationId: Scalars['Int']['input'];
  framesHTML: Array<FramesHtml>;
  mainPageHTML: Scalars['String']['input'];
  submissionType: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationUnlockAccountArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUnsetCorrespondenceFromAuthorizationArgs = {
  authorizationId: Scalars['Int']['input'];
  correspondenceId: Scalars['Int']['input'];
};


export type MutationUpdateAccountArgs = {
  patch?: InputMaybe<UpdateAccountPatch>;
};


export type MutationUpdateAccountByEmailArgs = {
  email: Scalars['String']['input'];
  lastLoggedIn?: InputMaybe<Scalars['DateTime']['input']>;
  passwordDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationUpdateAccountInstitutionArgs = {
  InstitutionId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateAuthorizationByIdArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['JSON']['input']>;
  segmentDetails?: InputMaybe<SegmentDetailsInputUpdatePa>;
};


export type MutationUpdateAuthorizationCorrespondenceArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateAuthorizationFormByIdArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<AuthorizationFormUpdatePatch>;
};


export type MutationUpdateAuthorizationFormTagsArgs = {
  authorizationFormId?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdateAuthorizationStatusArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  identifierCaptureType?: InputMaybe<Scalars['String']['input']>;
  nonApprovalInfo?: InputMaybe<Scalars['String']['input']>;
  nonApprovalType?: InputMaybe<Scalars['String']['input']>;
  portalAuthorizationId?: InputMaybe<Scalars['String']['input']>;
  segmentDetails?: InputMaybe<SegmentDetailsInputUpdatePa>;
  status?: InputMaybe<AuthorizationStatusEnum>;
  terminalStatusUpdateType?: InputMaybe<TerminalStatusUpdateType>;
};


export type MutationUpdateAuthorizationTagsArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdateBenefitsVerificationArgs = {
  id: Scalars['String']['input'];
  patch?: InputMaybe<UpdateBenefitsVerificationPatch>;
};


export type MutationUpdateBenefitsVerificationOutcomeArgs = {
  patch?: InputMaybe<UpdateBenefitsVerificationOutcomePatch>;
};


export type MutationUpdateEnrollmentByIdArgs = {
  id: Scalars['Int']['input'];
  patch: EnrollmentUpdatePatchArgs;
};


export type MutationUpdateFormNumberArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateInstitutionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<InstitutionUpdatePatch>;
};


export type MutationUpdateInstitutionForSamaUsersArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateInstitutionUploadCategoryArgs = {
  emrUploadCategory?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateInsuranceCompanyByIdArgs = {
  id: Scalars['Int']['input'];
  patch?: InputMaybe<InsuranceCompanyUpdatePatch>;
};


export type MutationUpdateLatestAuthorizationCorrespondenceArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  authorizedProcedures?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateNewEnrollmentByIdArgs = {
  id: Scalars['Int']['input'];
  patch: NewEnrollmentInputObjectType;
};


export type MutationUpdatePasswordArgs = {
  nonce?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePatientByIdArgs = {
  id: Scalars['Int']['input'];
  patch: UpdatePatientArgs;
};


export type MutationUpdatePortalArgs = {
  id: Scalars['Int']['input'];
  patch: UpdatePortalArgs;
};


export type MutationUpdateStatusCheckLoginRequiresAttnArgs = {
  PortalId: Scalars['Int']['input'];
};


export type MutationUpdateSubscriberArgs = {
  id: Scalars['Int']['input'];
  patch: UpdateSubscriberArgs;
};


export type MutationUpdateTrackerConfigArgs = {
  id: Scalars['Int']['input'];
  patch: UpdateTrackerConfigArgs;
};


export type MutationUploadDocumentsToModernizingMedicineArgs = {
  authorizationId: Scalars['Int']['input'];
  files: Array<InputMaybe<Scalars['JSON']['input']>>;
  institutionPatientId: Scalars['String']['input'];
};


export type MutationUploadDocumentsToNextGenArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  files: Array<InputMaybe<Scalars['JSON']['input']>>;
  institutionPatientId: Scalars['String']['input'];
  isTestUpload?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUploadDocumentsToOncoEmrArgs = {
  authorizationId: Scalars['Int']['input'];
  files: Array<InputMaybe<Scalars['JSON']['input']>>;
  institutionPatientId: Scalars['String']['input'];
};


export type MutationUploadDocumentsToRedoxArgs = {
  authorizationId: Scalars['Int']['input'];
  files: Array<Scalars['JSON']['input']>;
};


export type MutationUploadRedoxDocumentToS3Args = {
  authorizationId: Scalars['Int']['input'];
  documentId: Scalars['String']['input'];
};


export type MutationUpsertAccountForSamaUsersArgs = {
  patch?: InputMaybe<UpsertAccountForSamaUsersPatch>;
};


export type MutationUpsertBenefitsCheckPatientArgs = {
  InstitutionId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  patientData?: InputMaybe<UpdateBenefitsVerificationPatchPatientData>;
  primaryInsuranceData?: InputMaybe<UpdateBenefitsVerificationPatchInsuranceData>;
  secondaryInsuranceData?: InputMaybe<UpdateBenefitsVerificationPatchInsuranceData>;
};


export type MutationUpsertDrugOptionArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  drugName?: InputMaybe<Scalars['String']['input']>;
  expensive?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sponsored?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpsertFaxFilterArgs = {
  filterId?: InputMaybe<Scalars['Int']['input']>;
  formNumberId?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpsertFormArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  formId?: InputMaybe<Scalars['Int']['input']>;
  imgKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  pdfKey?: InputMaybe<Scalars['String']['input']>;
  specification?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertFormFilterArgs = {
  AuthorizationFormId?: InputMaybe<Scalars['Int']['input']>;
  NDCs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  benefitType?: InputMaybe<Scalars['String']['input']>;
  filterId?: InputMaybe<Scalars['Int']['input']>;
  formId?: InputMaybe<Scalars['Int']['input']>;
  insuranceTypes?: InputMaybe<Array<InputMaybe<FormFilterInsuranceType>>>;
  jCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpsertInsuranceCompanyArgs = {
  availityServiceReviewSearchMappingsKey?: InputMaybe<AvailityServiceReviewSearchMappingsKey>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  parentCompanyName?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  searchTags?: InputMaybe<Array<Scalars['String']['input']>>;
  supportedCodingTypes: InsuranceCompanySupportedCodingTypes;
  supportedPlanTypes: Array<Scalars['String']['input']>;
  supportedStates: Array<Scalars['String']['input']>;
};


export type MutationUpsertLocationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpsertModernizingMedicineIntegrationInstallationArgs = {
  firmUrlPrefix: Scalars['String']['input'];
  institutionId: Scalars['Int']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpsertNextGenIntegrationInstallationArgs = {
  documentType: Scalars['String']['input'];
  institutionId: Scalars['Int']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  practiceId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};


export type MutationUpsertPatientIntegrationCredentialArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  institutionId: Scalars['Int']['input'];
  patch: UpsertPatientIntegrationCredentialMutationPatch;
};


export type MutationUpsertPortalCredentialsArgs = {
  credentials: PortalCredential;
  isDelete?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpsertPrescriberArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpsertRedoxIntegrationInstallationArgs = {
  facilityCode: Scalars['String']['input'];
  institutionId: Scalars['Int']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationValidateCredentialsOncoEmrArgs = {
  emrCreds?: InputMaybe<Scalars['JSON']['input']>;
  institutionId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationWriteAuthorizationResultsToPatientArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationWriteEnrollmentInfoToEnrollmentFormArgs = {
  enrollmentId: Scalars['Int']['input'];
};


export type MutationWriteIntegratedDetailsToAuthorizationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Ndc = {
  __typename?: 'NDC';
  /** The brand name of the given NDC code. */
  brandName: Scalars['String']['output'];
  /** An NDC code. */
  code: Scalars['String']['output'];
  /** The generic name of the given NDC code. */
  genericName: Scalars['String']['output'];
};

export type NewEnrollmentInputObjectType = {
  /** The AccountId property of the NewEnrollment model */
  AccountId?: InputMaybe<Scalars['Int']['input']>;
  /** The EnrollmentProgramId property of the NewEnrollment model */
  EnrollmentProgramId?: InputMaybe<Scalars['Int']['input']>;
  /** The InstitutionId property of the NewEnrollment model */
  InstitutionId?: InputMaybe<Scalars['Int']['input']>;
  /** The InsuranceCompanyId property of the NewEnrollment model */
  InsuranceCompanyId?: InputMaybe<Scalars['Int']['input']>;
  /** The LocationId property of the NewEnrollment model */
  LocationId?: InputMaybe<Scalars['Int']['input']>;
  MedicationRequest?: InputMaybe<MedicationRequestInput>;
  /** The MedicationRequestId property of the NewEnrollment model */
  MedicationRequestId?: InputMaybe<Scalars['Int']['input']>;
  PatientConsent?: InputMaybe<PatientConsentInput>;
  /** The PatientConsentId property of the NewEnrollment model */
  PatientConsentId?: InputMaybe<Scalars['Int']['input']>;
  /** The PatientId property of the NewEnrollment model */
  PatientId?: InputMaybe<Scalars['Int']['input']>;
  /** The PrescriberId property of the NewEnrollment model */
  PrescriberId?: InputMaybe<Scalars['Int']['input']>;
  /** The QuestionnaireResponseId property of the NewEnrollment model */
  QuestionnaireResponseId?: InputMaybe<Scalars['Int']['input']>;
  SignatureFileId?: InputMaybe<Scalars['ID']['input']>;
  /** The SubmittedFileId property of the NewEnrollment model */
  SubmittedFileId?: InputMaybe<Scalars['Int']['input']>;
  /** The createdAt property of the NewEnrollment model */
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The deletedAt property of the NewEnrollment model */
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The externalId property of the NewEnrollment model */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** The isArchived property of the NewEnrollment model */
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  questionnaire?: InputMaybe<QuestionnaireResponseInputObjectType>;
  /** The state property of the NewEnrollment model */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The status property of the NewEnrollment model */
  status?: InputMaybe<NewEnrollmentStatusEnumType>;
  /** The submittedAt property of the NewEnrollment model */
  submittedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The updatedAt property of the NewEnrollment model */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NewEnrollmentObjectType = {
  __typename?: 'NewEnrollmentObjectType';
  /** The AccountId property of the NewEnrollment model */
  AccountId?: Maybe<Scalars['Int']['output']>;
  EnrollmentProgram?: Maybe<EnrollmentProgram>;
  /** The EnrollmentProgramId property of the NewEnrollment model */
  EnrollmentProgramId?: Maybe<Scalars['Int']['output']>;
  EnrollmentServiceResponses?: Maybe<Array<Maybe<EnrollmentServiceResponseObjectType>>>;
  /** The InstitutionId property of the NewEnrollment model */
  InstitutionId: Scalars['Int']['output'];
  InsuranceCompany?: Maybe<InsuranceCompany>;
  /** The InsuranceCompanyId property of the NewEnrollment model */
  InsuranceCompanyId?: Maybe<Scalars['Int']['output']>;
  /** The LocationId property of the NewEnrollment model */
  LocationId?: Maybe<Scalars['Int']['output']>;
  MedicationRequest?: Maybe<MedicationRequest>;
  /** The MedicationRequestId property of the NewEnrollment model */
  MedicationRequestId?: Maybe<Scalars['Int']['output']>;
  PatientConsent?: Maybe<PatientConsentObjectType>;
  /** The PatientConsentId property of the NewEnrollment model */
  PatientConsentId?: Maybe<Scalars['Int']['output']>;
  /** The PatientId property of the NewEnrollment model */
  PatientId?: Maybe<Scalars['Int']['output']>;
  /** The PrescriberId property of the NewEnrollment model */
  PrescriberId?: Maybe<Scalars['Int']['output']>;
  /** The QuestionnaireResponseId property of the NewEnrollment model */
  QuestionnaireResponseId?: Maybe<Scalars['Int']['output']>;
  SignatureFileId?: Maybe<Scalars['ID']['output']>;
  /** The SubmittedFileId property of the NewEnrollment model */
  SubmittedFileId?: Maybe<Scalars['Int']['output']>;
  SubmittedFileURL?: Maybe<Scalars['String']['output']>;
  /** The createdAt property of the NewEnrollment model */
  createdAt: Scalars['DateTime']['output'];
  /** The deletedAt property of the NewEnrollment model */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The externalId property of the NewEnrollment model */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The id property of the NewEnrollment model */
  id: Scalars['ID']['output'];
  /** The isArchived property of the NewEnrollment model */
  isArchived: Scalars['Boolean']['output'];
  questionnaire?: Maybe<QuestionnaireResponseObjectType>;
  /** The state property of the NewEnrollment model */
  state: Scalars['String']['output'];
  /** The status property of the NewEnrollment model */
  status: NewEnrollmentStatusEnumType;
  /** The submittedAt property of the NewEnrollment model */
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The updatedAt property of the NewEnrollment model */
  updatedAt: Scalars['DateTime']['output'];
};

export enum NewEnrollmentStatusEnumType {
  Completed = 'Completed',
  Draft = 'Draft',
  Submitted = 'Submitted'
}

export type NextGenPatient = {
  __typename?: 'NextGenPatient';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Note = {
  __typename?: 'Note';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** The account that generated the event triggering this note */
  createdBy: Account;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** Is this note hidden? */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Free form text note */
  note?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** A boolean indicating if the connection has more edges available. */
  hasNextPage: Scalars['Boolean']['output'];
  /** A boolean indicating if the connection has more edges available. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** The total number of records (across all pages) that can be retrieved. */
  totalCount: Scalars['Int']['output'];
};

export type Patient = {
  __typename?: 'Patient';
  /** Insurance company id */
  InsuranceCompanyId?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  authorizations?: Maybe<AuthorizationConnection>;
  authorizationsCount?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  dob: Scalars['String']['output'];
  ehrId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  heightInches?: Maybe<Scalars['Int']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** Institution specific patient id */
  institutionPatientId?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  mrn?: Maybe<Scalars['String']['output']>;
  patientSource?: Maybe<PatientSource>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<Scalars['String']['output']>;
  primaryInsurance?: Maybe<InsurancePolicyObjectType>;
  redoxPatientIdentifiers: Array<RedoxPatientIdentifier>;
  secondaryInsurance?: Maybe<InsurancePolicyObjectType>;
  state?: Maybe<Scalars['String']['output']>;
  /** Where a patient has an active (non-complete) authorization associated */
  status?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  weightPounds?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type PatientAuthorizationsArgs = {
  filters?: InputMaybe<AuthorizationsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AuthorizationConnectionSortByAttribute>;
};


export type PatientAuthorizationsCountArgs = {
  filters?: InputMaybe<AuthorizationsFilters>;
};

export type PatientBenefitsVerificationInfo = {
  __typename?: 'PatientBenefitsVerificationInfo';
  /** Total number of benefit verifications associated with a patient */
  count?: Maybe<Scalars['Int']['output']>;
  /** Total number of error benefit verifications associated with a patient */
  errorCount?: Maybe<Scalars['Int']['output']>;
  /** Timestamp of the most recent benefit verification for a given patient */
  lastCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Total number of pending benefit verifications associated with a patient */
  pendingCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of success benefit verifications associated with a patient */
  successCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientConsentFileObjectType = {
  __typename?: 'PatientConsentFileObjectType';
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PatientConsentInput = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  hasConsent: Scalars['Boolean']['input'];
};

export type PatientConsentObjectType = {
  __typename?: 'PatientConsentObjectType';
  File?: Maybe<PatientConsentFileObjectType>;
  hasConsent: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type PatientDemographicIntegrationCredential = {
  __typename?: 'PatientDemographicIntegrationCredential';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The institution associated with this Portal Integration Credential */
  institution?: Maybe<Institution>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Return the latest three logs for a given credential */
  latestErrorLogs?: Maybe<Array<Maybe<PatientDemographicIntegrationLog>>>;
  loginURL?: Maybe<Scalars['String']['output']>;
  officeKey?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type PatientDemographicIntegrationLog = {
  __typename?: 'PatientDemographicIntegrationLog';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum PatientPhoneType {
  Home = 'home',
  Mobile = 'mobile',
  Work = 'work'
}

/** A quick filter option for the Patients grid */
export enum PatientPresetFilter {
  ExpiringAuths = 'ExpiringAuths'
}

export type PatientSource = {
  __typename?: 'PatientSource';
  RedoxSourceId?: Maybe<Scalars['Int']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sourceType: PatientSourceType;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum PatientSourceType {
  Redox = 'redox'
}

export enum PatientStatusType {
  ContinuingTherapy = 'CONTINUING_THERAPY',
  InitiatingTherapy = 'INITIATING_THERAPY'
}

export type PatientWithTreatments = {
  __typename?: 'PatientWithTreatments';
  drugNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hasBvInDraft: Scalars['Boolean']['output'];
  institution?: Maybe<Institution>;
  patient?: Maybe<Patient>;
  showBvSupportNote: Scalars['Boolean']['output'];
  treatments?: Maybe<Array<Maybe<ServicesGroupedByDrug>>>;
};

export type PatientsFindAllOutput = {
  __typename?: 'PatientsFindAllOutput';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Patient>>>;
};

export type PatientsFindAllWhere = {
  InstitutionId?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  institutionPatientId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientSourceId?: InputMaybe<Scalars['Int']['input']>;
};

export enum PipelineStage {
  ChunkData = 'CHUNK_DATA',
  DeduplicateRows = 'DEDUPLICATE_ROWS',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  LoadData = 'LOAD_DATA',
  TransformAndValidateRows = 'TRANSFORM_AND_VALIDATE_ROWS',
  TransformFile = 'TRANSFORM_FILE',
  ValidateFile = 'VALIDATE_FILE',
  WaitingForPipeline = 'WAITING_FOR_PIPELINE',
  WaitingForUpload = 'WAITING_FOR_UPLOAD'
}

export enum PlaceOfServiceCode {
  AmbulanceAirOrWater = 'AmbulanceAirOrWater',
  AmbulanceLand = 'AmbulanceLand',
  AmbulatorySurgicalCenter = 'AmbulatorySurgicalCenter',
  AssistedLivingFacility = 'AssistedLivingFacility',
  EndStageRenalDiseaseTreatmentFacility = 'EndStageRenalDiseaseTreatmentFacility',
  Home = 'Home',
  NonResidentSubstanceAbuseTreatmentFacility = 'NonResidentSubstanceAbuseTreatmentFacility',
  NonResidentialOpioidTreatmentFacility = 'NonResidentialOpioidTreatmentFacility',
  OffCampusOutPatientHospital = 'OffCampusOutPatientHospital',
  Office = 'Office',
  OnCampusOutPatientHospital = 'OnCampusOutPatientHospital',
  PsychiatricFacilityPartialHospitalization = 'PsychiatricFacilityPartialHospitalization',
  School = 'School'
}

export type Portal = {
  __typename?: 'Portal';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  enableFollowUps: Scalars['Boolean']['output'];
  formFieldConfigKey: FormFieldConfigKey;
  hideCredentialsFromSupport: Scalars['Boolean']['output'];
  hubKey?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  isLegacy: Scalars['Boolean']['output'];
  isTop: Scalars['Boolean']['output'];
  loginUrl: Scalars['String']['output'];
  requiresPhoneMfa: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type PortalCredential = {
  InstitutionId?: InputMaybe<Scalars['Int']['input']>;
  PortalId?: InputMaybe<Scalars['Int']['input']>;
  hub?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type PortalIntegrationDetails = {
  __typename?: 'PortalIntegrationDetails';
  account?: Maybe<Account>;
  authorization?: Maybe<Authorization>;
  minutesRunning?: Maybe<Scalars['Float']['output']>;
  portalKey?: Maybe<Scalars['String']['output']>;
  /** uuid representing the runner */
  runnerId?: Maybe<Scalars['String']['output']>;
};

export type PortalMetric = {
  __typename?: 'PortalMetric';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdCount?: Maybe<Scalars['Int']['output']>;
  /** Institutions with the given portal */
  institutions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The portal key of the portal */
  key?: Maybe<Scalars['String']['output']>;
  submittedCount?: Maybe<Scalars['Int']['output']>;
};

export type PortalToHubMapping = {
  __typename?: 'PortalToHubMapping';
  deepLink?: Maybe<Scalars['String']['output']>;
  hubKey: Scalars['String']['output'];
  loginUrl?: Maybe<Scalars['String']['output']>;
  portalKey: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PortalWhere = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PossibleFormResponse = {
  __typename?: 'PossibleFormResponse';
  favoriteForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  hasForms?: Maybe<Scalars['Boolean']['output']>;
  otherPayerForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  otherStateForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  payerForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  stateForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  suggestedForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
  suggestedParentForms?: Maybe<Array<Maybe<AuthorizationForm>>>;
};

export type PossibleNumberResponse = {
  __typename?: 'PossibleNumberResponse';
  favoriteNumbers?: Maybe<Array<Maybe<FormNumber>>>;
  hasNumbers?: Maybe<Scalars['Boolean']['output']>;
  remainingNumbers?: Maybe<Array<Maybe<FormNumber>>>;
  suggestedNumbers?: Maybe<Array<Maybe<FormNumber>>>;
};

export type Prescriber = {
  __typename?: 'Prescriber';
  DEA?: Maybe<Scalars['String']['output']>;
  InstitutionId?: Maybe<Scalars['Int']['output']>;
  NPI?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** The institution associated with this account */
  institution?: Maybe<Institution>;
  /** Whether this account has been deactivated */
  isDeactivated?: Maybe<Scalars['Boolean']['output']>;
  /** Descriptive label for use when prescribers have multiple entries */
  label?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  licenseNumber?: Maybe<Scalars['String']['output']>;
  specialtyCode?: Maybe<Scalars['String']['output']>;
  specialtyDescription?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type PresignedPost = {
  __typename?: 'PresignedPost';
  fields: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Searches for NDC codes */
  NDCs: Array<Ndc>;
  /** Find all accounts, filtered by user's institutionId */
  accountsFindAll?: Maybe<Array<Account>>;
  /** Find an authorizationById */
  authorizationById?: Maybe<Authorization>;
  /** Returns authorizationForm by id */
  authorizationForms: AuthorizationFormConnection;
  /** Find authorizations */
  authorizations?: Maybe<AuthorizationsObjectType>;
  /** Find all authorizations, filtered by user's institutionId */
  authorizationsPaginated: AuthorizationConnection;
  /** Retrieves a single Benefits Verification */
  benefitsVerificationById?: Maybe<BenefitsVerification>;
  /** Find an authorizationById */
  correspondenceById?: Maybe<AuthorizationCorrespondence>;
  /** The logged in account */
  currentAccount?: Maybe<Account>;
  /** Search enabled features for this drug and institution */
  drugOptionFeatures: DrugOptionFeatures;
  /** Gets all forms */
  faxFiltersFindAll?: Maybe<Array<Maybe<FaxFilter>>>;
  /** Find an authorizationById */
  fileByAWSKey?: Maybe<Scalars['String']['output']>;
  /** Find duplicate authorizations across the patient given an authorization id */
  findDuplicateAuthorizationsForPatient?: Maybe<Array<Maybe<Authorization>>>;
  /** Returns authorizationForm by id */
  formById?: Maybe<AuthorizationForm>;
  /** Get all possible fax numbers */
  formNumbersFindAll?: Maybe<Array<Maybe<FormNumber>>>;
  /** Find an authorizationById */
  generateMockFaxMarkdownForAuthorization?: Maybe<Scalars['String']['output']>;
  /** Get account by email */
  getAccount?: Maybe<Account>;
  /** Get drug options */
  getAllServiceCodes: GetAllServiceCodesResult;
  /** Gets all the tags for the user's institution */
  getAllTags: Array<Scalars['String']['output']>;
  /** Find authorization logs by authorizationId */
  getAuthorizationLog?: Maybe<Array<AuthorizationLog>>;
  /** Find notes by authorization id */
  getAuthorizationNotes?: Maybe<Array<Maybe<Note>>>;
  /** Find associated Authorizations associated with a Form */
  getAuthorizationsByForm?: Maybe<Array<Maybe<Authorization>>>;
  /** Find associated Authorizations associated with a FormNumber */
  getAuthorizationsByFormNumber?: Maybe<Array<Maybe<Authorization>>>;
  /** Find authorizations given authorization ids */
  getAuthorizationsByIds?: Maybe<Array<Maybe<Authorization>>>;
  /** Get the AWS keys for the files that contain the data above skipped rows */
  getAwsKeysForSkippedRows: Array<Scalars['String']['output']>;
  /** Retrieves Benefits Verifications by patient and drug */
  getBenefitsVerificationsByPatientAndDrug?: Maybe<Array<Maybe<BenefitsVerificationBasic>>>;
  /** Get a careMetx enrollment response by external Id */
  getCareMetxEnrollmentResponseByBenefitsVerificationId?: Maybe<CareMetxEnrollmentResponsesType>;
  /** Get drug options */
  getDrugOptions?: Maybe<Array<DrugOption>>;
  /** Get an enrollment by ID */
  getEnrollmentById?: Maybe<NewEnrollmentObjectType>;
  /** enroll patient in CareMetx */
  getEnrollmentDetails: GetEnrollmentDetailsResponse;
  /** Get an enrollment program by ID */
  getEnrollmentProgramById?: Maybe<EnrollmentProgram>;
  /** Get enrollment programs */
  getEnrollmentPrograms: Array<Maybe<EnrollmentProgram>>;
  /** Get enrollments */
  getEnrollments?: Maybe<Array<Maybe<NewEnrollmentObjectType>>>;
  /** Get a fax number with a forbidden reason if it exists */
  getForbiddenFormNumber?: Maybe<FormNumber>;
  /** Gets all the tags for institution=null */
  getGlobalTags: Array<Scalars['String']['output']>;
  /** Gets identity provider for provided email */
  getIdentityProvider?: Maybe<IdentityProvider>;
  /** Get an ingest job by ID */
  getIngestJobById?: Maybe<IngestJob>;
  /** Get an ingest job by ID */
  getIngestTransform?: Maybe<IngestTransform>;
  /** get Institution Name from Next Gen API */
  getInstitutionNameFromNextGen: GetInstitutionNameFromNextGen;
  /** get potential Institution Names from ModernizingMedicine API */
  getInstitutionNamesFromModernizingMedicine: GetInstitutionNamesFromModernizingMedicine;
  /** Fetch institutions by institution ids */
  getInstitutionsByIds?: Maybe<Array<Maybe<Institution>>>;
  /** Find correspondences that are unassociated */
  getInsuranceResponses?: Maybe<Array<Maybe<AuthorizationCorrespondence>>>;
  /** Get an integration installation by institution ID */
  getIntegrationInstallationByInstitutionId?: Maybe<IntegrationInstallation>;
  /** Get a latest ingest job for the current institution */
  getLatestIngestJob?: Maybe<IngestJob>;
  /** Gets a mapping of the given portal keys to hub keys */
  getPortalHubs: Array<PortalToHubMapping>;
  /** Gets an array of portal preview images */
  getPortalPreview?: Maybe<Array<Scalars['String']['output']>>;
  /** Returns an array of insurance forms that strictly and loosely match the filtered criteria. Most relevant sorted first */
  getPossibleForms?: Maybe<PossibleFormResponse>;
  /** Get the possible phone numbers associated with a form and insurance company */
  getPossibleNumbers?: Maybe<PossibleNumberResponse>;
  /** Get SamaStory session by id */
  getStorySessionById?: Maybe<SamaStorySession>;
  /** Get SamaStory sessions by authorizationId */
  getStorySessionsByAuthorizationId?: Maybe<Array<Maybe<SamaStorySession>>>;
  /** Get SamaStory sessions by institution or account */
  getStorySessionsByInstitutionOrAccount?: Maybe<Array<Maybe<SamaStorySession>>>;
  /** get Test Patients from Next Gen API */
  getTestPatientsFromNextGen: GetTestPatientsFromNextGenResult;
  /** Find all treatments by patient */
  getTreatmentsByPatient?: Maybe<GetTreatmentsByPatientOutput>;
  /** Gets unresolved follow up portals and institutions */
  getUnresolvedFollowUpPortalsAndInstitutions: GetUnresolvedFollowUpPortalsAndInstitutionsResult;
  /** Gets unresolved follow ups */
  getUnresolvedFollowUps: GetUnresolvedFollowUpsResult;
  /** Get s3 presigned url for uploading rrweb record */
  getUserSessionPreSignedUrl?: Maybe<UserSessionRecordingUrl>;
  /** Find all insurance plans */
  icd10?: Maybe<Scalars['JSON']['output']>;
  /** Find all available institutions */
  institutionsFindAll: Array<Institution>;
  /** Find all insurance companies */
  insuranceCompaniesFindAll: Array<InsuranceCompany>;
  /** Search for insurance companies */
  insuranceCompanyFeatures: InsuranceCompanyFeatures;
  /** Validates whether a zip code is valid or not */
  isValidZipCode: Scalars['Boolean']['output'];
  /** List authorization correspondences */
  listCorrespondences?: Maybe<Array<Maybe<AuthorizationCorrespondence>>>;
  /** Search for Redox document references for the given patient */
  listRedoxPatientDocumentReferences: Array<RedoxDocumentReference>;
  /** Find patient by id */
  patientById?: Maybe<Patient>;
  /** Find all patient demographic integration credentials */
  patientIntegrationCredentialsFindAll?: Maybe<Array<Maybe<PatientDemographicIntegrationCredential>>>;
  /** Find all syncer titles */
  patientIntegrationTitlesFindAll?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Searches for patients by first and last name, DOB and institutionPatientId. */
  patientsByName?: Maybe<Array<Maybe<Patient>>>;
  /** Find all patients, filtered by user's institutionId */
  patientsFindAll?: Maybe<PatientsFindAllOutput>;
  /** Search for portals */
  portals: Array<Portal>;
  /** Find all prescribers, filtered by institutionId */
  prescribersFindAll: Array<Prescriber>;
  /** Search for redox insurance company identifiers */
  redoxInsuranceCompanyIdentifiers: Array<RedoxInsuranceCompanyIdentifier>;
  /** Search for redox sources */
  redoxSources: Array<RedoxSource>;
  /** Get all possible fax numbers */
  searchFormNumbers?: Maybe<Array<Maybe<FormNumber>>>;
  /** Find all HCPCS codes matching a search string */
  searchHCPCS?: Maybe<Array<HcpcsCode>>;
  /** Find signature by id */
  signatureById?: Maybe<File>;
  /** Find all signatures, filtered by user's id */
  signaturesFindAll?: Maybe<Array<Maybe<File>>>;
  /** Search for subscribers */
  subscribers: Array<Subscriber>;
  /** Find all authorizations, filtered by user's institutionId */
  supportToolAuthorizationsSearch?: Maybe<Array<Maybe<Authorization>>>;
  /** Search for tracker configs */
  trackerConfigs: Array<TrackerConfig>;
  /** Find all unified benefits checks for an institution */
  unifiedBenefitsChecks?: Maybe<Array<UnifiedBenefitsCheck>>;
  /** Find all patient demographic integration credentials */
  userCredentialByFollowUp?: Maybe<UserCredential>;
};


export type QueryNdCsArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QueryAccountsFindAllArgs = {
  institutionId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAuthorizationByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAuthorizationFormsArgs = {
  filters?: InputMaybe<AuthorizationFormsFilters>;
};


export type QueryAuthorizationsArgs = {
  filter?: InputMaybe<AuthorizationsFilterInputObjectType>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: Array<AuthorizationsSortInputObjectType>;
};


export type QueryAuthorizationsPaginatedArgs = {
  filters?: InputMaybe<AuthorizationsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AuthorizationConnectionSortByAttribute>;
};


export type QueryBenefitsVerificationByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryCorrespondenceByIdArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDrugOptionFeaturesArgs = {
  DrugOptionId: Scalars['Int']['input'];
  InstitutionId: Scalars['Int']['input'];
};


export type QueryFileByAwsKeyArgs = {
  awsKey?: InputMaybe<Scalars['String']['input']>;
  bucket?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindDuplicateAuthorizationsForPatientArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFormByIdArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFormNumbersFindAllArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGenerateMockFaxMarkdownForAuthorizationArgs = {
  authorizationId: Scalars['Int']['input'];
};


export type QueryGetAccountArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAuthorizationLogArgs = {
  authorizationId: Scalars['Int']['input'];
};


export type QueryGetAuthorizationNotesArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAuthorizationsByFormArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAuthorizationsByFormNumberArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAuthorizationsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type QueryGetAwsKeysForSkippedRowsArgs = {
  ingestJobId: Scalars['Int']['input'];
};


export type QueryGetBenefitsVerificationsByPatientAndDrugArgs = {
  DrugOptionId?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['Int']['input'];
};


export type QueryGetCareMetxEnrollmentResponseByBenefitsVerificationIdArgs = {
  BenefitsVerificationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetEnrollmentByIdArgs = {
  enrollmentId: Scalars['Int']['input'];
};


export type QueryGetEnrollmentDetailsArgs = {
  authorizationId: Scalars['Int']['input'];
};


export type QueryGetEnrollmentProgramByIdArgs = {
  enrollmentProgramId: Scalars['Int']['input'];
};


export type QueryGetForbiddenFormNumberArgs = {
  number?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetIdentityProviderArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetIngestJobByIdArgs = {
  ingestJobId: Scalars['Int']['input'];
};


export type QueryGetInstitutionNameFromNextGenArgs = {
  practiceId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};


export type QueryGetInstitutionNamesFromModernizingMedicineArgs = {
  firmUrlPrefix: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryGetInstitutionsByIdsArgs = {
  institutionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type QueryGetInsuranceResponsesArgs = {
  authorizationExtensionFilter?: InputMaybe<AuthorizationExtensionFilterValue>;
};


export type QueryGetIntegrationInstallationByInstitutionIdArgs = {
  institutionId: Scalars['Int']['input'];
};


export type QueryGetPortalHubsArgs = {
  portalKeys: Array<Scalars['String']['input']>;
};


export type QueryGetPortalPreviewArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPossibleFormsArgs = {
  NDC?: InputMaybe<Scalars['String']['input']>;
  benefitType?: InputMaybe<Scalars['String']['input']>;
  insuranceCompanyId?: InputMaybe<Scalars['Int']['input']>;
  insuranceType?: InputMaybe<Scalars['String']['input']>;
  jCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPossibleNumbersArgs = {
  authorizationFormId?: InputMaybe<Scalars['Int']['input']>;
  insuranceCompanyId?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStorySessionByIdArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetStorySessionsByAuthorizationIdArgs = {
  authorizationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetStorySessionsByInstitutionOrAccountArgs = {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  institutionId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetTestPatientsFromNextGenArgs = {
  firstName: Scalars['String']['input'];
  institutionId: Scalars['Int']['input'];
  lastName: Scalars['String']['input'];
};


export type QueryGetTreatmentsByPatientArgs = {
  duration: Scalars['Int']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  presetFilter?: InputMaybe<PatientPresetFilter>;
};


export type QueryGetUnresolvedFollowUpsArgs = {
  PortalId?: InputMaybe<Scalars['Int']['input']>;
  institutionId?: InputMaybe<Scalars['Int']['input']>;
  pageNum: Scalars['Int']['input'];
};


export type QueryGetUserSessionPreSignedUrlArgs = {
  object: UserSessionRecordingArgs;
};


export type QueryIcd10Args = {
  query?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInstitutionsFindAllArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInsuranceCompanyFeaturesArgs = {
  InsuranceCompanyId: Scalars['String']['input'];
  state: Scalars['String']['input'];
};


export type QueryIsValidZipCodeArgs = {
  zipCode: Scalars['String']['input'];
};


export type QueryListCorrespondencesArgs = {
  where: AuthorizationCorrespondenceWhere;
};


export type QueryListRedoxPatientDocumentReferencesArgs = {
  patientId: Scalars['Int']['input'];
};


export type QueryPatientByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPatientsByNameArgs = {
  dob?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  institutionPatientId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPatientsFindAllArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchWord?: InputMaybe<Scalars['String']['input']>;
  sortModel?: InputMaybe<Array<InputMaybe<GridSortItemInput>>>;
  where?: InputMaybe<PatientsFindAllWhere>;
};


export type QueryPortalsArgs = {
  where?: InputMaybe<PortalWhere>;
};


export type QueryPrescribersFindAllArgs = {
  InstitutionIdOverride?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRedoxInsuranceCompanyIdentifiersArgs = {
  where: RedoxInsuranceCompanyIdentiferWhere;
};


export type QueryRedoxSourcesArgs = {
  where: RedoxSourceWhere;
};


export type QuerySearchFormNumbersArgs = {
  partialNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchHcpcsArgs = {
  searchString?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySignatureByIdArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySignaturesFindAllArgs = {
  findAllInstitutionalSignatures?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySubscribersArgs = {
  where: SubscriberWhere;
};


export type QuerySupportToolAuthorizationsSearchArgs = {
  AuthorizationId?: InputMaybe<Scalars['Int']['input']>;
  InstitutionId?: InputMaybe<Scalars['String']['input']>;
  InsuranceCompanyId?: InputMaybe<Scalars['String']['input']>;
  PrescriberId?: InputMaybe<Scalars['String']['input']>;
  authorizationStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  patientFirstName?: InputMaybe<Scalars['String']['input']>;
  patientLastName?: InputMaybe<Scalars['String']['input']>;
  patientMemberId?: InputMaybe<Scalars['String']['input']>;
  submittedDaysAgo?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTrackerConfigsArgs = {
  where?: InputMaybe<TrackerConfigWhere>;
};


export type QueryUnifiedBenefitsChecksArgs = {
  DrugOptionId?: InputMaybe<Scalars['Int']['input']>;
  InstitutionId: Scalars['Int']['input'];
  PatientId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UnifiedBenefitsCheckType>;
};


export type QueryUserCredentialByFollowUpArgs = {
  followUpId?: InputMaybe<Scalars['Int']['input']>;
  isSupportQueue?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuestionAnswerStringObjectType = {
  __typename?: 'QuestionAnswerStringObjectType';
  valueString: Scalars['String']['output'];
};

export type QuestionEnableWhenObjectType = {
  __typename?: 'QuestionEnableWhenObjectType';
  answerBoolean?: Maybe<Scalars['Boolean']['output']>;
  answerString?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  operator: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export enum QuestionEnabledWhenBehaviourEnumType {
  All = 'all',
  Any = 'any'
}

export type QuestionGroupObjectType = {
  __typename?: 'QuestionGroupObjectType';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type QuestionObjectType = {
  __typename?: 'QuestionObjectType';
  answerOption?: Maybe<Array<QuestionAnswerStringObjectType>>;
  enableBehavior?: Maybe<QuestionEnabledWhenBehaviourEnumType>;
  enableWhen?: Maybe<Array<Maybe<QuestionEnableWhenObjectType>>>;
  groupId?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  linkId: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  type: QuestionTypeEnumType;
};

export enum QuestionTypeEnumType {
  Boolean = 'boolean',
  Choice = 'choice',
  Date = 'date',
  Number = 'number',
  Reference = 'reference',
  String = 'string'
}

export type QuestionnaireObjectType = {
  __typename?: 'QuestionnaireObjectType';
  all?: Maybe<Array<Maybe<QuestionObjectType>>>;
  /** The createdAt property of the Questionnaire model */
  createdAt: Scalars['DateTime']['output'];
  /** The customisations property of the Questionnaire model */
  customisations: Scalars['JSON']['output'];
  groups?: Maybe<Array<Maybe<QuestionGroupObjectType>>>;
  /** The id property of the Questionnaire model */
  id: Scalars['ID']['output'];
  /** The questions property of the Questionnaire model */
  questions: Scalars['JSON']['output'];
  /** The updatedAt property of the Questionnaire model */
  updatedAt: Scalars['DateTime']['output'];
};

export type QuestionnaireResponseAnswerInputObjectType = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionnaireResponseAnswerObjectType = {
  __typename?: 'QuestionnaireResponseAnswerObjectType';
  id: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type QuestionnaireResponseInputObjectType = {
  /** The QuestionnaireId property of the QuestionnaireResponse model */
  QuestionnaireId?: InputMaybe<Scalars['Int']['input']>;
  answers?: InputMaybe<Array<InputMaybe<QuestionnaireResponseAnswerInputObjectType>>>;
  /** The createdAt property of the QuestionnaireResponse model */
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The updatedAt property of the QuestionnaireResponse model */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuestionnaireResponseObjectType = {
  __typename?: 'QuestionnaireResponseObjectType';
  /** The QuestionnaireId property of the QuestionnaireResponse model */
  QuestionnaireId?: Maybe<Scalars['Int']['output']>;
  answers?: Maybe<Array<Maybe<QuestionnaireResponseAnswerObjectType>>>;
  /** The createdAt property of the QuestionnaireResponse model */
  createdAt: Scalars['DateTime']['output'];
  /** The id property of the QuestionnaireResponse model */
  id: Scalars['ID']['output'];
  /** The updatedAt property of the QuestionnaireResponse model */
  updatedAt: Scalars['DateTime']['output'];
};

export type RedoxDocumentReference = {
  __typename?: 'RedoxDocumentReference';
  contentType: Scalars['String']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RedoxIdentifierKindMapping = {
  __typename?: 'RedoxIdentifierKindMapping';
  RedoxSourceId: Scalars['Int']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  kind: IdentifierKind;
  system: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type RedoxInsuranceCompanyIdentiferWhere = {
  RedoxSourceId: Scalars['Int']['input'];
  system?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type RedoxInsuranceCompanyIdentifier = {
  __typename?: 'RedoxInsuranceCompanyIdentifier';
  InsuranceCompanyId: Scalars['Int']['output'];
  RedoxSourceId: Scalars['Int']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  system: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type RedoxPatientIdentifier = {
  __typename?: 'RedoxPatientIdentifier';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  kind: IdentifierKind;
  system: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  use: IdentifierUse;
  value: Scalars['String']['output'];
};

export type RedoxPatientIdentifierInput = {
  kind: IdentifierKind;
  system: Scalars['String']['input'];
  use?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type RedoxSource = {
  __typename?: 'RedoxSource';
  InstitutionId: Scalars['Int']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  facilityCode?: Maybe<Scalars['String']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  isFhirServer: Scalars['Boolean']['output'];
  organizationName: Scalars['String']['output'];
  patientSource: PatientSource;
  redoxIdentifierKindMappings: Array<RedoxIdentifierKindMapping>;
  sourceId: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type RedoxSourceWhere = {
  InstitutionId?: InputMaybe<Scalars['Int']['input']>;
  facilityCode?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
};

export type RedoxSubscriberIdentifierInput = {
  kind: IdentifierKind;
  system: Scalars['String']['input'];
  use?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type RedoxSubscriberIdentityWhere = {
  system?: InputMaybe<Scalars['String']['input']>;
  use?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshPrimaryInsuranceCoverageCheckResult = {
  __typename?: 'RefreshPrimaryInsuranceCoverageCheckResult';
  data?: Maybe<InsuranceCoverageCheckType>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully added a MFA phone number to the account */
  success: Scalars['Boolean']['output'];
};

export type RegenerateLomnAttachmentResult = {
  __typename?: 'RegenerateLOMNAttachmentResult';
  /** The error message we will display to our end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully regenerated the document */
  success: Scalars['Boolean']['output'];
};

export type RenderingProvider = {
  city?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  npi: Scalars['String']['input'];
  specialty?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RequestNewPortalIntegrationInput = {
  /** The name of the insurance company associated with the portal */
  insuranceCompany: Scalars['String']['input'];
  /** The URL the end user uses to log into the portal */
  portalUrl: Scalars['String']['input'];
  /** The frequency with which the end user uses this portal */
  usageFrequency: Scalars['String']['input'];
};

export type RequestNewPortalIntegrationResponse = {
  __typename?: 'RequestNewPortalIntegrationResponse';
  success: Scalars['Boolean']['output'];
};

export type Requester = {
  contactName: Scalars['String']['input'];
  fax?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type RequestingProvider = {
  PrescriberId: Scalars['Int']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  npi: Scalars['String']['input'];
  roleCode: RequestingProviderRoleCode;
  specialty?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum RequestingProviderRoleCode {
  Facility = 'Facility',
  Provider = 'Provider'
}

export type SftpKey = {
  __typename?: 'SFTPKey';
  InstitutionId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  sftpUsername: Scalars['String']['output'];
  sshPrivateKey?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type SamaStorySession = {
  __typename?: 'SamaStorySession';
  AuthorizationId?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** The account that generated the story sessions */
  createdBy?: Maybe<Account>;
  /** The signed URLs for the event files */
  events?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Whether the authorization associated to the SamaStorySession has been deleted or not */
  hasDeletedAuthorization?: Maybe<Scalars['Boolean']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  sessionURL?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type SaveClientLog = {
  __typename?: 'SaveClientLog';
  /** Whether or not we successfully sent log to Mezmo */
  success: Scalars['Boolean']['output'];
};

export type SaveSessionRecordingReferenceResult = {
  __typename?: 'SaveSessionRecordingReferenceResult';
  /** Success or failure */
  success: Scalars['Boolean']['output'];
};

export type SegmentDetailsInputCreatePa = {
  /** Portal origin URL of the portal */
  portalOrigin?: InputMaybe<Scalars['String']['input']>;
};

export type SegmentDetailsInputUpdatePa = {
  /** Portal origin URL of the portal */
  portalOrigin?: InputMaybe<Scalars['String']['input']>;
  /** Source of the update */
  updateSource?: InputMaybe<Scalars['String']['input']>;
};

export type SendResponsesToHcpMutationResult = {
  __typename?: 'SendResponsesToHCPMutationResult';
  /** Whether or not we successfully sent a fax to the payer */
  success: Scalars['Boolean']['output'];
};

export type Service = {
  description?: InputMaybe<Scalars['String']['input']>;
  hcpcsCode: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  quantityType: ServiceQuantityTypeCode;
  startDate: Scalars['String']['input'];
};

/** An option to choose from when choosing service codes */
export type ServiceCode = {
  __typename?: 'ServiceCode';
  code: Scalars['String']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  drugName: Scalars['String']['output'];
  drugOptionId?: Maybe<Scalars['Int']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum ServiceQuantityTypeCode {
  Days = 'Days',
  Hours = 'Hours',
  Months = 'Months',
  Units = 'Units',
  Visits = 'Visits'
}

export enum ServiceTypesEnum {
  Authorizations = 'Authorizations',
  BenefitsVerifications = 'BenefitsVerifications',
  NewEnrollments = 'NewEnrollments'
}

export type SetManuallyConfiguredBoxesOnFormInput = {
  formId: Scalars['Int']['input'];
  manuallyConfiguredBoxes?: InputMaybe<Array<InputMaybe<ManuallyConfiguredBoxesInput>>>;
};

export type SetManuallyConfiguredBoxesOnFormMutationResult = {
  __typename?: 'SetManuallyConfiguredBoxesOnFormMutationResult';
  /** Whether or not successfully set Manually Configured Boxes OnForm */
  success: Scalars['Boolean']['output'];
};

export type Site = {
  __typename?: 'Site';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  host: Scalars['String']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

/** The possible status at submission capture types */
export enum StatusAtSubmissionCaptureType {
  Auto = 'auto',
  Manual = 'manual'
}

export type StatusCheckerResponse = {
  __typename?: 'StatusCheckerResponse';
  status?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  userInputs?: Maybe<Array<Maybe<StatusCheckerUserInput>>>;
};

export type StatusCheckerUserInput = {
  __typename?: 'StatusCheckerUserInput';
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum StatusNotFoundReason {
  BlockedOnMfa = 'BLOCKED_ON_MFA',
  Other = 'OTHER',
  UnableToLocateAuth = 'UNABLE_TO_LOCATE_AUTH',
  UnableToLocatePatient = 'UNABLE_TO_LOCATE_PATIENT',
  WrongCredentials = 'WRONG_CREDENTIALS'
}

export enum SubmitCareMetxBenefitsVerificationStatus {
  RequiresEnrollment = 'REQUIRES_ENROLLMENT',
  Success = 'SUCCESS'
}

export type Subscriber = {
  __typename?: 'Subscriber';
  PatientSourceId: Scalars['Int']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  dob: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export enum SubscriberRelationshipCode {
  Child = 'Child',
  Other = 'Other',
  Self = 'Self',
  Spouse = 'Spouse'
}

export type SubscriberWhere = {
  PatientSourceId: Scalars['String']['input'];
  redoxSubscriberIdentifier?: InputMaybe<RedoxSubscriberIdentityWhere>;
};

export type SupportedAuthorizationFollowUp = {
  __typename?: 'SupportedAuthorizationFollowUp';
  AuthorizationId: Scalars['Int']['output'];
  authorization?: Maybe<Authorization>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['Int']['output'];
  ignoreUntilNextOccurrenceAfter?: Maybe<Scalars['DateTime']['output']>;
  isResolved?: Maybe<Scalars['Boolean']['output']>;
  madeActiveAt: Scalars['DateTime']['output'];
  screenshotAwsKey?: Maybe<Scalars['String']['output']>;
  screenshotUrl?: Maybe<Scalars['String']['output']>;
  statusNotFoundReason?: Maybe<StatusNotFoundReason>;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type TagType = {
  __typename?: 'TagType';
  InstitutionId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export enum TemplateType {
  BenefitsVerification = 'BENEFITS_VERIFICATION',
  Enrollment = 'ENROLLMENT'
}

export enum TerminalStatusUpdateType {
  AcceptSuggestion = 'ACCEPT_SUGGESTION',
  AutomaticFaxAssociation = 'AUTOMATIC_FAX_ASSOCIATION',
  AvailityApiTracker = 'AVAILITY_API_TRACKER',
  ExtensionAuto = 'EXTENSION_AUTO',
  ExtensionManual = 'EXTENSION_MANUAL',
  ExternalTracker = 'EXTERNAL_TRACKER',
  Support = 'SUPPORT',
  TargetedTracker = 'TARGETED_TRACKER',
  Unknown = 'UNKNOWN',
  User = 'USER'
}

export type TrackerConfig = {
  __typename?: 'TrackerConfig';
  authTypes: Array<Scalars['String']['output']>;
  batchKind?: Maybe<BatchKind>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  exclusions: Array<Scalars['JSON']['output']>;
  /** ID - primary key */
  id: Scalars['ID']['output'];
  insuranceCompanyIds: Array<Scalars['Int']['output']>;
  portalIds: Array<Scalars['Int']['output']>;
  portalKeys: Array<Scalars['String']['output']>;
  runAtHoursInLaTz: Array<Scalars['Int']['output']>;
  trackerKey: TrackerKey;
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
};

export type TrackerConfigWhere = {
  trackerKey?: InputMaybe<TrackerKey>;
};

export enum TrackerKey {
  ApiAvaility = 'API_AVAILITY',
  RpaAim = 'RPA_AIM',
  RpaAvailityBcbsmAdvantage = 'RPA_AVAILITY_BCBSM_ADVANTAGE',
  RpaAvailityBcbsmCommercial = 'RPA_AVAILITY_BCBSM_COMMERCIAL',
  RpaAvailityBcbsmMedicare = 'RPA_AVAILITY_BCBSM_MEDICARE',
  RpaAvailityBcbsTn = 'RPA_AVAILITY_BCBS_TN',
  RpaAvailityGeneric = 'RPA_AVAILITY_GENERIC',
  RpaCigna = 'RPA_CIGNA',
  RpaCoverMyMeds = 'RPA_COVER_MY_MEDS',
  RpaEvicore = 'RPA_EVICORE',
  RpaEvicoreMedSolutions = 'RPA_EVICORE_MED_SOLUTIONS',
  RpaHap = 'RPA_HAP',
  RpaHh = 'RPA_HH',
  RpaHorizonNj = 'RPA_HORIZON_NJ',
  RpaMagellan = 'RPA_MAGELLAN',
  RpaMartinsPoint = 'RPA_MARTINS_POINT',
  RpaNewCenturyHealth = 'RPA_NEW_CENTURY_HEALTH',
  RpaPremier = 'RPA_PREMIER',
  RpaSelectHealth = 'RPA_SELECT_HEALTH',
  RpaUhc = 'RPA_UHC',
  RpaUmr = 'RPA_UMR',
  RpaWellcare = 'RPA_WELLCARE'
}

export enum TrackingStatus {
  AcceptedError = 'ACCEPTED_ERROR',
  AccessBlocked = 'ACCESS_BLOCKED',
  AlreadyTerminal = 'ALREADY_TERMINAL',
  Archived = 'ARCHIVED',
  BlockedOnMfa = 'BLOCKED_ON_MFA',
  BrowserlessTimeout = 'BROWSERLESS_TIMEOUT',
  CaptchaRetryLimitReached = 'CAPTCHA_RETRY_LIMIT_REACHED',
  CheckedBySupport = 'CHECKED_BY_SUPPORT',
  CheckedByTracker = 'CHECKED_BY_TRACKER',
  IncorrectIdentifierInput = 'INCORRECT_IDENTIFIER_INPUT',
  LoginFailures = 'LOGIN_FAILURES',
  MissingCredentials = 'MISSING_CREDENTIALS',
  MissingIdentifier = 'MISSING_IDENTIFIER',
  MissingUi = 'MISSING_UI',
  MultipleAuthsFound = 'MULTIPLE_AUTHS_FOUND',
  NavigationError = 'NAVIGATION_ERROR',
  NoAccountsWithCreds = 'NO_ACCOUNTS_WITH_CREDS',
  NoAuthFound = 'NO_AUTH_FOUND',
  PasswordResetNeeded = 'PASSWORD_RESET_NEEDED',
  ScrapingError = 'SCRAPING_ERROR',
  StatusNotFound = 'STATUS_NOT_FOUND',
  TooOld = 'TOO_OLD',
  UnexpectedErrorDuringLogin = 'UNEXPECTED_ERROR_DURING_LOGIN',
  UnknownError = 'UNKNOWN_ERROR',
  UnrecognizedStatus = 'UNRECOGNIZED_STATUS',
  WrongCreds = 'WRONG_CREDS'
}

export type UnifiedBenefitsCheck = {
  __typename?: 'UnifiedBenefitsCheck';
  authAssociated: Array<Maybe<Authorization>>;
  createdAt: Scalars['DateTime']['output'];
  drug?: Maybe<DrugOption>;
  fullData?: Maybe<FullBenefitsCheckData>;
  id: Scalars['String']['output'];
  insuranceCompany?: Maybe<InsuranceCompany>;
  insuranceCompanyData?: Maybe<UnifiedBenefitsCheckInsuranceCompanyData>;
  insurancePolicyData?: Maybe<UnifiedBenefitsCheckInsurancePolicyData>;
  liteData?: Maybe<InsuranceCoverageCheckType>;
  patient: Patient;
  status: UnifiedBenefitsCheckStatus;
  type: UnifiedBenefitsCheckType;
  updatedAt: Scalars['DateTime']['output'];
};

export type UnifiedBenefitsCheckInsuranceCompanyData = {
  __typename?: 'UnifiedBenefitsCheckInsuranceCompanyData';
  name?: Maybe<Scalars['String']['output']>;
};

export type UnifiedBenefitsCheckInsurancePolicyData = {
  __typename?: 'UnifiedBenefitsCheckInsurancePolicyData';
  insuranceState?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
};

export enum UnifiedBenefitsCheckStatus {
  Active = 'active',
  Draft = 'draft',
  Error = 'error',
  Inactive = 'inactive',
  Pending = 'pending',
  Success = 'success'
}

export enum UnifiedBenefitsCheckType {
  Full = 'full',
  Lite = 'lite'
}

export type UpdateAccountPatch = {
  mfaConfiguredPortalIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateBenefitsVerificationOutcomePatch = {
  benefitsVerificationId?: InputMaybe<Scalars['String']['input']>;
  outcomeFields?: InputMaybe<BenefitsVerificationOutcomeFields>;
  patientId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateBenefitsVerificationPatch = {
  RequestedById?: InputMaybe<Scalars['Int']['input']>;
  benefitsVerificationData?: InputMaybe<UpdateBenefitsVerificationPatchData>;
  patientData?: InputMaybe<UpdateBenefitsVerificationPatchPatientData>;
  primaryInsuranceData?: InputMaybe<UpdateBenefitsVerificationPatchInsuranceData>;
  secondaryInsuranceData?: InputMaybe<UpdateBenefitsVerificationPatchInsuranceData>;
};

export type UpdateBenefitsVerificationPatchData = {
  DrugOptionId?: InputMaybe<Scalars['Int']['input']>;
  LocationId?: InputMaybe<Scalars['Int']['input']>;
  PrescriberId?: InputMaybe<Scalars['Int']['input']>;
  hcpcsCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  icds?: InputMaybe<Array<Scalars['String']['input']>>;
  locationPtan?: InputMaybe<Scalars['String']['input']>;
  patientStatus?: InputMaybe<PatientStatusType>;
  prescribedDate?: InputMaybe<Scalars['String']['input']>;
  prescriberPtan?: InputMaybe<Scalars['String']['input']>;
  primaryInsuranceCompanyPhone?: InputMaybe<Scalars['String']['input']>;
  providerInNetwork?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryInsuranceCompanyPhone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBenefitsVerificationPatchInsuranceData = {
  InsuranceCompanyId?: InputMaybe<Scalars['Int']['input']>;
  groupNumber?: InputMaybe<Scalars['String']['input']>;
  insuranceState?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  planType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBenefitsVerificationPatchPatientData = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLoginErrorDuringStatusCheckResult = {
  __typename?: 'UpdateLoginErrorDuringStatusCheckResult';
  /** Whether or not we successfully toggle statusCheckLoginRequiresAttn flag on authorizations */
  success: Scalars['Boolean']['output'];
};

export type UpdatePatientArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  ehrId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  heightInches?: InputMaybe<Scalars['Int']['input']>;
  institutionPatientId?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mrn?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType?: InputMaybe<PatientPhoneType>;
  primaryInsurance?: InputMaybe<InsurancePolicyInput>;
  redoxPatientIdentifiers?: InputMaybe<Array<RedoxPatientIdentifierInput>>;
  secondaryInsurance?: InputMaybe<InsurancePolicyInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  weightPounds?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePortalArgs = {
  enableFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  formFieldConfigKey?: InputMaybe<Scalars['String']['input']>;
  hideCredentialsFromSupport?: InputMaybe<Scalars['Boolean']['input']>;
  hubKey?: InputMaybe<Scalars['String']['input']>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isTop?: InputMaybe<Scalars['Boolean']['input']>;
  loginUrl?: InputMaybe<Scalars['String']['input']>;
  requiresPhoneMfa?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSubscriberArgs = {
  dob?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  redoxSubscriberIdentifiers?: InputMaybe<Array<RedoxSubscriberIdentifierInput>>;
};

export type UpdateTrackerConfigArgs = {
  authTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  batchKind?: InputMaybe<BatchKind>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  exclusions?: InputMaybe<Array<Scalars['JSON']['input']>>;
  insuranceCompanyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  portalIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  portalKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  runAtHoursInLaTz?: InputMaybe<Array<Scalars['Int']['input']>>;
  trackerKey?: InputMaybe<TrackerKey>;
};

export type UploadDocumentToModernizingMedicineResult = {
  __typename?: 'UploadDocumentToModernizingMedicineResult';
  /** The error message we will display to our end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully upload documents to Modernizing Medicine API */
  success: Scalars['Boolean']['output'];
};

export type UploadDocumentToNextGenResult = {
  __typename?: 'UploadDocumentToNextGenResult';
  /** The error message we will use to communicate to end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not the error message contains debug-specific error context */
  requiresDebug?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not we successfully upload documents to Next Gen API */
  success: Scalars['Boolean']['output'];
};

export type UploadDocumentsToRedoxResult = {
  __typename?: 'UploadDocumentsToRedoxResult';
  /** The error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Was the upload successful */
  success: Scalars['Boolean']['output'];
};

export type UpsertAccountForSamaUsersPatch = {
  DEA?: InputMaybe<Scalars['String']['input']>;
  InstitutionId?: InputMaybe<Scalars['Int']['input']>;
  NPI?: InputMaybe<Scalars['String']['input']>;
  TIN?: InputMaybe<Scalars['String']['input']>;
  allowedInstitutionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedOut?: InputMaybe<Scalars['Boolean']['input']>;
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  mfaConfiguredPortalIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  portalCredentials?: InputMaybe<Array<Scalars['JSON']['input']>>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertModernizingMedicineIntegrationInstallationResult = {
  __typename?: 'UpsertModernizingMedicineIntegrationInstallationResult';
  /** The error message we will display to our end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully created a new IntegrationInstallation for Modernizing Medicine */
  success: Scalars['Boolean']['output'];
};

export type UpsertNextGenIntegrationInstallationResult = {
  __typename?: 'UpsertNextGenIntegrationInstallationResult';
  /** The error message we will display to our end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully created a new IntegrationInstallation for NextGen */
  success: Scalars['Boolean']['output'];
};

export type UpsertPatientIntegrationCredentialMutationPatch = {
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  loginURL?: InputMaybe<Scalars['String']['input']>;
  officeKey?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertRedoxIntegrationInstallationResult = {
  __typename?: 'UpsertRedoxIntegrationInstallationResult';
  /** The error message we will display to our end user */
  message?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully created a new IntegrationInstallation for Redox */
  success: Scalars['Boolean']['output'];
};

export type UserCredential = {
  __typename?: 'UserCredential';
  hub?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  portalKey?: Maybe<Scalars['String']['output']>;
  portalMfaPhoneNumber?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserSessionRecordingArgs = {
  portalHost: Scalars['String']['input'];
  sessionKey: Scalars['String']['input'];
  userAuthorizationId: Scalars['String']['input'];
};

export type UserSessionRecordingUrl = {
  __typename?: 'UserSessionRecordingUrl';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID - primary key */
  id: Scalars['ID']['output'];
  /** Last updated at */
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

/** How the front end should render the caremetx enrollment module */
export enum CaremetxEnrollmentDisplayStatus {
  DisplayAndEnable = 'displayAndEnable',
  DisplayButDisable = 'displayButDisable',
  Hide = 'hide'
}

export type CreatePaFromEnrollmentPatch = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  type: Scalars['String']['input'];
};

/** The possible durg code type of the service (ndc or Jcode or both) */
export enum DrugCodeType {
  Jcode = 'JCODE',
  Ndc = 'NDC',
  NdcAndJcode = 'NDC_AND_JCODE'
}

export type FramesHtml = {
  frameName?: InputMaybe<Scalars['String']['input']>;
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

/** The possible practicing specialties for an institution */
export enum PracticingSpecialty {
  AllergyAsthmaImmunology = 'ALLERGY_ASTHMA_IMMUNOLOGY',
  Ent = 'ENT',
  Gastro = 'GASTRO',
  MultiSpecInfusion = 'MULTI_SPEC_INFUSION',
  Neurology = 'NEUROLOGY',
  Oncology = 'ONCOLOGY',
  Retina = 'RETINA',
  Rheumatology = 'RHEUMATOLOGY',
  Urology = 'UROLOGY'
}

export type SaveClientMetric = {
  __typename?: 'saveClientMetric';
  /** Whether or not we successfully saved metric in DataDog */
  success: Scalars['Boolean']['output'];
};

export type ServicesGroupedByDrug = {
  __typename?: 'servicesGroupedByDrug';
  DrugOptionId?: Maybe<Scalars['Int']['output']>;
  authorizations?: Maybe<Array<Maybe<Authorization>>>;
  bvs?: Maybe<Array<Maybe<BenefitsVerificationBasic>>>;
  drugCode?: Maybe<Scalars['String']['output']>;
  drugName?: Maybe<Scalars['String']['output']>;
  enrollments?: Maybe<Array<Maybe<NewEnrollmentObjectType>>>;
  expiringAuth: Scalars['Boolean']['output'];
  supportBvWorkflow?: Maybe<Scalars['Boolean']['output']>;
  supportEnrollmentWorkflow?: Maybe<Scalars['Boolean']['output']>;
};

export type Settings = {
  __typename?: 'settings';
  /** Next Gen API installation config field */
  documentType?: Maybe<Scalars['String']['output']>;
  /** Modernizing Medicine installation config field */
  firmUrlPrefix?: Maybe<Scalars['String']['output']>;
  /** Next Gen API installation config field */
  password?: Maybe<Scalars['String']['output']>;
  /** Next Gen API installation config field */
  practiceId?: Maybe<Scalars['String']['output']>;
  /** Next Gen API installation config field */
  siteId?: Maybe<Scalars['String']['output']>;
  /** Modernizing Medicine installation config field */
  username?: Maybe<Scalars['String']['output']>;
};

export type TrackPluginPortalPageHtmlResponseType = {
  __typename?: 'trackPluginPortalPageHTMLResponseType';
  /** Whether or not we successfully sent a fax to the payer */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Whether or not we successfully sent a fax to the payer */
  success: Scalars['Boolean']['output'];
};

export type WriteEnrollmentInfoToEnrollmentFormResult = {
  __typename?: 'writeEnrollmentInfoToEnrollmentFormResult';
  /** The pdf file we created after write the enrollment info to enrollment form */
  fileUrl: Scalars['String']['output'];
};
