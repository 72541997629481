import gql from "graphql-tag";

export default gql`
  fragment portalInformation on Portal {
    id
    title
    loginUrl
    hubKey
    isTop
    isLegacy
    enableFollowUps
    hideCredentialsFromSupport
    requiresPhoneMfa
    formFieldConfigKey
  }
`;
