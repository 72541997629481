import React, { useState } from "react";

import { Paper, Typography, LinearProgress } from "@samacare/design";
import CloseIcon from "@samacare/design/core/icons/Close";
import UploadFileOutlined from "@samacare/design/core/icons/UploadFileOutlined";
import styled from "styled-components";
import Upload from "@@components/Upload";
import { useDispatch } from "react-redux";
import {
  File as FormFile,
  removeAttachment,
  setAttachment,
  toggleLoadingSegment,
} from "../../../reducers/formReducer";
import IntegratedDownloadedFilesSuggestionModal from "AuthorizationSharedComponents/IntegratedDownloadedFilesSuggestionModal";
import { Account } from "@samacare/graphql";
import { AxiosResponse } from "axios";

const Block = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;
  border-radius: 4px;
  border-top: 4px solid rgba(99, 99, 242, 0.5);
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  margin-bottom: 8px;
`;

const StyledUpload = styled(Upload)`
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 24px 16px;
`;

const Icon = styled(UploadFileOutlined)`
  color: #6363f2;
  width: 24px;
  height: 24px;
  margin: 8px;
`;

const FileRaw = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;
`;

const FirstRawPart = styled.div`
  display: flex;
  align-items: center;
`;

const FileDetails = styled.div`
  padding-left: 16px;
`;

const Status = styled.div`
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  gap: 8px;
`;

const ProgressBar = styled(LinearProgress)`
  width: 200px;
  color: #6363f2;
  height: 4px;
  border-radius: 5px;
  margin-top: 4px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 12px;
`;

const maxSize = 3000000; // 3 MB

interface IUploadingFile {
  name: string;
}

interface UploadAttachmentsProps {
  attachments: FormFile[];
  account: Account;
  authorizationId: string;
  showEMRDocumentOption: boolean;
  multiple: boolean;
  header: string;
  details: string;
  hideUploader?: boolean;
  disabled?: boolean;
}

const UploadAttachments: (props: UploadAttachmentsProps) => JSX.Element = ({
  attachments,
  account,
  authorizationId,
  showEMRDocumentOption,
  multiple,
  header,
  details,
  hideUploader,
  disabled,
}: UploadAttachmentsProps) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<IUploadingFile[]>([]);
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const set = (file: FormFile) => dispatch(setAttachment(file));
  const remove = (file: FormFile) => dispatch(removeAttachment(file));
  const setLoading = (b: boolean) => dispatch(toggleLoadingSegment(b));

  const onDropSuccess = (file: IUploadingFile, res: AxiosResponse) => {
    set({
      title: file.name,
      id: res.data.id,
      awsKey: res.data.fields.key,
    });
    setLoading(false);
  };

  const onDrop = () => {
    setLoading(true);
  };

  const onDropError = () => {
    setLoading(false);
  };

  return (
    <Block>
      <Header>
        <Typography variant="h6" gutterBottom>
          {header}
        </Typography>
        <Typography variant="body2">{details}</Typography>
      </Header>
      {!hideUploader && (
        <StyledUpload
          className="dropzone-new"
          onDrop={onDrop}
          onDropSuccess={onDropSuccess}
          onDropError={onDropError}
          setProgress={setProgress}
          setFiles={setFiles}
          isSimplifiedUx={true}
          fullWidth={true}
          buttonText="Click to upload"
          helperText="PDF (max. 3MB)"
          acceptedTypes={[window.CONFIG.CONSTANTS.CONTENT_TYPES.PDF]}
          customMaxSize={maxSize}
          multiple={multiple}
        />
      )}
      {showEMRDocumentOption && (
        <IntegratedDownloadedFilesSuggestionModal
          authorizationId={authorizationId}
          account={account}
          isSimplifiedUx={true}
        />
      )}
      {attachments.map((file) => (
        <FileRaw key={`uploaded-file-id-${file.id}`}>
          <FirstRawPart>
            <Icon />
            <FileDetails>{file.title}</FileDetails>
          </FirstRawPart>
          {!disabled && <StyledCloseIcon onClick={() => remove(file)} />}
        </FileRaw>
      ))}
      {files.map((file) => (
        <FileRaw key={file.name}>
          <FirstRawPart>
            <Icon />
            <FileDetails>
              <div>{file.name}</div>
              <Status>
                <div>{progress[file.name]}%</div>
                <div>•</div>
                <div>Loading</div>
              </Status>
              <ProgressBar variant="determinate" value={progress[file.name]} />
            </FileDetails>
          </FirstRawPart>
        </FileRaw>
      ))}
    </Block>
  );
};

export default UploadAttachments;
