import { Box, Button, Stack, Typography } from "@samacare/design";
import { useEffect, useState } from "react";
import Add from "@samacare/design/core/icons/Add";
import { ServiceCode } from "@samacare/graphql";
import { useFieldArray } from "react-hook-form";
import {
  ServiceCodeInput,
  ServiceCodeOptionalFields,
} from "./ServiceCodeInput";

// eslint-disable-next-line no-shadow
enum ServiceCodeSelectorDataSources {
  AllServiceCodes = "allServiceCodes",
  DrugOptionsOnly = "drugOptionsOnly",
  HcpcsCodesOnly = "hcpcsCodesOnly",
}

export interface ServiceCodeSelectorInternalFunctionalProps {
  required?: boolean;
  disabled?: boolean;
  limit?: number;
  drugOptionRequired?: boolean;
  dataSource?: ServiceCodeSelectorDataSources;
  name: string;
  optionalFields?: ServiceCodeOptionalFields[];
  showFirstServiceCode?: boolean;
  disableSelectedService?: boolean;
}

export interface ServiceCodeSelectorFunctionalProps
  extends ServiceCodeSelectorInternalFunctionalProps {
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
}

export type ServiceCodeSelectorDataProps = {
  allServiceCodes: ServiceCode[];
  drugOptions: ServiceCode[];
  hcpcsCodes: ServiceCode[];
  loading: boolean;
};

export type ServiceCodeSelectorAllProps =
  ServiceCodeSelectorInternalFunctionalProps & ServiceCodeSelectorDataProps;

export const ServiceCodeSelector: React.FC<ServiceCodeSelectorAllProps> = (
  props,
) => {
  const {
    required = false,
    disabled = false,
    limit = 5,
    allServiceCodes,
    drugOptions,
    hcpcsCodes,
    loading,
    drugOptionRequired = true,
    dataSource = ServiceCodeSelectorDataSources.AllServiceCodes,
    name,
    optionalFields,
    showFirstServiceCode = true,
    disableSelectedService,
  } = props;
  const { fields, append, remove } = useFieldArray({ name });
  const [firseServiceCodeSelected, setFirstServiceCodeSelected] =
    useState(false);

  useEffect(() => {
    if (showFirstServiceCode && fields.length > 0 && disableSelectedService) {
      setFirstServiceCodeSelected(true);
    }
    if (showFirstServiceCode && fields.length === 0) {
      append({});
    }
  }, [append, fields.length, showFirstServiceCode, disableSelectedService]);

  const serviceCodes = (): ServiceCode[] => {
    switch (dataSource) {
      case ServiceCodeSelectorDataSources.DrugOptionsOnly: {
        return drugOptions;
      }
      case ServiceCodeSelectorDataSources.HcpcsCodesOnly: {
        return hcpcsCodes;
      }
      default: {
        return allServiceCodes;
      }
    }
  };

  return (
    <Stack spacing={2} maxWidth={600}>
      {fields.map(({ id }, idx) => (
        <ServiceCodeInput
          key={id}
          required={required}
          disabled={disabled || (idx === 0 && firseServiceCodeSelected)}
          drugOptionRequired={drugOptionRequired}
          optionalFields={optionalFields}
          serviceCodes={
            drugOptionRequired &&
            dataSource === ServiceCodeSelectorDataSources.AllServiceCodes &&
            idx === 0
              ? drugOptions
              : serviceCodes()
          }
          handleDelete={disabled ? undefined : () => remove(idx)}
          isFirst={idx === 0}
          loading={loading}
          name={`${name}.${idx}`}
        />
      ))}
      {limit > 1 && !disabled && (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          {fields.length === limit ? (
            <Typography
              variant="caption"
              color="GrayText"
            >{`Maximum of ${limit} Service Codes reached`}</Typography>
          ) : (
            <Button
              onClick={() => append({})}
              startIcon={<Add />}
              data-cy="actionAddServiceCode"
            >
              Add Service Code
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};
