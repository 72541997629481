import { useQuery } from "@apollo/client";
import GetPortals from "../graphql/GetPortals.gql";
import { GetPortalsQuery } from "@@generated/graphql";
import { useMemo } from "react";
import { Portal } from "@samacare/graphql";

export const usePortals = () => {
  const { data, loading } = useQuery<GetPortalsQuery>(GetPortals);
  const portals = useMemo(() => data?.portals ?? [], [data]) as Portal[];
  return { portals, loading };
};
