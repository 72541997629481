import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { withAlert } from "react-alert";
import { ICDRegex } from "../../../server/src/shared_code";

import { withICD10Search } from "../graphql/ICD10";
import colors from "Resources/colors";

const ICDContainer = styled.div`
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid ${(props) => props.theme.purple};
  display: flex;
  align-items: center;

  div {
    color: ${(props) => props.theme.purple};
    margin-left: auto;
    min-width: 100px;
    cursor: pointer;
    text-align: right;

    &:hover {
      font-weight: 700;
    }
  }
`;

const RegexWarning = styled.span`
  margin: 5px;
  color: ${(props) => props.theme.red};
`;

const CreateableICD = styled(Creatable)`
  width: 600px;
`;

export class IcdSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      icds: _.reduce(
        props.initializedICDs,
        (total, code) => ({ ...total, [code]: code }),
        {}
      ),
      formatWarning: "",
    };

    if (props.setAddICDFromParent) {
      props.setAddICDFromParent(this.addICDFromParent);
    }
  }

  // for BV components using the 'value' prop, reset the rendered options
  // when those options are cleared by the parent form compoent
  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    const { icds } = this.state;
    if (prevState.icds != null && icds != null && value === null) {
      this.setState((state) => {
        return { ...state, icds: null };
      });
    }
  }

  requeryResults = (searchTerm) => {
    const { refetch } = this.props;
    return refetch({ searchTerm });
  };

  addICDFromParent = (icd) => {
    const { onChange, max, alert } = this.props;
    const { icds } = this.state;

    if (!max || _.keys(icds).length < max) {
      this.setState({ icds: { ...icds, [icd]: icd } }, () => {
        onChange(this.state.icds);
      });
    } else {
      alert.error("Maximum number of ICD codes reached");
    }
  };

  setICDs = (icds) => {
    const { onChange, max, alert } = this.props;
    if (!max || _.keys(icds).length <= max) {
      this.setState({ icds }, () => {
        onChange(icds);
      });
    } else {
      alert.error("Maximum number of ICD codes reached");
    }
  };

  getICDErrors = (upperCaseICD) => {
    const nonAlphanumericOrDot = /[^A-Z0-9.]/g;

    if (!ICDRegex.test(upperCaseICD)) {
      return "ICD Codes must start with a letter and two numbers (i.e. H43)";
    }

    if (
      nonAlphanumericOrDot.test(upperCaseICD) ||
      (upperCaseICD.length >= 5 && !upperCaseICD.includes("."))
    ) {
      return (
        "ICD Codes must be in X00.0000 format (i.e C20, H32.0, H31.001, etc). Other characters such as " +
        'spaces, "(" or ")" are not allowed'
      );
    }

    if (_.last(upperCaseICD) === ".") {
      return 'ICD Code cannot end in "."';
    }

    if (upperCaseICD.length > 8) {
      return "ICD Code length too long";
    }

    return null;
  };

  render() {
    const { icd10, icdError, max, disabled, highlightRequiredFields } =
      this.props;
    const { icds, formatWarning } = this.state;
    const isDisabled = (max && max <= _.size(icds)) || disabled;
    return (
      <div data-cy="controlIcdSelector" style={{ width: "100%" }}>
        {!icdError ? (
          <Select
            value={null}
            style={{
              minWidth: 300,
              border:
                highlightRequiredFields && _.isEmpty(icds)
                  ? `2px solid ${colors.red}`
                  : "",
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                minHeight: "46px",
              }),
            }}
            placeholder="Type to search ICD codes..."
            onInputChange={(input) => {
              this.requeryResults(input);
            }}
            options={_.map(icd10, (icd) => ({
              label: icd.join(": "),
              value: icd,
            }))}
            onChange={(selected) => {
              this.setICDs({ ...icds, [selected.value[0]]: selected.label });
            }}
            isDisabled={isDisabled}
          />
        ) : (
          <div>
            {formatWarning && <RegexWarning>{formatWarning}</RegexWarning>}
            <CreateableICD
              value={null}
              style={{
                minWidth: 300,
                marginBottom: "32px",
                border:
                  highlightRequiredFields && _.isEmpty(icds)
                    ? "2px solid red"
                    : "",
              }}
              placeholder="nih.gov is currently unavailable. Type to enter ICD codes..."
              onCreateOption={(selected) => {
                const newIcd = selected.toUpperCase();
                if (this.getICDErrors(newIcd)) {
                  this.setState({ formatWarning: this.getICDErrors(newIcd) });
                } else {
                  this.setState({ formatWarning: "" });
                  this.setICDs({ ...icds, [newIcd]: newIcd });
                }
              }}
              isDisabled={isDisabled}
            />
          </div>
        )}
        <div>
          {_.map(icds, (icdDisplay, key) => (
            <ICDContainer key={`ICD_container_${icdDisplay}`}>
              {icdDisplay}
              <div
                onClick={() => {
                  this.setICDs(_.omit(icds, key));
                }}
              >
                Remove
              </div>
            </ICDContainer>
          ))}
        </div>
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withICD10Search)(withAlert()(IcdSelector));
