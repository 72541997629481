import _ from "lodash";
import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import Select from "react-select";
import styled from "styled-components";
import strings from "Resources/strings";
import { BaseText } from "Segment/StyledComponents";
import {
  IdentifierCaptureType,
  TerminalStatusUpdateType,
} from "@samacare/graphql";

import { withUpdateAuthorizationStatus } from "../graphql/Authorization";
import { withCurrentAccount } from "../graphql/Account";
import { withCreateFollowUp } from "../graphql/FollowUp";
import BaseButton from "./BaseButton";
import Modal from "./Modal";
import { ModalBody, ModalFooter } from "./ModalStyledComponents";
import { isNovartisAuth } from "../util/isNovartisAuth";
import {
  getNonApprovalTypesOptions,
  formatNonApprovalTypeLabel,
} from "../util/nonApprovalTypes";
import Tooltip from "@samacare/design/core/Tooltip";

const StyledSelect = styled(Select)`
  width: 100%;
`;

const ModalContent = styled.div`
  padding: 20px 0;
`;

const CustomModalFooter = styled(ModalFooter)`
  ${BaseButton} {
    width: 150px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const SelectorTitle = styled.div`
  font-weight: 700;
  margin: 9px 0 7px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${BaseButton} {
    margin-left: 5px;
  }
`;

const AdditionalInfoContainer = styled.div`
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.red};
  text-align: center;
  margin-bottom: 20px;
  white-space: normal;
`;

const DetailsButton = styled(BaseButton)`
  background-color: ${(props) =>
    props.selected ? props.theme.purple : "white"};
  color: ${(props) => (props.selected ? "white" : props.theme.purple)};
  flex: 1;
  margin-left: ${(props) => (props.isFirst ? 0 : 5)}px;
`;

const statePropsToUnsetOnChange = {
  nonApprovalInfo: "",
  errorMessage: null,
  followUpType: null,
  nonApprovalType: null,
  isNonApproval: false,
};

const STATUSES = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
const NONAPPROVALS = CONFIG.CONSTANTS.USER_NONAPPROVAL_TYPES;
export class UpdateAuthorizationStatusButton extends PureComponent {
  state = {
    open: false,
    selectedOption: {},
    originalOption: null,
    portalAuthorizationId: "",
    ...statePropsToUnsetOnChange,
  };

  toggleModal = (e) => {
    e.stopPropagation();
    const { authorization, defaultTo } = this.props;
    const { open } = this.state;

    const defaultSelected = defaultTo || authorization.status;
    this.setState({
      open: !open,
      portalAuthorizationId: authorization.portalAuthorizationId,
      selectedOption: {
        value: defaultSelected,
        label: defaultSelected.replace(/_/g, " "),
      },
      originalOption: authorization.status,
      ...statePropsToUnsetOnChange,
    });
  };

  getErrorMessage = () => {
    const {
      selectedOption,
      originalOption,
      nonApprovalInfo,
      nonApprovalType,
      isNonApproval,
    } = this.state;

    if (
      originalOption !== selectedOption.value &&
      (_.includes(
        CONFIG.CONSTANTS.STATUSES_REQUIRING_NONAPPROVAL_REASON,
        selectedOption.value
      ) ||
        isNonApproval)
    ) {
      if (_.isNil(nonApprovalType)) {
        return "Please select a reason for status change";
      }
      if (
        _.includes(
          [
            NONAPPROVALS.OTHER,
            NONAPPROVALS.CLINICAL_DENIAL_OTHER,
            NONAPPROVALS.PAYER_POLICY_DENIAL,
          ],
          nonApprovalType
        ) &&
        !nonApprovalInfo
      ) {
        return 'Please add additional info for "Other" reason';
      }
    }

    return null;
  };

  statusChangeHandler = (selectedOption) => {
    const errorMessage =
      selectedOption.value ===
      CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT
        ? strings.UPDATE_AUTH_STATUS.EDIT_AND_RESEND_NOTE
        : null;
    this.setState({
      selectedOption,
      ...statePropsToUnsetOnChange,
      errorMessage,
    });
  };

  nextStepChangeHandler = (selectedOption) => {
    this.setState({ followUpType: _.get(selectedOption, "value", null) });
  };

  getReasonInputs = () => {
    const { authorization, account } = this.props;
    const {
      selectedOption,
      originalOption,
      nonApprovalType,
      isNonApproval,
      followUpType,
    } = this.state;

    let buttons;

    const nonApprovalReasonSelect = (
      <StyledSelect
        id="controlNonApprovalReason"
        value={{
          value: nonApprovalType,
          label: formatNonApprovalTypeLabel(nonApprovalType),
        }}
        onChange={(selected) => {
          this.setState({ nonApprovalType: _.get(selected, "value", null) });
        }}
        options={getNonApprovalTypesOptions()}
        isClearable
        isSearchable
      />
    );
    if (originalOption !== selectedOption.value) {
      if (
        _.includes(
          CONFIG.CONSTANTS.STATUSES_REQUIRING_NONAPPROVAL_REASON,
          selectedOption.value
        )
      ) {
        buttons = nonApprovalReasonSelect;
      }

      // Only display approval markings if it's a Sama User. Otherwise, user is correcting their own mistake and is
      // Not considered a non-approval
      if (
        _.includes(
          [
            STATUSES.PENDING,
            STATUSES.EDIT_AND_RESUBMIT,
            STATUSES.ACTION_REQUIRED,
          ],
          selectedOption.value
        ) &&
        account?.isSamaUser
      ) {
        buttons = (
          <div>
            <ButtonContainer>
              {_.map(
                [
                  { value: true, title: "IS non-approval" },
                  { value: false, title: "NOT non-approval" },
                ],
                (isNA, i) => (
                  <DetailsButton
                    key={`denial_reason_button_${isNA.value}`}
                    onClick={() => {
                      this.setState({
                        isNonApproval: isNA.value,
                        nonApprovalType: null,
                      });
                    }}
                    selected={isNonApproval === isNA.value}
                    isFirst={i === 0}
                  >
                    {isNA.title}
                  </DetailsButton>
                )
              )}
            </ButtonContainer>
            {isNonApproval && (
              <div>
                <SelectorTitle>
                  Please select the nonapproval reason
                </SelectorTitle>
                {nonApprovalReasonSelect}
              </div>
            )}
          </div>
        );
      }
    }

    if (buttons) {
      const nextStepHelperOptions = _.values(CONFIG.CONSTANTS.FOLLOW_UP_TYPES);
      const options = _.map(nextStepHelperOptions, (selection) => ({
        label: selection.replace(/_/g, " "),
        value: selection,
      }));
      return (
        <AdditionalInfoContainer>
          <SelectorTitle>
            Please mark the
            {` ${selectedOption.value} `}
            reason
          </SelectorTitle>
          {buttons}
          <SelectorTitle>Additional Info</SelectorTitle>
          <BaseText
            onChange={(event) => {
              this.setState({ nonApprovalInfo: event.target.value });
            }}
          />
          {account?.isSamaUser && !authorization?.portalKey && (
            <div>
              <SelectorTitle>Next Step Helper</SelectorTitle>
              <StyledSelect
                value={
                  options.find((item) => item.value === followUpType) ?? ""
                }
                onChange={this.nextStepChangeHandler}
                options={options}
                isClearable
                isSearchable
              />
            </div>
          )}
        </AdditionalInfoContainer>
      );
    }
    return null;
  };

  updateStatus = async (e) => {
    e.stopPropagation();
    const {
      updateAuthorizationStatus,
      alert,
      authorization,
      onUpdateSuccess,
      createFollowUp,
    } = this.props;
    const {
      selectedOption,
      portalAuthorizationId,
      nonApprovalInfo,
      nonApprovalType,
      followUpType,
    } = this.state;

    if (this.getErrorMessage()) {
      this.setState({ errorMessage: this.getErrorMessage() });
    } else {
      try {
        await updateAuthorizationStatus({
          variables: {
            id: authorization.id,
            status: selectedOption.value,
            nonApprovalInfo,
            nonApprovalType,
            terminalStatusUpdateType: TerminalStatusUpdateType.User,
            ...(portalAuthorizationId === authorization.portalAuthorizationId
              ? {}
              : {
                  portalAuthorizationId,
                  identifierCaptureType: IdentifierCaptureType.UserDirect,
                }),
          },
        });

        if (followUpType) {
          await createFollowUp({
            variables: {
              authorizationId: authorization.id,
              type: followUpType,
            },
          });
        }

        this.setState({ open: false });
        if (onUpdateSuccess) {
          onUpdateSuccess();
        }
      } catch (err) {
        alert.error("There was an error updating the Authorization");
      }
    }
  };

  isUpdateDisabled = () => {
    const { account, authorization } = this.props;
    const {
      selectedOption,
      originalOption,
      nonApprovalType,
      isNonApproval,
      portalAuthorizationId,
    } = this.state;

    if (!selectedOption || selectedOption.value === originalOption) {
      // if the selected option matches the original one then disable submit
      if (!_.isNil(authorization.portalKey)) {
        return portalAuthorizationId === authorization.portalAuthorizationId;
      }
      return true;
    }
    // if a status requiring a reason is selected and no reason has been chosen then disable submit if isSamaUser
    return (
      (_.includes(
        CONFIG.CONSTANTS.STATUSES_REQUIRING_NONAPPROVAL_REASON,
        selectedOption.value
      ) ||
        isNonApproval) &&
      !nonApprovalType &&
      account?.isSamaUser
    );
  };

  render() {
    const { children, authorization, account, styleOverrides } = this.props;
    const {
      open,
      selectedOption,
      originalOption,
      errorMessage,
      portalAuthorizationId,
    } = this.state;

    let allowedStatuses = _.without(
      CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES,
      CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT
    );
    if (authorization.portalKey) {
      allowedStatuses =
        CONFIG.CONSTANTS.PORTAL_AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES;
    }

    const isNovartis = isNovartisAuth(account);

    const statusOptions = _.map(
      _.reject(allowedStatuses, (status) => status === originalOption),
      (option) =>
        isNovartis && option === "sent"
          ? {
              value: option,
              label: "Sent to payer",
            }
          : {
              value: option,
              label:
                option ===
                CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT
                  ? strings.UPDATE_AUTH_STATUS.EDIT_AND_RESEND_BUTTON_LABEL
                  : _.upperFirst(option.replace(/_/g, " ")),
            }
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Modal
          header="Update authorization status"
          onClick={this.toggleModal}
          open={open}
          styleOverride={{ overflowY: "visible" }}
        >
          <ModalBody>
            <ModalContent>
              <SelectorTitle>
                Update the status of this authorization.
              </SelectorTitle>
              <div data-cy="controlUpdateAuthToStatus">
                <StyledSelect
                  value={selectedOption}
                  onChange={this.statusChangeHandler}
                  options={statusOptions}
                  isClearable={false}
                  isSearchable
                />
              </div>
              {!_.isNil(authorization.portalKey) && (
                <>
                  <SelectorTitle>Portal Authorization ID</SelectorTitle>
                  <BaseText
                    value={portalAuthorizationId}
                    onChange={(event) => {
                      this.setState({
                        portalAuthorizationId: event.target.value,
                      });
                    }}
                  />
                </>
              )}
              {this.getReasonInputs()}
            </ModalContent>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <CustomModalFooter>
              <BaseButton
                onClick={this.updateStatus}
                disabled={this.isUpdateDisabled()}
                data-cy="actionUpdateAuthUpdateDetails"
              >
                Update Details
              </BaseButton>
            </CustomModalFooter>
          </ModalBody>
        </Modal>

        <Tooltip
          title="Manually update this authorization status"
          placement={this.props.tooltipPlace ?? "top"}
          disabled={this.props.disableTooltip}
        >
          <BaseButton
            style={{
              padding: "3px",
              fontSize: "12px",
              display: "inline-block",
              ...(styleOverrides || {}),
            }}
            onClick={this.toggleModal}
            data-cy="actionUpdateAuthStatus"
            data-tooltip-id="updateAuthorizationStatusButton"
          >
            {children}
          </BaseButton>
        </Tooltip>
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withCurrentAccount,
  withUpdateAuthorizationStatus,
  withCreateFollowUp
)(withAlert()(UpdateAuthorizationStatusButton));
