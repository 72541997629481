import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  DataGridPro,
  GridColDef,
  Stack,
  GridRenderCellParams,
  IconButton,
} from "@samacare/design/core";
import { Portal } from "@samacare/graphql";
import EditIcon from "@samacare/design/core/icons/Edit";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";
import GridWrapper from "../../../components/DataGrid/GridWrapper";

import { LeftRightCenterAll } from "@samacare/component";
import { UpsertPortalDialog } from "./UpsertPortalDialog";
import { DeletePortalIconButton } from "./DeletePortalIconButton";
import { wait } from "../../../../helpers/wait";
import Check from "@samacare/design/core/icons/Check";
import { usePortals } from "../../../hooks/usePortals";

const CenteredCheck = () => (
  <LeftRightCenterAll sx={{ width: "100%" }}>
    <Check />
  </LeftRightCenterAll>
);

export const Portals: React.FC = () => {
  const [selectedPortalForEdit, setSelectedPortalForEdit] =
    useState<Portal | null>(null);
  const [showList, setShowList] = useState(false);

  const { portals: portalList, loading } = usePortals();

  const gridColumns: GridColDef[] = [
    { field: "title", headerName: "Title", width: 200 },
    { field: "loginUrl", headerName: "Login Url", width: 200 },
    { field: "hubKey", headerName: "Hub Key", width: 100 },
    {
      field: "isTop",
      headerName: "Top",
      width: 75,
      renderCell: (props) => (props.value ? <CenteredCheck /> : null),
    },
    {
      field: "isLegacy",
      headerName: "Legacy",
      width: 75,
      renderCell: (props) => (props.value ? <CenteredCheck /> : null),
    },
    {
      field: "enableFollowUps",
      headerName: "Follow Ups",
      width: 75,
      renderCell: (props) => (props.value ? <CenteredCheck /> : null),
    },
    {
      field: "hideCredentialsFromSupport",
      headerName: "Hide Credentials",
      width: 75,
      renderCell: (props) => (props.value ? <CenteredCheck /> : null),
    },
    {
      field: "requiresPhoneMfa",
      headerName: "Phone MFA",
      width: 75,
      renderCell: (props) => (props.value ? <CenteredCheck /> : null),
    },
    {
      field: "formFieldConfigKey",
      headerName: "Field Config",
      width: 75,
    },
    {
      field: "buttons",
      headerName: "",
      hideable: false,
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (props: GridRenderCellParams) => (
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <IconButton
            onClick={() => setSelectedPortalForEdit(props.row as Portal)}
          >
            <EditIcon />
          </IconButton>
          <DeletePortalIconButton portal={props.row as Portal} />
        </Stack>
      ),
    },
  ];

  // Autosize on mount was not working when just gating with loading,
  // needed to add the wait to ensure proper autosizing
  // Still doesn't work sometimes and breaks when new is clicked
  useEffect(() => {
    if (portalList && !loading) {
      void (async () => {
        await wait(250);
        setShowList(true);
      })();
    }
  }, [portalList, loading]);

  return (
    <GridWrapper sx={{ marginLeft: 0, marginRight: 0, marginTop: 2 }}>
      {showList && (
        <DataGridPro
          rows={portalList}
          columns={gridColumns}
          autosizeOnMount
          autosizeOptions={{ expand: true }}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: () => (
              <Box display="flex" justifyContent="center">
                <Typography variant="body">
                  No portals have been added
                </Typography>
              </Box>
            ),
            noResultsOverlay: () => (
              <LeftRightCenterAll sx={{ height: "27px" }}>
                <Typography variant="body">No portals found</Typography>
              </LeftRightCenterAll>
            ),
          }}
          slotProps={{
            toolbar: {
              onNewClick: () => setSelectedPortalForEdit({} as Portal),
              newButtonDataCy: "actionNewPortal",
            },
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
            sorting: {
              sortModel: [{ field: "title", sort: "asc" }],
            },
          }}
          sx={{ border: 0 }}
        />
      )}

      <UpsertPortalDialog
        portal={selectedPortalForEdit}
        portalList={portalList}
        onClose={() => setSelectedPortalForEdit(null)}
      />
    </GridWrapper>
  );
};
