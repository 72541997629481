import _ from "lodash";
import * as PhoneNumber from "react-phone-number-input";

import { removeNonDigits } from "./stringUtils";

export const formatPhiloPhoneNumberForDisplay = (pn: string): string => {
  return `${pn.slice(1, 4)}-${pn.slice(4, 7)}-${pn.slice(7)}`;
};

export const isValidPhoneNumber = (phoneNumber: string): boolean =>
  _.size(removeNonDigits(phoneNumber)) === 10;

export const formatPhoneNumber = (phone: string): string => {
  if (!phone) return "";

  const formattedPhone = PhoneNumber.formatPhoneNumberIntl(phone);
  if (formattedPhone) return formattedPhone;
  if (phone?.length === 10) return `+1${phone}`;
  return phone;
};

export const isValidE164PhoneNumber = (phone: string) => {
  if (!phone) return false;
  return PhoneNumber.isPossiblePhoneNumber(phone);
};
