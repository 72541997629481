import _ from "lodash";

import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@samacare/design";
import { FullBenefitsCheckDataType } from "@samacare/graphql";

import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { ActionBar } from "./ActionBar";
import { CareMetxMedeBvSummary } from "./CareMetxMedeBvSummary";
import { PatientDetails } from "./PatientDetails";
import { RightSideMenu } from "./RightSideMenu";
import { FullBvNotificationAlert } from "../components";

export const CareMetxPavbluResponse: React.FC = () => {
  const res = useUnifiedBenefitsCheckContext();
  if (res.loading) {
    return <CircularProgress />;
  }

  const { unifiedBenefitsCheck } = res;
  if (unifiedBenefitsCheck == null) {
    return <Typography>Unable to load benefits verification</Typography>;
  }

  const { fullData: benefitsVerification, patient } = unifiedBenefitsCheck;
  if (benefitsVerification == null) {
    return <Typography>Unable to load benefits verification</Typography>;
  }
  if (benefitsVerification.type !== FullBenefitsCheckDataType.Caremetx) {
    return (
      <Typography>
        Benefits verification is not supported by CareMetx
      </Typography>
    );
  }

  const html = benefitsVerification.outcome?.data?.Html;
  const drugLabel = _.compact([
    unifiedBenefitsCheck.drug?.code,
    unifiedBenefitsCheck.drug?.drugName,
  ]).join(" - ");
  return (
    <Stack
      padding={2}
      sx={{ display: "flex", height: "100vh" }}
      data-cy="componentCareMetxBenefitsVerificationSummaryPage"
    >
      <Breadcrumbs>
        <Link underline="hover" href="#/benefits-verification">
          <Typography variant="h6">Benefits Verification</Typography>
        </Link>
        <Typography variant="h6">
          #{benefitsVerification.id.slice(-5)}
        </Typography>
      </Breadcrumbs>
      <Box marginTop={({ spacing }) => spacing(4)}>
        <PatientDetails />
      </Box>
      <Stack direction="row" justifyContent="space-between" marginTop={3}>
        <Typography variant="h6">
          {`Drug Specific Coverage - ${drugLabel}`}
        </Typography>
        <ActionBar isEditMode={false} />
      </Stack>
      <Stack direction="row" marginTop={3} flexGrow={1}>
        <Stack flexGrow={1}>
          <FullBvNotificationAlert
            patientId={patient.id ? patient.id.toString() : undefined}
          />
          <Box>
            <CareMetxMedeBvSummary />
          </Box>
          <Box flexGrow={1}>
            {html != null && (
              <iframe
                title="CareMetx Benefits Verification Results"
                style={{ width: "100%", height: "100%" }}
                srcDoc={html}
              />
            )}
          </Box>
        </Stack>
        <Box minWidth="230px" marginLeft={3}>
          <RightSideMenu activeStep={2} />
        </Box>
      </Stack>
    </Stack>
  );
};
