import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useInstitutionPrescribers } from "@samacare/hooks-data";
import {
  ProviderAutocomplete,
  ProviderAutocompleteFunctionalProps,
  ProviderAutocompleteInternalFunctionalProps,
} from "./ProviderAutocomplete";

const Provider: React.FC<ProviderAutocompleteInternalFunctionalProps> = (
  props,
) => {
  const [data, loading] = useInstitutionPrescribers();

  return <ProviderAutocomplete providers={data} loading={loading} {...props} />;
};

const WrappedProviderAutocomplete: React.FC<
  ProviderAutocompleteFunctionalProps
> = (props) => {
  const { apolloClient, ...otherProps } = props;

  return (
    <ApolloProvider client={apolloClient as ApolloClient<object>}>
      <Provider {...otherProps} />
    </ApolloProvider>
  );
};

export default WrappedProviderAutocomplete;
