import { IconButton, Stack } from "@samacare/design";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { AutocompleteField } from "@samacare/form2";
import { createFilterOptions } from "@samacare/design/core/base";
import { useFormContext } from "react-hook-form";
import { Rule } from "./components/Rule";
import { QuantityAndType, DatesOfService } from "./optionalFields";

// eslint-disable-next-line no-shadow
export enum ServiceCodeOptionalFields {
  Quantity = "quantity",
  DateOfService = "dateOfService",
}

export type ServiceCode = {
  code: string;
  drugName: string;
  DrugOptionId?: number;
};

interface ServiceCodeInputProps {
  name: string;
  required?: boolean;
  disabled?: boolean;
  handleDelete?: () => void;
  optionalFields?: ServiceCodeOptionalFields[];
  drugOptionRequired?: boolean;
  serviceCodes: ServiceCode[];
  isFirst: boolean;
  loading: boolean;
}

export const ServiceCodeInput: React.FC<ServiceCodeInputProps> = ({
  required = true,
  disabled = false,
  handleDelete,
  drugOptionRequired,
  optionalFields,
  name,
  serviceCodes,
  isFirst,
  loading,
}) => {
  const { watch } = useFormContext();
  const selected = watch(name);

  return (
    <Stack spacing={2} width="100%">
      {!isFirst && <Rule />}
      <Stack direction="row" spacing={1}>
        <AutocompleteField<ServiceCode, true, true, false>
          loading={loading}
          required={required}
          disabled={disabled}
          placeholder="Type to search services..."
          label={isFirst ? "Primary Service Code" : "Additional Service Code"}
          options={serviceCodes}
          name={name}
          value={selected?.code ? selected : null}
          filterSelectedOptions
          filterOptions={createFilterOptions({ limit: 50 })}
          getOptionLabel={(option: ServiceCode) =>
            `${option.code}: ${option.drugName}`
          }
          sx={{ flex: 4 }}
          isOptionEqualToValue={(option: ServiceCode, value: ServiceCode) =>
            option.code === value.code
          }
        />

        {!(isFirst && drugOptionRequired) && handleDelete && (
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      {optionalFields?.includes(ServiceCodeOptionalFields.Quantity) && (
        <QuantityAndType required={required} disabled={disabled} name={name} />
      )}
      {optionalFields?.includes(ServiceCodeOptionalFields.DateOfService) && (
        <DatesOfService required={required} disabled={disabled} name={name} />
      )}
    </Stack>
  );
};
